import createReducer from "../../utils/createReducer";
import {
  CANDIDATE_ERROR,
  CANDIDATE_SUCCESS,
  CANDIDATE_COMMENTS_SUCCESS,
  CANDIDATE_COMMENTS_ERROR,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_ERROR,
} from "../../actions/candidate/candidateActionConstants";

const initialState = {
  candidate: {},
  errorMessage: "",
};

export default createReducer(
  {
    [CANDIDATE_SUCCESS]: setCandidate,
    [CANDIDATE_ERROR]: setError,
    [CANDIDATE_COMMENTS_SUCCESS]: setComments,
    [CANDIDATE_COMMENTS_ERROR]: setCommentsError,
    [DELETE_CANDIDATE_SUCCESS]: deleteCandidate,
    [DELETE_CANDIDATE_ERROR]: deleteCandidateError,
  },
  initialState
);

function setCandidate(state, action) {
  return {
    ...state,
    candidate: action.payload,
  };
}

function setError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setComments(state, action) {
  const currentDate = new Date();
  // console.log("Ovde smo" + currentDate);
  var datetime =
    currentDate.getFullYear() +
    "-" +
    (currentDate.getMonth() <= 9
      ? "0" + (currentDate.getMonth() + 1)
      : currentDate.getMonth() + 1) +
    "-" +
    (currentDate.getDate() <= 9
      ? "0" + currentDate.getDate()
      : currentDate.getDate()) +
    "T" +
    currentDate.getHours() +
    ":" +
    (currentDate.getMinutes() <= 9
      ? "0" + currentDate.getMinutes()
      : currentDate.getMinutes()) +
    ":" +
    (currentDate.getSeconds() <= 9
      ? "0" + currentDate.getSeconds()
      : currentDate.getSeconds());
  const obj = {
    content: action.payload.myObj.content,
    dateOfSending: datetime,
    user: action.payload.user,
  };
  return {
    ...state,
    candidate: {
      ...state.candidate,
      comments: [...state.candidate.comments, obj],
    },
  };
}

function setCommentsError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function deleteCandidate(state) {
  return {
    ...state,
    candidate: {},
  };
}

function deleteCandidateError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
