import React, { useState } from "react";
import PropType from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import filterIcon from "../../assets/images/filter_vector.png";
import x from "../../assets/images/x.png";
import { changeIsCheckedValue } from "../../store/actions/technologies/technologiesActions";
import { useDispatch } from "react-redux";
import { setFilteredAdsReq } from "../../store/actions/ads/adsAction";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AdFilters = ({ open, handleClose, technologies }) => {
  const [sliderValue, setSliderValue] = useState([0, 10]);
  const [employmentType, setEmploymentType] = useState("Work");
  const [workHour, setWorkHour] = useState("FullTime");
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const handleSliderChange = (_, newValue) => {
    setSliderValue(newValue);
  };

  const onSubmitFilters = () => {
    const tech = technologies
      .filter((tech) => tech.isChecked === true)
      .map((tech) => tech.name);

    dispatch(
      setFilteredAdsReq({
        minExperience: sliderValue[0],
        maxExperience: sliderValue[1],
        technologies: tech,
        workHour,
        employmentType,
      })
    );

    let technologiesQuery = "";
    for (let i = 0; i < tech.length; i++) {
      technologiesQuery += `technologies=${tech[i]}&`;
    }

    history.push({
      pathname: "/ads",
      search: `?minExperience=${sliderValue[0]}&maxExperience=${sliderValue[1]}&workHour=${workHour}&employmentType=${employmentType}&${technologiesQuery}`,
    });

    handleClose();
  };

  const handleCheckboxes = (e) => {
    const { value } = e.target;

    dispatch(changeIsCheckedValue(value));
  };

  const employmentTypeHandler = (type) => {
    setEmploymentType(type);
  };

  const workHourHandler = (type) => {
    setWorkHour(type);
  };

  const list = () => (
    <Box
      sx={{
        width: 360,
        height: "100%",
        borderRadius: "18px 0 0 18px",
        padding: "36px",
      }}
      role="presentation"
      // onClick={handleClose}
      onKeyDown={handleClose}
    >
      <div data-testid="ad-filters-drawer">
        <div className="job-filters-header-container">
          <div className="job-filters-header">
            <img src={filterIcon} alt="filter_icon" />
            <h3>{t("filters.filters")}</h3>
            <p>
              <sub>| {t("ads.ads")}</sub>
            </p>
          </div>
          <div className="job-filters-header-close" onClick={handleClose}>
            <img src={x} alt="x" />
          </div>
        </div>
        <div className="job-filters-experience">
          <div className="job-filters-sub-title">
            <p>{t("filters.experience")}</p>
          </div>
          <div className="job-filters-experience-slider">
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={sliderValue}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              max={20}
            />
          </div>
        </div>
        <div className="job-filters-technologies">
          <div className="job-filters-sub-title">
            <p>{t("filters.tecnologies")}</p>
          </div>
          <div className="job-filters-technologies-checkboxes">
            <FormGroup>
              {technologies?.map((technology, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      onChange={handleCheckboxes}
                      value={technology.name}
                      checked={technology.isChecked}
                      className="job-filters-checkbox"
                    />
                  }
                  label={technology.name}
                />
              ))}
            </FormGroup>
          </div>
        </div>
        <div className="job-filters-technologies">
          <div className="job-filters-sub-title">
            <p>{t("filters.employmentType")}</p>
          </div>
          <div className="job-filters-employment-type">
            <button
              className={`c-btn ${
                employmentType === "Intership"
                  ? "c-btn c-btn--primary"
                  : "c-btn--primary-outlined"
              }`}
              onClick={employmentTypeHandler.bind(this, "Intership")}
            >
              {t("filters.internship")}
            </button>
            <button
              className={`c-btn  ${
                employmentType === "Work"
                  ? "c-btn c-btn--primary"
                  : "c-btn--primary-outlined"
              }`}
              onClick={employmentTypeHandler.bind(this, "Work")}
            >
              {t("filters.work")}
            </button>
          </div>
        </div>
        <div className="job-filters-technologies">
          <div className="job-filters-sub-title">
            <p>{t("filters.workHour")}</p>
          </div>
          <div className="job-filters-employment-type">
            <button
              className={`c-btn  ${
                workHour === "PartTime"
                  ? "c-btn c-btn--primary"
                  : "c-btn--primary-outlined"
              }`}
              onClick={workHourHandler.bind(this, "PartTime")}
            >
              {t("filters.partTime")}
            </button>
            <button
              className={`c-btn ${
                workHour === "FullTime"
                  ? "c-btn c-btn--primary"
                  : "c-btn--primary-outlined"
              }`}
              onClick={workHourHandler.bind(this, "FullTime")}
            >
              {t("filters.fullTime")}
            </button>
          </div>
        </div>
        <div className="job-filters-search">
          <button
            onClick={onSubmitFilters}
            className="c-btn c-btn--primary"
            data-testid="job-filters-submit"
          >
            {t("filters.search")}
          </button>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        {list()}
      </Drawer>
    </div>
  );
};

AdFilters.propTypes = {
  open: PropType.any,
  handleClose: PropType.func,
  technologies: PropType.any,
};

export default AdFilters;
