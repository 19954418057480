import createReducer from "../../utils/createReducer";
import {
  FETCH_ADD_AD_TECHNOLOGIES_ERR,
  FETCH_ADD_AD_TECHNOLOGIES_SUCCESS,
  CHANGE_ISCHECKED_VALUE_ADD_AD,
  RESET_ISCHECKED_VALUE_ADD_AD,
} from "../../actions/addAdTechnologies/addAdTechnologiesActionConstants";

const initialState = {
  technologies: [],
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_ADD_AD_TECHNOLOGIES_SUCCESS]: setStateTechnologiesAddAd,
    [FETCH_ADD_AD_TECHNOLOGIES_ERR]: setStateAddAdErrorMessage,
    [CHANGE_ISCHECKED_VALUE_ADD_AD]: setIsCheckedTechnologyAddAd,
    [RESET_ISCHECKED_VALUE_ADD_AD]: setResetTechnologyAddAd,
  },
  initialState
);

function setStateTechnologiesAddAd(state, action) {
  return { ...state, technologies: action.payload };
}

function setStateAddAdErrorMessage(state, action) {
  return { ...state, errorMessage: action.payload };
}

function setIsCheckedTechnologyAddAd(state, action) {
  return {
    ...state,
    technologies: state.technologies.map((tech) =>
      tech.technologyId === action.payload
        ? { ...tech, isChecked: !tech.isChecked }
        : tech
    ),
  };
}

function setResetTechnologyAddAd(state) {
  const resetedTechnologies = state.technologies.map((tech) => ({
    ...tech,
    isChecked: false,
  }));
  
  return { ...state, technologies: resetedTechnologies };
}
