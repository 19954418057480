import React, { useState } from "react";
import PropType from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import filterIcon from "../../assets/images/filter_vector.png";
import x from "../../assets/images/x.png";
import { changeStatusIsCheckedValue } from "../../store/actions/processes/statusAction";
import { useDispatch } from "react-redux";
import { setFilteredProcessesReq  } from "../../store/actions/processes/processesAction";
import { useTranslation } from "react-i18next";

const SelectionFilter = ({ open, handleClose, statuses }) => {
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onSubmitFilters = () => {
    const status = statuses
      .filter((status) => status.isChecked === true)
      .map((status) => status.name);

    dispatch(
      setFilteredProcessesReq({
        statuses: status,
        startAt,
        endAt
      })
    );

    handleClose();
  };

  const handleCheckboxes = (e) => {
    const { value } = e.target;
    dispatch(changeStatusIsCheckedValue(value));
  };

  const list = () => (
    <Box
      sx={{
        width: 360,
        height: "100%",
        borderRadius: "18px 0 0 18px",
        padding: "36px",
      }}
      role="presentation"
      // onClick={handleClose}
      onKeyDown={handleClose}
    >
      <div>
        <div className="job-filters-header-container">
          <div className="job-filters-header">
            <img src={filterIcon} alt="filter_icon" />
            <h3>{t("filters.filters")}</h3>
            <p>
              <sub>| {t("selection.title")}</sub>
            </p>
          </div>
          <div className="job-filters-header-close" onClick={handleClose}>
            <img src={x} alt="x" />
          </div>
        </div>
        <div className="job-filters-technologies">
          <div className="job-filters-sub-title">
            <p>Status</p>
          </div>
          <div className="job-filters-technologies-checkboxes">
            <FormGroup>
              {statuses?.map((technology, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      onChange={handleCheckboxes}
                      value={technology.name}
                      checked={technology.isChecked}
                    />
                  }
                  label={technology.name}
                />
              ))}
            </FormGroup>
          </div>
        </div>
        <div className="job-filters-technologies">
          {/* <div className="job-filters-sub-title">
            <p>Datum isteka oglasa</p>
          </div>
          <input
            type="date"
            placeholder="ex"
            value={expiredAt}
            onChange={(e) => setExpiredAt(e.target.value)}
          /> */}
          <div className="job-filters-sub-title">
            <p>{t("selection.filterDate")}</p>
          </div>
          <div className="add-job-modal-stage-sub-card">
            <label>{t("common.from")}</label>
            <input
              type="date"
              placeholder="ex"
              value={startAt}
              onChange={(e) => setStartAt(e.target.value)}
            />
          </div>
          <div className="add-job-modal-stage-sub-card">
            <label>{t("common.to")}</label>
            <input
              type="date"
              placeholder="ex"
              value={endAt}
              onChange={(e) => setEndAt(e.target.value)}
            />
          </div>
        </div>
        <div className="job-filters-search">
          <button onClick={onSubmitFilters} className="c-btn c-btn--primary">
          {t("common.search")}
          </button>
        </div>
      </div>
    </Box >
  );

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        {list()}
      </Drawer>
    </div>
  );
};

SelectionFilter.propTypes = {
  open: PropType.any,
  handleClose: PropType.func,
  statuses: PropType.any,
};

export default SelectionFilter;
