import {
  createFetchType,
  createSuccessType,
  createErrorType,
} from "../actionHelpers";

const FETCH_PATTERN_SCOPE = "FETCH_PATTERN";

export const FETCH_PATTERN_REQ = createFetchType(FETCH_PATTERN_SCOPE);
export const FETCH_PATTERN_ERR = createErrorType(FETCH_PATTERN_SCOPE);
export const FETCH_PATTERN_SUCCESS = createSuccessType(FETCH_PATTERN_SCOPE);
