import {
  FETCH_ARCHIVE_ADS_SUCCESS,
  FETCH_ARCHIVE_ADS_ERR,
} from "../../actions/archiveAds/archiveAdsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  archiveAds: [],
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_ARCHIVE_ADS_SUCCESS]: setStateArchiveAds,
    [FETCH_ARCHIVE_ADS_ERR]: setArchiveAdsError,
  },
  initialState
);

function setStateArchiveAds(state, action) {
  return {
    ...state,
    archiveAds: action.payload,
  };
}

function setArchiveAdsError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
