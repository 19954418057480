import createReducer from "../../utils/createReducer";
import {
  FETCH_STATUS_CHANGE_ERR,
  FETCH_STATUS_CHANGE_REQ,
  FETCH_STATUS_CHANGE_SUCCESS,
} from "../../actions/processes/processesActionConstants";

const initialState = {
  success: false,
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_STATUS_CHANGE_REQ]: setStateErrorReq,
    [FETCH_STATUS_CHANGE_ERR]: setStateErrorMessage,
    [FETCH_STATUS_CHANGE_SUCCESS]: statusUpdateSuccess,
  },
  initialState
);

function statusUpdateSuccess(state) {
  return { ...state, success: true };
}

function setStateErrorReq(state) {
  return { ...state, success: false };
}

function setStateErrorMessage(state, action) {
  return { ...state, errorMessage: action.payload };
}
