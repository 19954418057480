import {
  CANDIDATE_FETCH,
  CANDIDATE_ERROR,
  CANDIDATE_SUCCESS,
  CANDIDATE_COMMENTS_FETCH,
  CANDIDATE_COMMENTS_ERROR,
  CANDIDATE_COMMENTS_SUCCESS,
  DELETE_CANDIDATE,
  DELETE_CANDIDATE_ERROR,
  DELETE_CANDIDATE_SUCCESS,
} from "./candidateActionConstants";

export const fetchCandidate = (payload) => ({
  type: CANDIDATE_FETCH,
  payload,
});

export const fetchCandidateError = (payload) => ({
  type: CANDIDATE_ERROR,
  payload,
});

export const fetchCandidateSuccess = (payload) => ({
  type: CANDIDATE_SUCCESS,
  payload,
});

export const createCandidateComment = (payload) => ({
  type: CANDIDATE_COMMENTS_FETCH,
  payload,
});

export const createCandidateCommentError = (payload) => ({
  type: CANDIDATE_COMMENTS_ERROR,
  payload,
});

export const createCandidateCommentSuccess = (payload) => ({
  type: CANDIDATE_COMMENTS_SUCCESS,
  payload,
});

export const deleteCandidate = (payload) => ({
  type: DELETE_CANDIDATE,
  payload,
});

export const deleteCandidateError = (payload) => ({
  type: DELETE_CANDIDATE_ERROR,
  payload,
});

export const deleteCandidateSuccess = () => ({
  type: DELETE_CANDIDATE_SUCCESS
});
