import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import filterIcon from "../../assets/images/filter_vector.png";
import x from "../../assets/images/x.png";
import {
  filterCandidates,
  fetchAdsCandidates,
} from "../../store/actions/candidates/candidatesActions";
import { useDispatch } from "react-redux";
import { formatDateInput } from "../../util/helpers/dateHelpers";
import {
  changeIsCheckedValue,
  resetIsCheckedValue,
} from "../../store/actions/technologies/technologiesActions";
import { useTranslation } from "react-i18next";

const CandidateFilters = ({
  open,
  handleClose,
  pageSize,
  currentPage,
  isTableView,
  technologies,
  sliderValue,
  startingDate,
  endingDate,
  typesOfEmployments,
  setSliderValue,
  setStartingDate,
  setEndingDate,
  setTypesOfEmployments,
}) => {
  const dispatch = useDispatch();
  const [isInitial, setIsInitial] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {}, [isTableView]);

  const handleSliderChange = (_, newValue) => {
    setSliderValue(newValue);
  };

  const resetFilters = () => {
    setTypesOfEmployments([
      { name: "Posao", isChecked: false },
      { name: "Intership", isChecked: false },
    ]);
    setSliderValue([0, 0]);
    setEndingDate("");
    setStartingDate("");
  };

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      return;
    }
    dispatch(resetIsCheckedValue());
    resetFilters();
  }, [isTableView]);

  const updateTypeState = (name, value) => {
    const newState = typesOfEmployments.map((obj) => {
      if (obj.name === name) {
        return { ...obj, isChecked: value };
      } else {
        return { ...obj, isChecked: false };
      }
    });
    setTypesOfEmployments(newState);
  };

  const handleTechologiesChange = (e) => {
    const { name } = e.target;

    dispatch(changeIsCheckedValue(name));
  };

  const getSelectedEmploymentType = () => {
    return typesOfEmployments.filter((e) => e.isChecked === true);
  };

  const handleChangeTypeOfEmployment = (name) => {
    updateTypeState(name, true);
  };

  const handleApiResponseSuccess = () => {
    handleClose();
  };

  const isThereSelectedFilter = () => {
    let tech = technologies.filter((t) => t.isChecked === true);
    if (tech && tech.length > 0) {
      return true;
    }
    let k = typesOfEmployments.filter((te) => te.isChecked === true);
    if (k && k.length > 0) {
      return true;
    }

    if (sliderValue[0] !== 0 || sliderValue[1] !== 0) {
      return true;
    }
    if (startingDate !== "" || endingDate !== "") {
      return true;
    }

    return false;
  };

  const fiterItems = () => {
    const tech = technologies
      .filter((tech) => tech.isChecked === true)
      .map((tech) => tech.name);

    const selectedEmploymentType = getSelectedEmploymentType();

    isTableView
      ? dispatch(
          filterCandidates({
            pageSize,
            currentPage,
            minExperience: sliderValue[0],
            maxExperience: sliderValue[1],
            employmentType:
              selectedEmploymentType && selectedEmploymentType.length === 0
                ? ""
                : selectedEmploymentType[0].name,
            minDateOfApplication: startingDate,
            maxDateOfApplication: endingDate,
            technologies: tech,
            handleApiResponseSuccess,
          })
        )
      : dispatch(
          fetchAdsCandidates({
            pageSize,
            currentPage,
            minExperience: sliderValue[0],
            maxExperience: sliderValue[1],
            employmentType:
              selectedEmploymentType && selectedEmploymentType.length === 0
                ? ""
                : selectedEmploymentType[0].name,
            minDateOfApplication: startingDate,
            maxDateOfApplication: endingDate,
            technologies: tech,
            handleApiResponseSuccess,
          })
        );
  };

  const handleChangeStartingDate = (event) => {
    setStartingDate(event.target.value);
  };

  const handleChangeEndingDate = (event) => {
    setEndingDate(event.target.value);
  };

  const getDayBeforeToday = (date = new Date()) => {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
    return previous;
  };

  const list = () => (
    <Box
      sx={{
        width: 360,
        height: "100%",
        borderRadius: "18px 0 0 18px",
        padding: "36px",
      }}
      role="presentation"
      onKeyDown={handleClose}
    >
      <div>
        <div className="job-filters-header-container">
          <div className="job-filters-header">
            <img src={filterIcon} alt="filter_icon" />
            <h3>{t("filters.filters")}</h3>
            <p>
              <sub>| {t("candidates.candidates")}</sub>
            </p>
          </div>
          <div className="job-filters-header-close" onClick={handleClose}>
            <img src={x} alt="x" />
          </div>
        </div>
        <div className="job-filters-experience">
          <div className="job-filters-sub-title">
            <p>{t("filters.experience")}</p>
          </div>
          <div className="job-filters-experience-slider">
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={sliderValue}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              max={20}
            />
          </div>
        </div>
        <div className="job-filters-technologies">
          <div className="job-filters-sub-title">
            <p>{t("filters.technologies")}</p>
          </div>
          <div className="job-filters-technologies-checkboxes">
            <FormGroup>
              {technologies.map((technology, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={technology.isChecked}
                      name={technology.name}
                      className="job-filters-technologies-checkboxes-checkbox"
                      onChange={handleTechologiesChange}
                    />
                  }
                  label={technology.name}
                />
              ))}
            </FormGroup>
          </div>
        </div>
        <div className="job-filters-technologies">
          <div className="job-filters-sub-title">
            <p>{t("filters.employmentType")}</p>
          </div>
          <div className="job-filters-employment-type">
            {typesOfEmployments.map((type, index) => (
              <button
                key={index}
                className={
                  type.isChecked === false
                    ? "c-btn c-btn--primary-outlined type-of-employment-btn"
                    : "c-btn c-btn--primary type-of-employment-btn"
                }
                onClick={() => handleChangeTypeOfEmployment(type.name)}
              >
                {type.name}
              </button>
            ))}
          </div>
        </div>
        <div className="job-filters-technologies" style={{ marginTop: "35px" }}>
          <div className="job-filters-sub-title">
            <p>{t("filters.dateOfApplication")}</p>
            <div className="filter-date-container">
              <p>{t("common.from")}</p>
              <input
                type="date"
                id="start"
                max={formatDateInput(getDayBeforeToday())}
                value={startingDate}
                onChange={handleChangeStartingDate}
              />
            </div>
            <div className="filter-date-container">
              <p>{t("common.to")}</p>
              <input
                type="date"
                id="start"
                data-testid="filter-date-container-ending-date"
                max={formatDateInput(new Date())}
                value={endingDate}
                onChange={handleChangeEndingDate}
              />
            </div>
          </div>
        </div>
        <div className="job-filters-search" style={{ marginTop: "45px" }}>
          <button
            className="c-btn c-btn--primary"
            onClick={fiterItems}
            disabled={!isThereSelectedFilter()}
          >
            {t("common.search")}
          </button>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        {list()}
      </Drawer>
    </div>
  );
};

CandidateFilters.propTypes = {
  open: PropType.any,
  handleClose: PropType.func,
  candidates: PropType.array,
  currentPage: PropType.number,
  pageSize: PropType.number,
  isTableView: PropType.bool,
  technologies: PropType.array,
  sliderValue: PropType.array,
  startingDate: PropType.string,
  endingDate: PropType.string,
  typesOfEmployments: PropType.array,
  setSliderValue: PropType.func,
  setStartingDate: PropType.func,
  setEndingDate: PropType.func,
  setTypesOfEmployments: PropType.func,
};

export default CandidateFilters;
