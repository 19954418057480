import {
  FETCH_ADS_REQ,
  FETCH_ADS_ERR,
  FETCH_ADS_SUCCESS,
  FETCH_FILTERED_ADS_REQ,
  FETCH_FILTERED_ADS_ERR,
  FETCH_FILTERED_ADS_SUCCESS,
} from "./adsActionConstants";

export const setAdsReq = () => ({
  type: FETCH_ADS_REQ,
});

export const setAdsError = (payload) => ({
  type: FETCH_ADS_ERR,
  payload,
});

export const setAds = (payload) => ({
  type: FETCH_ADS_SUCCESS,
  payload,
});

export const setFilteredAdsReq = (payload) => ({
  type: FETCH_FILTERED_ADS_REQ,
  payload,
});

export const setFilteredAdsError = (payload) => ({
  type: FETCH_FILTERED_ADS_ERR,
  payload,
});

export const setFilteredAds = (payload) => ({
  type: FETCH_FILTERED_ADS_SUCCESS,
  payload,
});