import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { refreshUserToken } from "./store/actions/login/loginActions";
import { useLocation } from "react-router-dom";

import {
  ADS_PAGE,
  FORGOT_PASSWORD_PAGE,
  FORGOT_PASSWORD_CONFIRMATION_PAGE,
  NOT_FOUND_PAGE,
  ERROR_PAGE,
  BASE_PAGE,
  RESET_PASSWORD_PAGE,
  USERS_PAGE,
  CANDIDATES_PAGE,
  USER_DETAILS_PAGE,
  CANDIDATES_DETAILS_PAGE,
  SELECTION_PROCESS_PAGE,
  SELECTION_PROCESS_OF_APPLICANT_PAGE,
  PATTERNS_PAGE,
  PATTERN_DETAILS_PAGE,
  SCHEDULE_PAGE,
  STATS_PAGE,
  REGISTER_PAGE,
  CREATE_AD_PAGE,
  JOB_DETAILS_PAGE,
  CREATE_CV_PAGE,
} from "./constants/pages";

import LoginPage from "./pages/LoginPage/LoginPageMUI";
import AdsPage from "./pages/AdsPage/AdsPage";
import NotFoundPage from "./pages/ErrorPages/NotFoundPage";
import ErrorPage from "./pages/ErrorPages/ErrorPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPageMUI";
import PrivateRoute from "./components/Router/PrivateRoute";
import ForgotPasswordConfirmationPage from "./pages/ForgotPasswordPage/ForgotPasswordConfirmationPageMUI";
import ResetPasswordPage from "./pages/ForgotPasswordPage/ResetPasswordPageMUI";
import UsersPage from "./pages/UsersPage/UsersPage";
import CandidatesPage from "./pages/CandidatesPage/CandidatesPage";
import AdDetailsPage from "./pages/AdsPage/AdDetailsPage";
import UserDetails from "./pages/UsersPage/UserDetails";
import CandidateDetailsPage from "./pages/CandidatesPage/CandidateDetailsPage";
import SelectionProcessPage from "./pages/SelectionProcessPage/SelectionProcessPage";
import SelectionProcessOfApplicantPage from "./pages/SelectionProcessPage/SelectionProcessOfApplicantPage";
import PatternsPage from "./pages/PatternsPage/PatternsPage";
import PatternDetailsPage from "./pages/PatternsPage/PatternDetailsPage";
import SchedulePage from "./pages/SchedulePage/SchedulePage";
import StatsPage from "./pages/StatsPage/StatsPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import CreateAdPage from "./pages/AdsPage/CreateAdPage";
import CreateCVPage from "./pages/CVPage/CreateCVPage";

const AppRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === BASE_PAGE) {
      return;
    }
    dispatch(refreshUserToken());
  }, [location]);
  return (
    <Switch>
      <Route exact path={BASE_PAGE} component={LoginPage} />
      <Route path={NOT_FOUND_PAGE} component={NotFoundPage} />
      {/* <Route path={USERS_PAGE} component={UsersPage} /> */}
      <Route path={ERROR_PAGE} component={ErrorPage} />
      <Route path={FORGOT_PASSWORD_PAGE} component={ForgotPasswordPage} />
      <Route
        path={FORGOT_PASSWORD_CONFIRMATION_PAGE}
        component={ForgotPasswordConfirmationPage}
      />
      <Route exact path={REGISTER_PAGE} component={RegisterPage} />
      <Route path={RESET_PASSWORD_PAGE} component={ResetPasswordPage} />
      <Route exact path={CREATE_CV_PAGE} component={CreateCVPage} />
      <PrivateRoute exact path={ADS_PAGE} component={AdsPage} />
      <PrivateRoute exact path={JOB_DETAILS_PAGE} component={AdDetailsPage} />
      <PrivateRoute exact path={USER_DETAILS_PAGE} component={UserDetails} />
      <PrivateRoute exact path={USERS_PAGE} component={UsersPage} />
      <PrivateRoute exact path={CANDIDATES_PAGE} component={CandidatesPage} />
      <PrivateRoute exact path={CREATE_AD_PAGE} component={CreateAdPage} />
      <PrivateRoute
        exact
        path={CANDIDATES_DETAILS_PAGE}
        component={CandidateDetailsPage}
      />
      <PrivateRoute
        exact
        path={SELECTION_PROCESS_PAGE}
        component={SelectionProcessPage}
      />
      <PrivateRoute
        exact
        path={SELECTION_PROCESS_OF_APPLICANT_PAGE}
        component={SelectionProcessOfApplicantPage}
      />
      <PrivateRoute
        exact
        path={PATTERN_DETAILS_PAGE}
        component={PatternDetailsPage}
      />
      <PrivateRoute exact path={PATTERNS_PAGE} component={PatternsPage} />
      <PrivateRoute exact path={SCHEDULE_PAGE} component={SchedulePage} />
      <PrivateRoute exact path={STATS_PAGE} component={StatsPage} />
      <Redirect from="*" to={NOT_FOUND_PAGE} />
    </Switch>
  );
};

export default AppRoutes;
