import {
  UPDATE_PATTERN_ERR,
  UPDATE_PATTERN_SUCCESS,
} from "../../actions/updatePattern/updatePatternActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  pattern: null,
  errorMessage: "",
};

export default createReducer(
  {
    [UPDATE_PATTERN_SUCCESS]: setUpdatePattern,
    [UPDATE_PATTERN_ERR]: setUpdatePatternErrorMessage,
  },
  initialState
);

function setUpdatePattern(state, action) {
  return {
    ...state,
    pattern: action.payload,
  };
}

function setUpdatePatternErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
