import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CreateAdFirstStep = ({
  title,
  setTitle,
  employmentType,
  setEmploymentType,
  workHour,
  setWorkHour,
  expiredAt,
  setExpiredAt,
}) => {
  const { t } = useTranslation();
  const employmentTypeHandler = (type) => {
    setEmploymentType(type);
  };

  const workHourHandler = (type) => {
    setWorkHour(type);
  };

  return (
    <div data-testid="create-job-first-step-form">
      <div className="create-job-form-control">
        <label>{t("common.title")}</label>
        <input
          type="text"
          className="create-job-form-control-first-step-input"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder="ex. Medior React Developer"
        />
      </div>
      <div className="create-job-form-control">
        <label>{t("filters.employmentType")}</label>
        <div className="create-job-form-control-buttons">
          <button
            className={`c-btn  ${
              employmentType === "Work"
                ? "c-btn c-btn--primary"
                : "c-btn--primary-outlined"
            }`}
            onClick={employmentTypeHandler.bind(this, "Work")}
          >
            {t("filters.work")}
          </button>
          <button
            className={`c-btn ${
              employmentType === "Intership"
                ? "c-btn c-btn--primary"
                : "c-btn--primary-outlined"
            }`}
            onClick={employmentTypeHandler.bind(this, "Intership")}
          >
            {t("filters.internship")}
          </button>
        </div>
      </div>
      <div className="create-job-form-control">
        <label>{t("filters.workHour")}</label>
        <div className="create-job-form-control-buttons">
          <button
            className={`c-btn  ${
              workHour === "PartTime"
                ? "c-btn c-btn--primary"
                : "c-btn--primary-outlined"
            }`}
            onClick={workHourHandler.bind(this, "PartTime")}
          >
            {t("filters.partTime")}
          </button>
          <button
            className={`c-btn ${
              workHour === "FullTime"
                ? "c-btn c-btn--primary"
                : "c-btn--primary-outlined"
            }`}
            onClick={workHourHandler.bind(this, "FullTime")}
          >
            {t("filters.fullTime")}
          </button>
        </div>
      </div>
      <div className="create-job-form-control">
        <label>{t("ads.expirationDate")}</label>
        <input
          type="date"
          className="create-job-form-control-first-step-input"
          onChange={(e) => setExpiredAt(e.target.value)}
          value={expiredAt}
        />
      </div>
    </div>
  );
};

CreateAdFirstStep.propTypes = {
  title: PropTypes.string,
  setTitle: PropTypes.any,
  employmentType: PropTypes.string,
  setEmploymentType: PropTypes.any,
  workHour: PropTypes.string,
  setWorkHour: PropTypes.any,
  expiredAt: PropTypes.any,
  setExpiredAt: PropTypes.any,
};

export default CreateAdFirstStep;
