export const FETCH_CV_PREVIEW_REQ = "FETCH_CV_PREVIEW_REQ";
export const FETCH_CV_PREVIEW_ERR = "FETCH_CV_PREVIEW_ERR";
export const FETCH_CV_PREVIEW_SUCCESS = "FETCH_CV_PREVIEW_SUCCESS";

export const SEND_CV_INVITE_REQ = "SEND_CV_INVITE_REQ";
export const SEND_CV_INVITE_ERR = "SEND_CV_INVITE_REQ";
export const SEND_CV_INVITE_SUCCESS = "SEND_CV_INVITE_REQ";

export const RESPOND_TO_CV_INVITE_REQ = "RESPOND_TO_CV_INVITE_REQ";
export const RESPOND_TO_CV_INVITE_ERR = "RESPOND_TO_CV_INVITE_ERR";
export const RESPOND_TO_CV_INVITE_SUCCESS = "RESPOND_TO_CV_INVITE_SUCCESS";

export const CREATE_CV_REQ = "CREATE_CV_REQ";
export const CREATE_CV_ERR = "CREATE_CV_ERR";
export const CREATE_CV_SUCCESS = "CREATE_CV_SUCCESS";
