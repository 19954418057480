import { all, call, put, takeEvery } from "redux-saga/effects";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import { applyForAdRequest } from "../../request/applicantRequest";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import { APPLY_FOR_AD_REQ } from "../actions/applyForAd/applyForAdActionConstants";
import {
  applyForAd,
  applyForAdError,
} from "../actions/applyForAd/applyForAdActions";

export function* applyForAdSaga({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const formData = new FormData();
    formData.append("adId", payload.adId);
    formData.append("firstName", payload.firstName);
    formData.append("lastName", payload.lastName);
    formData.append("gender", payload.gender);
    formData.append("dateOfBirth", payload.dateOfBirth);
    formData.append("phoneNumber", payload.phoneNumber);
    formData.append("professionalQualification", payload.professionalQualification);
    formData.append("technologiesIds", payload.technologiesIds);
    formData.append("experience", payload.experience);
    formData.append("linkedinLink", payload.linkedinLink);
    formData.append("githubLink", payload.githubLink);
    formData.append("bitBucketLink", payload.bitBucketLink);
    formData.append("email", payload.email);
    formData.append("coverLetter", payload.coverLetter);
    formData.append("pdfFile", payload.pdfFile);
    const { data } = yield call(applyForAdRequest, formData);
    yield put(applyForAd(data));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(applyForAdError(errorMessage));
  }
}

export default function* applicantsSaga() {
  yield all([takeEvery(APPLY_FOR_AD_REQ, applyForAdSaga)]);
}
