import { createSelector } from "@reduxjs/toolkit";

export const processesSelector = (state) => state.processes;

export const selectProcesses = createSelector(
  processesSelector,
  (state) => state.processes
);

export const selectProcessesError = createSelector(
  processesSelector,
  (state) => state.errorMessage
);
