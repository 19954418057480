import { all, call, put, takeEvery } from "redux-saga/effects";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import { getTests, createTest } from "../../request/screeningTestRequest";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import { SCREENING_TESTS_FETCH,CREATE_SCREENING_TEST } from "../actions/screeningTests/screeningTestsActionConstants";
import {
  fetchScreeningTestsError,
  fetchScreeningTestsSuccess,
  createScreeningTestSuccess,
  createScreeningTestError,
} from "../actions/screeningTests/screeningTestActions";

export function* getScreeningTests() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getTests);
    yield put(fetchScreeningTestsSuccess(result.data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(fetchScreeningTestsError(errorMessage));
    }
  }
}

export function* createScreeningTest({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(createTest, payload);
    yield put(createScreeningTestSuccess(result.data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(createScreeningTestError(errorMessage));
    }
  }
}

export default function* screeningTestsSaga() {
  yield all([takeEvery(SCREENING_TESTS_FETCH, getScreeningTests)]);
  yield all([takeEvery(CREATE_SCREENING_TEST, createScreeningTest)]);
}
