import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import calendar from "../../../src/assets/images/calendar2.png";
import arrowLeftDisabled from "../../../src/assets/images/arrow_left.png";
import arrowLeft from "../../../src/assets/images/arrow_left2.png";
import arrowRight from "../../../src/assets/images/arrow_right.png";
import x from "../../../src/assets/images/x.png";
import meet from "../../../src/assets/images/meet.png";
import { formatTimeSrb } from "../../util/helpers/dateHelpers";
import { CANDIDATES_PAGE } from "../../constants/pages";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const DayDetailsComponent = ({
  selectedDate,
  selectionProcesses,
  open,
  onClose,
  setCurrentlySelected,
  setCurrentlySelectedDay,
  currentlySelectedDay,
  numberOfDaysInMonth,
  history,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("361"));
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(false);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setIsLeftArrowDisabled(currentlySelectedDay === 1 ? true : false);
    setIsRightArrowDisabled(
      currentlySelectedDay === numberOfDaysInMonth ? true : false
    );
  }, [currentlySelectedDay, isLeftArrowDisabled, isRightArrowDisabled]);

  const goForwardOneDay = () => {
    const date = selectedDate.split(".");
    const day = date[0];
    const month = date[1];
    const year = date[2];
    const newDay = parseInt(day) + 1;
    if (day === numberOfDaysInMonth) {
      setIsRightArrowDisabled(true);
      return;
    }
    setCurrentlySelected(newDay + "." + month + "." + year);
    setCurrentlySelectedDay(newDay);
    setIsLeftArrowDisabled(true);
  };

  const goBackOneDay = () => {
    const date = selectedDate.split(".");
    const day = date[0];
    const month = date[1];
    const year = date[2];
    if (day === 1) {
      setIsLeftArrowDisabled(true);
      return;
    }
    const newDay = parseInt(day) - 1;
    setCurrentlySelected(newDay + "." + month + "." + year);
    setCurrentlySelectedDay(newDay);
  };

  const navigateToCandidateDetailsPage = (applicantId) => {
    history.push({
      pathname: CANDIDATES_PAGE + "/" + applicantId,
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={!matches ? "549" : "339"}
    >
      <div
        className="day-details-sub-container"
        data-testid="day-component-dialog"
      >
        <DialogTitle className="day-datails-title-container">
          <img src={calendar} className="day-details-calendar-image" />
          <p className="day-details-main-header">{t("schedule.planner")}</p>
          <p className="day-details-header">| {selectedDate}.</p>
          <img
            src={x}
            className="day-details-close-btn"
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="day-details-content-sub-container">
            {selectionProcesses &&
              selectionProcesses.map((selectionProcess, index) => (
                <div key={index} data-testid="day-details-component-process">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className="day-details-time">
                      {formatTimeSrb(selectionProcess.date)}h
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: !matches ? "center" : "flex-start",
                        flexDirection: !matches ? "row" : "column",
                      }}
                    >
                      <p className="day-details-name">
                        {selectionProcess.selectionLevel.name}
                      </p>
                      <p
                        className="day-details-applicant"
                        data-testid="day-details-applicant"
                        onClick={() =>
                          navigateToCandidateDetailsPage(
                            selectionProcess.applicant.applicantId
                          )
                        }
                      >
                        {selectionProcess.applicant.firstName}{" "}
                        {selectionProcess.applicant.lastName}
                      </p>
                    </div>
                    <a
                      className="day-details-link"
                      href={selectionProcess.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={meet} />
                      {!matches && <span>Link</span>}
                    </a>
                  </div>
                  <div className="day-details-line" />
                </div>
              ))}
          </div>
          <div
            style={{
              display: "flex",
              gap: "18px",
              marginTop: selectionProcesses.length === 0 ? "18px" : "0px",
            }}
          >
            {isLeftArrowDisabled === true ? (
              <div className="day-details-arrow-container">
                <img src={arrowLeftDisabled} />
              </div>
            ) : (
              <div
                className="day-details-arrow-container"
                data-testid="day-details-left-arrow"
                onClick={goBackOneDay}
              >
                <img src={arrowLeft} />
              </div>
            )}
            {isRightArrowDisabled === true ? (
              <div className="day-details-arrow-container-p">
                <img src={arrowLeftDisabled} />
              </div>
            ) : (
              <div
                className="day-details-arrow-container"
                onClick={goForwardOneDay}
                data-testid="day-details-right-arrow"
              >
                <img src={arrowRight} />
              </div>
            )}
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

DayDetailsComponent.propTypes = {
  selectedDate: PropTypes.string,
  selectionProcesses: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setCurrentlySelected: PropTypes.func,
  setCurrentlySelectedDay: PropTypes.func,
  currentlySelectedDay: PropTypes.number,
  numberOfDaysInMonth: PropTypes.number,
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default DayDetailsComponent;
