import {
    FETCH_APPLICANT_PROCESSES_ERR,
    FETCH_APPLICANT_PROCESSES_SUCCESS
  } from "../../actions/processes/processesActionConstants";
  import createReducer from "../../utils/createReducer";
  
  const initialState = {
      applicant: {},
      errorMessage: ""
  };
  
  export default createReducer(
    {
      [FETCH_APPLICANT_PROCESSES_SUCCESS]: setStateApplicant,
      [FETCH_APPLICANT_PROCESSES_ERR]: setApplicantErrorMessage,
    },
    initialState
  );
  
  function setStateApplicant(state, action) {
    return {
      ...state,
      applicant: action.payload,
    };
  }
  
  function setApplicantErrorMessage(state, action) {
    return {
      ...state,
      errorMessage: action.payload,
    };
  }