import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
// import aspNetIcon from "../../assets/images/.net_icon.png";
// import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAdReq } from "../../store/actions/ad/adActions";
import { selectAd } from "../../store/selectors/adSelectors";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow_left from "../../assets/images/arrow_left.png";
import arrow_right from "../../assets/images/arrow_right.png";
import AdDetailsCandidateCard from "../../components/Ads/AdDetailsCandidateCard";
import { selectLogo } from "../../util/helpers/technologiesLogos";
import { archiveActiveAdReq } from "../../store/actions/archiveActiveAd/archiveActiveAdActions";
import { ADS_PAGE } from "../../constants/pages";
import { useHistory } from "react-router-dom";
import archiveIcon from "../../assets/images/archive.png";
import ApplyForAd from "../../components/Ads/ApplyForAd";
import { useState } from "react";
import parse from "html-react-parser";
import ConfirmDialog from "../../components/MUI/ConfirmDialog";
import IconButton from "../../components/IconButton/IconButton";

const AdDetailsPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  const [applyForAdOpenModal, setApplyForAdOpenModal] = useState(false);
  const [showArchiveAdDialog, setShowArchiveAdDialog] = useState(false);
  const ad = useSelector(selectAd);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const archiveAdsSliderRef = useRef();
  const history = useHistory();

  useEffect(() => {
    dispatch(setAdReq({ id }));
  }, []);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleCloseApplyForAdModal = () => {
    setApplyForAdOpenModal(false);
  };

  const navigateToAds = () => {
    history.push(ADS_PAGE);
  };

  const archiveAdHandler = () => {
    dispatch(archiveActiveAdReq({ id, navigateToAds }));
  };

  const archiveAdsArrowLeftHandler = () => {
    archiveAdsSliderRef.current.slickPrev();
  };

  const archiveAdsArrowRightHandler = () => {
    archiveAdsSliderRef.current.slickNext();
  };

  const getDummyArchiveAds = (len) => {
    const ads = [];

    for (let i = 0; i < 5 - len + 1; i++) {
      ads.push(<AdDetailsCandidateCard key={i} className="hiddenAd" />);
    }

    return ads;
  };

  return (
    <>
      {!ad && <p>Radi</p>}
      {ad && (
        <div data-testid="job-details-page">
          <ApplyForAd
            open={applyForAdOpenModal}
            title={ad.title}
            adId={id}
            onCloseModal={handleCloseApplyForAdModal}
          />
          <div className="job-details">
            {matches && (
              <div className="job-details-tech-logo-date">
                <p>
                  <span>{t("ads.adDetailsExpiredAt")}: </span>
                  {new Date(ad.expiredAt).toLocaleDateString()}
                </p>
              </div>
            )}
            <div className="job-details-tech-logo">
              <div className="job-details-tech-logo-title">
                <div className="job-details-tech-logo-title-img">
                  <img src={selectLogo(ad.title)} alt="asp-net-icon" />
                </div>
                <div className="job-details-tech-logo-title-title">
                  <h1>{ad.title}</h1>
                </div>
                <div className="job-details-tech-logo-title-sub">
                  <sub>
                    | {ad.totalApplicants} {t("ads.registered")}
                  </sub>
                </div>
              </div>
              {!(new Date(ad.expiredAt) < new Date()) && (
                <IconButton
                  onClick={() => setShowArchiveAdDialog(true)}
                  className={`c-btn--primary-outlined editEnableBtn c-btn userPageBtn archive-job-button
              }`}
                >
                  {!matches && "Arhiviraj"}
                  <img
                    style={{
                      position: "relative",
                      top: -0.25,
                      paddingLeft: matches ? "0px" : "10px",
                    }}
                    src={archiveIcon}
                  />
                </IconButton>
              )}
            </div>
            <ConfirmDialog
              open={showArchiveAdDialog}
              title={t("ads.archivingAd")}
              subtitle={ad.title}
              imgSrc={archiveIcon}
              content={t("ads.archivingAdQuestion")}
              onClose={() => {
                setShowArchiveAdDialog(false);
              }}
              onConfirm={() => archiveAdHandler()}
            />
            <div className="job-details-content">
              <div className="job-details-content-experience">
                <p>
                  {ad.minimumExperience}+ {t("ads.adDetailsExperience")}
                </p>
              </div>
              <div className="job-details-content-work-time">
                <button>{t("filters.work")}</button>
                <button>{t("filters.fullTime")}</button>
              </div>
              <div className="job-details-content-content">
                <div className="job-details-content-conten-description">
                  <p>{t("ads.adDetailsDescription")}</p>
                </div>
                <div className="job-details-content-conten-description">
                  <h3>{t("ads.adDetailsKeyResponsibilities")}</h3>
                  {parse(ad.keyResponsibilities)}
                </div>
                <div className="job-details-content-conten-description">
                  <h3>{t("ads.adDetailsRequirements")}</h3>
                  {parse(ad.requirements)}
                </div>
                <div className="job-details-content-conten-description">
                  <h3>{t("ads.adDetailsOffer")}</h3>
                  {parse(ad.offer)}
                </div>
              </div>
            </div>
            {ad.applicants && ad.applicants.length > 0 && (
              <div className="job-details-applicants">
                <div className="job-details-applicants-header">
                  <h2>{t("ads.archiveAdsCandidates")}</h2>
                </div>
                <div className="job-details-applicants-applicants">
                  {!matches && (
                    <div className="job-details-applicants-applicants-a">
                      <div className="job-details-applicants-applicants-arrows">
                        <button onClick={archiveAdsArrowLeftHandler}>
                          <img src={arrow_left} alt="arrow-left" />
                        </button>
                        {ad.applicants.length > 3 && (
                          <button onClick={archiveAdsArrowRightHandler}>
                            <img src={arrow_right} alt="arrow-right" />
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="job-details-applicants-applicants-applicant">
                    <Slider
                      ref={archiveAdsSliderRef}
                      {...settings}
                      slidesToShow={3}
                      slidesToScroll={3}
                      style={{ width: "100%" }}
                    >
                      {ad.applicants.map((applicant, index) => (
                        <AdDetailsCandidateCard
                          key={index}
                          id={applicant.applicantId}
                          firstName={applicant.firstName}
                          lastName={applicant.lastName}
                          experience={applicant.experience}
                          cv={applicant.cv}
                        />
                      ))}
                      {ad.applicants.length <= 3 &&
                        getDummyArchiveAds(ad.applicants.length)}
                    </Slider>
                  </div>
                </div>
                {matches && (
                  <div className="active-jobs-jobs-arrows">
                    <button onClick={archiveAdsArrowLeftHandler}>
                      <img src={arrow_left} alt="arrow-left" />
                    </button>
                    {ad.applicants.length > 3 && (
                      <button onClick={archiveAdsArrowRightHandler}>
                        <img src={arrow_right} alt="arrow-right" />
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="job-details-actions">
              {!matches && (
                <div className="job-details-expired-at">
                  <div className="job-details-tech-logo-date">
                    <p>
                      <span>{t("ads.adDetailsExpiredAt")}: </span>
                      {new Date(ad.expiredAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              )}
              <div className="job-details-buttons">
                <button
                  className="job-details-buttons-link"
                  onClick={() => history.push({ pathname: ADS_PAGE })}
                >
                  {t("ads.backToAds")}
                </button>

                {!(new Date(ad.expiredAt) < new Date()) && (
                  <IconButton
                    className="c-btn c-btn--primary add-job-btn apply-for-job-button"
                    onClick={() => setApplyForAdOpenModal(true)}
                  >
                    {t("ads.signUp").toUpperCase()}
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdDetailsPage;
