import { createSelector } from "@reduxjs/toolkit";

export const statusesSelector = (state) => state.statuses;

export const selecStatuses = createSelector(
    statusesSelector,
  (state) => state.statuses
);

export const selecStatusesError = createSelector(
  statusesSelector,
  (state) => state.errorMessage
);
