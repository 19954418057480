import React from "react";
import PropTypes from "prop-types";
import PDFViewer from "./PDFViewer/PDFViewer";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectIsUserAuthenticated } from "../../store/selectors/userSelectors";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { CANDIDATES_DETAILS_PAGE } from "../../constants/pages";

const CreateCvFinishScreen = ({ pdf }) => {
  const { t } = useTranslation();
  const isUserAuthed = useSelector(selectIsUserAuthenticated);
  const history = useHistory();
  const { token } = useParams();

  return (
    <Box className="cv-finish-main-cont">
      <Typography
        variant="h5"
        sx={{ width: 400, mb: 5, mt: 5 }}
        fontFamily="Source Sans Pro"
        color="#272727"
      >
        {isUserAuthed
          ? t("cv.successfullSubmitAdmin")
          : t("cv.successfullSubmit")}
      </Typography>
      {isUserAuthed && (
        <Button
          variant="outlined"
          className="cv-finish-back-btn"
          onClick={() =>
            history.push(CANDIDATES_DETAILS_PAGE.replace(":id", token))
          }
        >
          {t("cv.backToCandidate")}
        </Button>
      )}
      <PDFViewer pdf={pdf} />
      <Box sx={{ mb: 5 }} />
    </Box>
  );
};

CreateCvFinishScreen.propTypes = {
  pdf: PropTypes.any,
};

export default CreateCvFinishScreen;
