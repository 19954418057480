import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../util/helpers/dateHelpers";
// import { Link } from "react-router-dom";
import success from "../../assets/images/svg/success.svg";
import unsucc from "../../assets/images/unsucc.png";
import { useTranslation } from "react-i18next";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";

const ApplicantSelection = ({
  levelNumber,
  levelName,
  schedguler,
  link,
  date,
  status,
  // id,
  onShowAdDetails,
  className,
  comment,
}) => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="appSelection"
      className={`active-process-card ${className}`}
      onClick={onShowAdDetails}
    >
      <div className="active-process-card-header">
        <div className="active-process-card-number">
          <p>{levelNumber}</p>
        </div>
        {status === "Odrađen" && (
          <div className="active-process-card-logo">
            <img src={success} alt="success-image" />
          </div>
        )}
        {status === "Neuspešno" && (
          <div className="active-process-card-logo">
            <img src={unsucc} alt="success-image" />
          </div>
        )}
      </div>

      <div className="active-process-card-body">
        <div className="active-process-card-date">
          <p>{date && date !== "" && formatDate(new Date(date))}</p>
        </div>

        <div className="job-card-title">
          <h3>{levelName}</h3>
        </div>

        <div className="active-process-card-date">
          <p>{schedguler}</p>
        </div>

        <div className="active-process-card-buttons">
          <button className={status === "Neuspešno" && "unsucc"}>
            {status}
          </button>
        </div>

        <div className="active-process-card-link">
          {status === "Odrađen" && levelNumber === 4 && (
            // <Link className="job-details-buttons-link" to={"/candidates/" + id}>
            //   {t("selection.report")}
            // </Link>
            <div style= {{paddingBottom: "20px"}}>
              <Tippy content={comment}>
                <span>Komentar</span>
              </Tippy>
            </div>
          )}

          {link && status !== "Odrađen" && (
            <a href={link} className="job-details-buttons-link">
              {t("selection.link")}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

ApplicantSelection.propTypes = {
  id: PropTypes.string,
  levelNumber: PropTypes.number,
  levelName: PropTypes.string,
  schedguler: PropTypes.string,
  link: PropTypes.string,
  date: PropTypes.any,
  status: PropTypes.string,
  onShowAdDetails: PropTypes.func,
  className: PropTypes.any,
  comment: PropTypes.string,
};

export default ApplicantSelection;
