import { createSelector } from "@reduxjs/toolkit";

export const adsSelector = (state) => state.ads;

export const selectAds = createSelector(adsSelector, (state) => state.ads);

export const selectAdsError = createSelector(
  adsSelector,
  (state) => state.errorMessage
);
