import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ApplyForAdFirstStage = ({
  firstName,
  setFirstName,
  lastName,
  gender,
  setGender,
  setLastName,
  dateOfBirth,
  setDateOfBirth,
  phoneNumber,
  setPhoneNumber,
  onIncreaseStage,
}) => {
  const disabled =
    firstName.length === 0 ||
    lastName.length === 0 ||
    dateOfBirth === "" ||
    phoneNumber.length === 0 ||
    gender.length === 0;

  const { t } = useTranslation();

  return (
    <div>
      <div className="apply-for-job-modal-form-control">
        <label>{t("common.firstName")}</label>
        <input
          type="text"
          placeholder="ex. Petar"
          value={firstName}
          data-testid="apply-for-job-modal-first-name-input"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="apply-for-job-modal-form-control">
        <label>{t("common.lastName")}</label>
        <input
          type="text"
          placeholder="ex. Petrovic"
          value={lastName}
          data-testid="apply-for-job-modal-last-name-input"
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="apply-for-job-modal-form-control">
        <label>{t("common.gender")}</label>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
            <input
              type="radio"
              name="gender"
              value="Muski"
              className="apply-for-job-modal-gender-input"
              checked={gender === "Muski"}
              onChange={(e) => setGender(e.target.value)}
            />
            <p style={{ marginLeft: "5px" }}>{t("common.male")}</p>
          </div>
          <div style={{ display: "flex", marginLeft: "50px" }}>
            <input
              type="radio"
              name="gender"
              value="Zenski"
              className="apply-for-job-modal-gender-input"
              checked={gender === "Zenski"}
              onChange={(e) => setGender(e.target.value)}
            />
            <p style={{ marginLeft: "5px" }}>{t("common.female")}</p>
          </div>
        </div>
      </div>
      <div className="apply-for-job-modal-form-control">
        <label>{t("common.dateOfBirth")}</label>
        <input
          type="date"
          placeholder="ex. Datum rodjenja"
          data-testid="apply-for-job-modal-date-of-birth"
          value={dateOfBirth}
          onChange={(e) => setDateOfBirth(e.target.value)}
        />
      </div>
      <div className="apply-for-job-modal-form-control">
        <label>{t("common.phoneNumber")}</label>
        <input
          type="text"
          placeholder="ex. +381/60/000/0000"
          data-testid="apply-for-job-modal-phone-number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>
      <div className="apply-for-job-buttons">
        <button disabled>{t("common.back")}</button>
        <button
          disabled={disabled}
          data-testid="apply-for-job-modal-go-forward-button"
          onClick={() => onIncreaseStage()}
        >
          {t("common.continue")}
        </button>
      </div>
    </div>
  );
};

ApplyForAdFirstStage.propTypes = {
  firstName: PropTypes.string,
  setFirstName: PropTypes.any,
  lastName: PropTypes.string,
  gender: PropTypes.string,
  setGender: PropTypes.func,
  setLastName: PropTypes.any,
  dateOfBirth: PropTypes.any,
  setDateOfBirth: PropTypes.any,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.any,
  onIncreaseStage: PropTypes.func,
};

export default ApplyForAdFirstStage;
