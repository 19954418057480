import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import EducationForm from "./Forms/EducationForm";
import plusIcon from "../../assets/images/plus.png";
import { EDUCATION_STEP, OTHER_SKILLS_STEP } from "../../constants/cv";

const CreateCvEducationStep = ({
  cvData,
  setCvData,
  nextStep,
  setNextStep,
  setActiveStep,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState([
    {
      degreeTitle: false,
      faculty: false,
      city: false,
      country: false,
      startDate: false,
    },
  ]);

  useEffect(() => {
    if (cvData.educations.length) return;

    handleAddFields();
  }, []);

  useEffect(() => {
    validate();
  }, [nextStep]);

  const handleAddFields = () => {
    setCvData((state) => ({
      ...state,
      educations: [
        ...state.educations,
        {
          degreeTitle: "",
          university: "",
          faculty: "",
          city: "",
          country: "",
          isCourse: false,
          startDate: null,
          endDate: null,
        },
      ],
    }));

    setErrors((prev) => [
      ...prev,
      {
        degreeTitle: false,
        faculty: false,
        city: false,
        country: false,
        startDate: false,
      },
    ]);
  };

  const validate = () => {
    if (nextStep !== OTHER_SKILLS_STEP) return;

    const newErrors = cvData.educations.map((education) => {
      const errorObj = {
        degreeTitle: !education.degreeTitle.trim(),
        faculty: !education.isCourse && !education.faculty.trim(),
        city: !education.isCourse && !education.city.trim(),
        country: !education.isCourse && !education.country.trim(),
        startDate: !education.startDate,
      };

      return errorObj;
    });

    const hasErrors = newErrors.some((error) =>
      Object.values(error).some((field) => field)
    );

    if (hasErrors) {
      setErrors(newErrors);
      setNextStep(EDUCATION_STEP);
      return;
    }

    setActiveStep(OTHER_SKILLS_STEP);
  };

  return (
    <>
      {cvData.educations.map((field, index) => (
        <div key={index}>
          <EducationForm
            field={field}
            index={index}
            cvData={cvData}
            setCvData={setCvData}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      ))}
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <IconButton
          sx={{ mb: 10 }}
          className={"c-btn--primary-outlined c-btn"}
          onClick={handleAddFields}
        >
          <img
            src={plusIcon}
            width={12}
            height={12}
            style={{ marginRight: "10px" }}
          />
          {t("cv.education")}
        </IconButton>
      </Box>
    </>
  );
};

CreateCvEducationStep.propTypes = {
  cvData: PropTypes.object,
  setCvData: PropTypes.func,
  nextStep: PropTypes.number,
  setNextStep: PropTypes.func,
  setActiveStep: PropTypes.func,
};

export default CreateCvEducationStep;
