import {
  SCHEDULE_APPOINTMENT_SUCCESS,
  SCHEDULE_APPOINTMENT_ERR,
  CLEAR_NOT_SENT_EMAILS_ARRAY,
} from "../../actions/scheduleAppointment/scheduleAppointmentActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  notSentEmails: null,
  errorMessage: "",
};

export default createReducer(
  {
    [SCHEDULE_APPOINTMENT_SUCCESS]: setStateScheduleAppointment,
    [SCHEDULE_APPOINTMENT_ERR]: setScheduleAppointmentErrorMessage,
    [CLEAR_NOT_SENT_EMAILS_ARRAY]: setNotSentEmailsArrayNull,
  },
  initialState
);

function setStateScheduleAppointment(state, action) {
  return {
    ...state,
    notSentEmails: action.payload ? [...action.payload.notSentEmails] : null,
  };
}

function setScheduleAppointmentErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setNotSentEmailsArrayNull(state) {
  return {
    ...state,
    notSentEmails: null,
  };
}
