import {
  SCHEDULE_FETCH,
  SCHEDULE_ERROR,
  SCHEDULE_SUCCESS,
} from "./scheduleActionConstants";

export const fetchSchedule = (payload) => ({
  type: SCHEDULE_FETCH,
  payload,
});

export const fetchScheduleError = (payload) => ({
  type: SCHEDULE_ERROR,
  payload,
});

export const fetchScheduleSuccess = (payload) => ({
  type: SCHEDULE_SUCCESS,
  payload,
});
