import {
    FETCH_STATUSES_ERR,
    FETCH_STATUSES_SUCCESS,
    FETCH_STATUSES_REQ,
    CHANGE_STATUS_ISCHECKED_VALUE
  } from "./processesActionConstants";
  
  export const setStatusesReq = () => ({
      type: FETCH_STATUSES_REQ,
    });
    
    export const setStatusesError = (payload) => ({
      type: FETCH_STATUSES_ERR,
      payload,
    });
    
    export const setStatuses = (payload) => ({
      type: FETCH_STATUSES_SUCCESS,
      payload,
    });
    
    export const changeStatusIsCheckedValue = (payload) => ({
      type: CHANGE_STATUS_ISCHECKED_VALUE,
      payload,
    });