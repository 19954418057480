import {
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
} from "../actionHelpers";

const CANDIDATE_SCOPE = "CANDIDATE";
const CANDIDATE_SCOPE_COMMENTS = "CANDIDATE_COMMENTS";
const DELETE_CANDIDATE_SCOPE = "DELETE_CANDIDATE";

export const CANDIDATE_FETCH = createFetchType(CANDIDATE_SCOPE);
export const CANDIDATE_ERROR = createErrorType(CANDIDATE_SCOPE);
export const CANDIDATE_SUCCESS = createSuccessType(CANDIDATE_SCOPE);
export const CANDIDATE_LOADING = createLoadingType(CANDIDATE_SCOPE);

export const CANDIDATE_COMMENTS_FETCH = createFetchType(
  CANDIDATE_SCOPE_COMMENTS
);
export const CANDIDATE_COMMENTS_ERROR = createErrorType(
  CANDIDATE_SCOPE_COMMENTS
);
export const CANDIDATE_COMMENTS_SUCCESS = createSuccessType(
  CANDIDATE_SCOPE_COMMENTS
);
export const CANDIDATE_COMMENTS_LOADING = createLoadingType(
  CANDIDATE_SCOPE_COMMENTS
);

export const DELETE_CANDIDATE = createFetchType(DELETE_CANDIDATE_SCOPE);
export const DELETE_CANDIDATE_ERROR = createErrorType(DELETE_CANDIDATE_SCOPE);
export const DELETE_CANDIDATE_SUCCESS = createSuccessType(
  DELETE_CANDIDATE_SCOPE
);
export const DELETE_CANDIDATE_LOADING = createLoadingType(
  DELETE_CANDIDATE_SCOPE
);
