import { getRequest, postRequest, putRequest } from ".";
import apiEndpoints from "./apiEndpoints";

export const getAllAds = () => getRequest(apiEndpoints.ads.allAds);
export const getAllFilteredAds = (payload) => {
  let technologiesQuery = "";
  for (let i = 0; i < payload.technologies.length; i++) {
    technologiesQuery += `technologies=${payload.technologies[i]}&`;
  }
  return getRequest(
    apiEndpoints.ads.allFilteredAds +
      `?minExperience=${payload.minExperience}&maxExperience=${payload.maxExperience}&workHour=${payload.workHour}&employmentType=${payload.employmentType}&${technologiesQuery}`
  );
};
export const getAllArchiveAds = () =>
  getRequest(apiEndpoints.ads.allArchiveAds);
export const getAdDetailsById = (id) =>
  getRequest(`${apiEndpoints.ads.adDetails}/${id}`);
export const createNewAd = (ad) => postRequest(apiEndpoints.ads.createAd, ad);
export const archiveActiveAdRequest = (payload) =>
  putRequest(
    apiEndpoints.ads.archiveActiveAd.replace(":id", payload.id),
    payload
  );
