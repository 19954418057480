export const steps = [
  "profile",
  "experience",
  "skills",
  "education",
  "otherSkillsAndAccomplishments",
  "finish",
];

export const CV_DEBOUNCE_INPUT_TIMEOUT = 400;

export const PROFILE_STEP = 0;
export const EXPERIENCE_STEP = 1;
export const SKILLS_STEP = 2;
export const EDUCATION_STEP = 3;
export const OTHER_SKILLS_STEP = 4;
export const FINISH_STEP = 5;

export const cvInitial = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  description: "",
  experiences: null,
  skills: [],
  educations: [],
  otherSkillsAndAccomplishments: null,
};

export const getCvDto = (cvData) => {
  return {
    firstName: cvData.firstName,
    lastName: cvData.lastName,
    phone: cvData.phone,
    email: cvData.email,
    profileDescription: cvData.description,
    experiences: cvData.experiences?.map((experience) => ({
      positionName: experience.positionName,
      companyName: experience.companyName,
      companyCity: experience.companyCity,
      companyCountry: experience.companyCountry,
      startDate: experience.startDate,
      endDate: experience.endDate,
      isFreelance: experience.isFreelance,
      notableProjects: experience.projects.map((project) => ({
        name: project.name,
        description: project.description,
      })),
    })),
    skills: cvData.skills.map((skill) => ({
      technologyStack: skill.techStack,
      technologies: skill.techs.map((tech) => ({
        techOrTool: tech.techOrTool,
        yearsExperience: tech.yearsExperience,
      })),
    })),
    educations: cvData.educations.map((education) => ({
      degreeTitle: education.degreeTitle,
      university: education.university,
      faculty: education.faculty,
      city: education.city,
      country: education.country,
      isCourse: education.isCourse,
      startDate: education.startDate,
      endDate: education.endDate,
    })),
    otherSkillsAndAccomplishments: cvData.otherSkillsAndAccomplishments && {
      hobby: cvData.otherSkillsAndAccomplishments.hobby,
      driversLicence: cvData.otherSkillsAndAccomplishments.driversLicence,
      maritalStatus: cvData.otherSkillsAndAccomplishments.maritalStatus,
      interests: cvData.otherSkillsAndAccomplishments.interests,
    },
  };
};

export const cvInitialDummy = {
  firstName: "John",
  lastName: "Doe",
  email: "john.doe@example.com",
  phone: "+1234567890",
  description:
    "Experienced software engineer with expertise in web development.",
  experiences: [
    {
      positionName: "Senior Software Engineer",
      companyName: "Tech Solutions Inc.",
      companyCity: "New York",
      companyCountry: "USA",
      startDate: new Date(2018, 0, 1), // January 1, 2018
      endDate: new Date(2022, 11, 31), // December 31, 2022
      projects: [
        {
          name: "Project A",
          description:
            "Developed a scalable web application using React and Node.js.",
        },
      ],
    },
  ],
  skills: [
    {
      techStack: "Full Stack Development",
      techs: [
        {
          techOrTool: "JavaScript",
          yearsExperience: 4,
        },
      ],
    },
  ],
  educations: [
    {
      degreeTitle: "Bachelor of Science in Computer Science",
      university: "University of Example",
      faculty: "Computer Science",
      city: "Example City",
      country: "Example Country",
      startDate: new Date(2014, 8, 1), // September 1, 2014
      endDate: new Date(2018, 5, 30), // June 30, 2018
    },
  ],
  otherSkillsAndAccomplishments: {
    hobby: "Playing guitar",
    driversLicence: "Yes",
    maritalStatus: false,
    interests: "Reading, hiking",
  },
};
