import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import x from "../../assets/images/x.png";
import { Editor } from "@tinymce/tinymce-react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
  DialogContent,
  //   TextField,
  //   TextField,
} from "@mui/material";
import IconButton from "../IconButton/IconButton";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDispatch, useSelector } from "react-redux";
// import { format, isValid } from "date-fns";
// import { fetchInitProcess } from "../../store/actions/candidates/candidatesActions";
import { useEffect } from "react";
import { SelectionContext } from "../../context/SelectionContext";
import { setUpdateStatusReq } from "../../store/actions/processes/processAction";
import { useTranslation } from "react-i18next";
import { TINYMCE_API_KEY } from "../../secrets";
// import { setUpdateInterviewerReq } from "../../store/actions/processes/processAction";
// import { setUpdateStatusReq } from "../../store/actions/processes/processAction";

const CommentProcessDialog = ({
  title,
  subtitle,
  imgSrc,
  onClose,
  open,
  maxWidth,
  fullWidth,
  responsive,
}) => {
  const { activeProcessUnsuccess, setActiveProcessUnsuccess } =
    useContext(SelectionContext);
  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const editorContent = useRef();

  const { isSuccess } = useSelector((s) => s.interviewerUpdate);

  const dispatch = useDispatch();

  useEffect(() => {
    handleClose();
  }, [dispatch, isSuccess]);

  const handleClose = () => {
    onClose();
  };

  const submitHandler = () => {
    dispatch(
      setUpdateStatusReq({
        data: {
          comment: editorContent.current.getContent(),
          newStatus: "Neuspešno",
          processId: activeProcessUnsuccess.id,
        },
        responseHandler: apiSuccess,
      })
    );
  };

  const apiSuccess = () => {
    setActiveProcessUnsuccess(null);
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      keepMounted={false}
      fullWidth={fullWidth}
      fullScreen={responsive && fullScreen}
      onClose={handleClose}
      open={open}
      style={{
        padding: "0px",
      }}
    >
      {/* {activeInterview?.id} */}
      <div style={{ padding: "25px" }}>
        <DialogTitle style={{ padding: 0 }}>
          {fullScreen ? (
            <>
              <div className="flex-center" style={{ justifyContent: "start" }}>
                <img
                  style={{
                    position: "relative",
                    top: -0.25,
                    paddingRight: "10px",
                  }}
                  src={imgSrc}
                />
                <h5 style={{ textAlign: "start" }}>{title}</h5>
                <div style={{ justifySelf: "stretch", flex: "1" }}></div>
                <IconButton onClick={onClose}>
                  <img
                    style={{
                      position: "relative",
                      top: -0.25,
                    }}
                    src={x}
                  />
                </IconButton>
              </div>
              <p
                className="dialog-subtitle"
                style={{ paddingLeft: "23px", marginTop: "-10px" }}
              >
                | {subtitle}
              </p>
            </>
          ) : (
            <div
              className="flex-center"
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex-center" style={{ justifyContent: "start" }}>
                <img
                  style={{
                    position: "relative",
                    top: -0.25,
                    paddingRight: "10px",
                  }}
                  src={imgSrc}
                />
                <h5>{title}</h5>
                <div className="vr"></div>
                <p className="dialog-subtitle">{subtitle}</p>
              </div>
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <form className="modal-content comment">
            <p
              style={{
                width: "100%",
                textAlign: "left",
                alignSelf: "flex-start",
                marginBottom: "10px",
              }}
            >
              Komentar (opciono):
            </p>
            <Editor
              apiKey={TINYMCE_API_KEY}
              onInit={(evt, editor) => (editorContent.current = editor)}
              style={{ height: "20px" }}
            />
          </form>
        </DialogContent>
        <DialogActions style={{ padding: 0, justifyContent: "space-between" }}>
          {!fullScreen ? (
            <IconButton
              data-testid="editbtn"
              className={`c-btn--primary-outlined interview-btn c-btn dialog-btn`}
              onClick={onClose}
            >
              {t("common.cancel")}
            </IconButton>
          ) : (
            ""
          )}
          <IconButton
            data-testid="editbtn"
            className={`c-btn--primary-outlined sm-full interview-btn c-btn dialog-btn`}
            onClick={submitHandler}
          >
            {t("common.confirm")}
          </IconButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

CommentProcessDialog.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  imgSrc: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  fullWidth: PropTypes.bool,
  responsive: PropTypes.bool,
};

export default CommentProcessDialog;
