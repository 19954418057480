import aspnet from "../../assets/images/.net_icon.png";
import backend from "../../assets/images/backend.png";
import dataanalyst from "../../assets/images/dataanalyst.png";
import devops from "../../assets/images/devops.png";
import qa from "../../assets/images/qa.png";
import logo_react from "../../assets/images/logo_react.png";
import hr from "../../assets/images/HR.png";

export const technologiesLogos = {
  '.net': aspnet,
  'backend': backend,
  'dataanalyst': dataanalyst,
  'devops': devops,
  'qa': qa,
  'react': logo_react,
  'hr': hr,
};

const takeLogo = (title) => {
    if(!title) {
        return technologiesLogos['hr']
    }

    switch(true) {
        case title.toLowerCase().includes('.net'):
            return technologiesLogos['.net'];
        case title.toLowerCase().includes('backend'):
            return technologiesLogos['backend'];
        case title.toLowerCase().includes('dataanalyst'):
            return technologiesLogos['dataanalyst'];
        case title.toLowerCase().includes('devops'):
            return technologiesLogos['devops'];
        case title.toLowerCase().includes('qa'):
            return technologiesLogos['qa'];
        case title.toLowerCase().includes('react'):
            return technologiesLogos['react'];
        default:
            return technologiesLogos['hr']
    }
}

export const selectLogo = (title) => {
    return takeLogo(title)
}
