import {
  UPDATE_PATTERN_REQ,
  UPDATE_PATTERN_ERR,
  UPDATE_PATTERN_SUCCESS,
} from "./updatePatternActionConstants";

export const updatePatternReq = (payload) => ({
  type: UPDATE_PATTERN_REQ,
  payload,
});

export const updatePatternError = (payload) => ({
  type: UPDATE_PATTERN_ERR,
  payload,
});

export const updatePattern = (payload) => ({
  type: UPDATE_PATTERN_SUCCESS,
  payload,
});
