import React from "react";
import PropType from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import filterIcon from "../../assets/images/filters.png";
import x from "../../assets/images/x.png";
import { useTranslation } from "react-i18next";

const CustomDrawer = ({ title, open, onCloseDrawer, children }) => {
  const { t } = useTranslation();
  const list = () => (
    <Box
      sx={{
        width: 360,
        height: "100%",
        borderRadius: "18px 0 0 18px",
        padding: "36px",
      }}
      role="presentation"
      onKeyDown={onCloseDrawer}
    >
      <div data-testid="pattern-filters">
        <div className="custom-filter-drawer-header-container">
          <div className="custom-filter-drawer-header">
            <img src={filterIcon} alt="filter_icon" />
            <h3>{t("filters.filters")}</h3>
            <p>
              <sub>| {title}</sub>
            </p>
          </div>
          <div
            className="custom-filter-drawer-header-close"
            onClick={onCloseDrawer}
          >
            <img src={x} alt="x" />
          </div>
        </div>
        <div className="custom-filter-drawer-content">{children}</div>
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={onCloseDrawer}>
        {list()}
      </Drawer>
    </div>
  );
};

CustomDrawer.propTypes = {
  title: PropType.string,
  open: PropType.bool,
  onCloseDrawer: PropType.func,
  children: PropType.any,
};

export default CustomDrawer;
