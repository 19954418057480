import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import FilterButton from "../../components/Button/FilterButton";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import userPageBtnIcon from "../../assets/images/userPageBtnIcon.png";
import PatternCard from "../../components/Patterns/PatternCard";
import { useDispatch, useSelector } from "react-redux";
import { setPatternsReq } from "../../store/actions/patterns/patternsActions";
import { selectPatterns } from "../../store/selectors/patternsSelectors";
import { selectProcesses } from "../../store/selectors/processesSelectors";
import { PATTERN_DETAILS_PAGE } from "../../constants/pages";
import CustomModal from "../../components/UI/CustomModal";
import plusIcon from "../../assets/images/plus.png";
import xIcon from "../../assets/images/x.png";
import { setProcessesReq } from "../../store/actions/processes/processesAction";
import { createPatternReq } from "../../store/actions/createPattern/createPatternActions";
import { updatePatternReq } from "../../store/actions/updatePattern/updatePatternActions";
import PatternFilters from "../../components/Patterns/PatternFilters";
import IconButton from "../../components/IconButton/IconButton";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";
import { TINYMCE_API_KEY } from "../../secrets";

const PatternsPage = ({ history }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isShownEdit, setIsShownEdit] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openAddPatternModal, setOpenAddPatternModal] = useState(false);
  const [openEditPatternModal, setOpenEditPatternModal] = useState(false);
  const [editPattern, setEditPattern] = useState(null);
  const [addPatternTitle, setAddPatternTitle] = useState("");
  const [addPatternCategory, setAddPatternCategory] = useState(1);
  const [selectionLevelFilter, setSelectionLevelFilter] = useState([]);
  const patterns = useSelector(selectPatterns);
  const processes = useSelector(selectProcesses);
  const dispatch = useDispatch();
  const addPatternEditor = useRef();
  const editPatternEditor = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setPatternsReq());
    dispatch(setProcessesReq());
  }, []);

  useEffect(() => {
    if (processes && processes.length > 0) {
      setAddPatternCategory(processes[0].id);
      const tmpSelectionLevelFilter = processes.map((level) => ({
        ...level,
        isChecked: false,
      }));
      setSelectionLevelFilter([...tmpSelectionLevelFilter]);
    }
  }, [processes]);

  const closeAddPatternModalHandler = () => {
    setOpenAddPatternModal(false);
  };

  const openEditModalHandler = (pattern) => {
    setEditPattern({
      id: pattern.id,
      title: pattern.title,
      createdAt: pattern.createdAt,
      selectionLevelId: pattern.selectionLevel.id,
      message: pattern.message,
    });
    setOpenEditPatternModal(true);
  };

  const closeEditPatternModalHandler = () => {
    setEditPattern(null);
    setOpenEditPatternModal(false);
  };

  const closeFilterDrawerHandler = () => {
    setOpenFilterDrawer(false);
  };

  const submitAddPatternHandler = (e) => {
    e.preventDefault();

    if (
      addPatternTitle.length === 0 ||
      addPatternEditor.current.getContent().length === 0
    ) {
      return;
    }

    dispatch(
      createPatternReq({
        title: addPatternTitle,
        selectionLevelId: addPatternCategory,
        message: addPatternEditor.current.getContent(),
      })
    );

    setOpenAddPatternModal(false);
    setAddPatternTitle("");
    setAddPatternCategory(processes[0].id);
  };

  const submitEditPatternHandler = (e) => {
    e.preventDefault();

    if (
      editPattern.title.length === 0 ||
      editPatternEditor.current.getContent() === 0
    ) {
      return;
    }

    dispatch(
      updatePatternReq({
        id: editPattern.id,
        title: editPattern.title,
        createdAt: editPattern.createdAt,
        selectionLevelId: editPattern.selectionLevelId,
        message: editPatternEditor.current.getContent(),
      })
    );

    setOpenEditPatternModal(false);
  };

  const handleFilterCheckboxes = (id) => {
    const tmpSelectionLevelFilter = selectionLevelFilter.map((level) =>
      level.id === id ? { ...level, isChecked: !level.isChecked } : level
    );
    setSelectionLevelFilter(tmpSelectionLevelFilter);
  };

  return (
    <div data-testid="patterns-page">
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <PatternFilters
        openFilterDrawer={openFilterDrawer}
        handleClose={closeFilterDrawerHandler}
        selectionLevelFilter={selectionLevelFilter}
        onChangeFilterCheckboxes={handleFilterCheckboxes}
      />
      <CustomModal
        open={openAddPatternModal}
        onCloseModal={closeAddPatternModalHandler}
        classes="add-pattern-modal"
      >
        <div className="add-pattern-modal-header">
          <div className="add-pattern-modal-header-title">
            <div className="add-pattern-modal-header-title-image">
              <img src={plusIcon} alt="plus" />
            </div>
            <div className="add-pattern-modal-header-title-title">
              <p>{t("ads.adding")}</p>
            </div>
            <div className="add-pattern-modal-header-title-sub">
              <sub> | {t("patterns.pattern")}</sub>
            </div>
          </div>
          <div
            className="add-pattern-modal-header-close"
            onClick={closeAddPatternModalHandler}
          >
            <img src={xIcon} alt="close" />
          </div>
        </div>
        <form onSubmit={submitAddPatternHandler}>
          <div className="add-pattern-modal-form-control">
            <label>{t("common.title")}</label>
            <input
              type="text"
              placeholder="ex. Datum HR intervjua"
              onChange={(e) => setAddPatternTitle(e.target.value)}
              value={addPatternTitle}
            />
          </div>
          <div className="add-pattern-modal-form-control">
            <label>{t("filters.category")}</label>
            <select
              name="add-pattern-category"
              value={addPatternCategory}
              onChange={(e) => setAddPatternCategory(e.target.value)}
            >
              {processes &&
                processes.map((process) => (
                  <option key={process.id} value={process.id}>
                    {process.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="add-pattern-modal-form-control">
            <label>{t("patterns.messageText")}</label>
            <Editor
              apiKey={TINYMCE_API_KEY}
              onInit={(evt, editor) => (addPatternEditor.current = editor)}
              style={{ height: "100px !important" }}
            />
          </div>
          <div className="add-pattern-modal-form-control">
            <input type="submit" value="DODAJ ŠABLON" />
          </div>
        </form>
      </CustomModal>
      <CustomModal
        open={openEditPatternModal}
        onCloseModal={closeEditPatternModalHandler}
        classes="edit-pattern-modal"
      >
        <div className="edit-pattern-modal-header">
          <div className="edit-pattern-modal-header-title">
            <div className="edit-pattern-modal-header-title-image">
              <img src={userPageBtnIcon} alt="plus" />
            </div>
            <div className="edit-pattern-modal-header-title-title">
              <p>{t("patterns.editing")}</p>
            </div>
            <div className="edit-pattern-modal-header-title-sub">
              <sub> | {t("patterns.pattern")}</sub>
            </div>
          </div>
          <div
            className="edit-pattern-modal-header-close"
            onClick={closeEditPatternModalHandler}
          >
            <img src={xIcon} alt="close" />
          </div>
        </div>
        <form onSubmit={submitEditPatternHandler}>
          <div className="edit-pattern-modal-form-control">
            <label>{t("common.title")}</label>
            <input
              type="text"
              placeholder="ex. Datum HR intervjua"
              onChange={(e) =>
                setEditPattern((oldState) => ({
                  ...oldState,
                  title: e.target.value,
                }))
              }
              value={editPattern ? editPattern.title : ""}
            />
          </div>
          <div className="edit-pattern-modal-form-control">
            <label>{t("filters.category")}</label>
            <select
              name="edit-pattern-category"
              value={editPattern ? editPattern.selectionLevelId : 1}
              onChange={(e) =>
                setEditPattern((oldState) => ({
                  ...oldState,
                  selectionLevelId: e.target.value,
                }))
              }
            >
              {processes &&
                processes.map((process) => (
                  <option key={process.id} value={process.id}>
                    {process.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="edit-pattern-modal-form-control">
            <label>{t("patterns.messageText")}</label>
            <Editor
              apiKey={TINYMCE_API_KEY}
              initialValue={editPattern ? editPattern.message : ""}
              onInit={(evt, editor) => (editPatternEditor.current = editor)}
              style={{ height: "100px !important" }}
            />
          </div>
          <div className="edit-pattern-modal-form-control">
            <input type="submit" value="UREDI ŠABLON" />
          </div>
        </form>
      </CustomModal>
      <div className="patterns">
        <div className="patterns-header">
          <div>
            <h1>{t("patterns.patternsMade")}</h1>
          </div>
          <div style={{ display: "flex" }}>
            <IconButton
              onClick={() => setIsShownEdit((oldState) => !oldState)}
              className={`c-btn--primary-outlined c-btn editEnableBtn userPageBtn ${
                isShownEdit && "pattern-header-active-button"
              }`}
            >
              {!matches && t("patterns.editing2")}
              <img
                style={{
                  position: "relative",
                  top: -0.25,
                  paddingLeft: matches ? "0px" : "10px",
                }}
                src={userPageBtnIcon}
              />
            </IconButton>
            <FilterButton onShowFilters={() => setOpenFilterDrawer(true)} />
          </div>
        </div>
        <div className="patterns-cards">
          {!patterns && (
            <div>
              <p>Loading...</p>
            </div>
          )}
          {patterns && patterns.length === 0 && (
            <div>
              <p>{t("patterns.noPatterns")}</p>
            </div>
          )}
          {patterns &&
            patterns.length > 0 &&
            patterns.map((pattern) => (
              <div className="pattern-card-parent" key={pattern.id}>
                <PatternCard
                  createdAt={pattern.createdAt}
                  title={pattern.title}
                  selectionProcess={pattern.selectionLevel.name}
                  onOpenEditModal={openEditModalHandler.bind(this, pattern)}
                  isShownEdit={isShownEdit}
                  onShowPatternDetails={() =>
                    history.push(
                      PATTERN_DETAILS_PAGE.replace(":id", pattern.id)
                    )
                  }
                />
              </div>
            ))}
        </div>
        <div className="patterns-button">
          <IconButton
            className="c-btn c-btn--primary add-job-btn add-pattern-btn"
            sx={{ p: 0, m: 0 }}
            onClick={() => setOpenAddPatternModal(true)}
          >
            + {t("patterns.addPattern")}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

PatternsPage.propTypes = {
  history: PropTypes.any,
};

export default PatternsPage;
