import { ERROR_PAGE } from "../../constants/pages";
import { attachPostRequestListener } from "../../request";
import history from "../utils/history";

export default () => (next) => (action) => {
  attachPostRequestListener((error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 500) {
      return history.push(ERROR_PAGE);
    }
    return Promise.reject(error);
  });

  next(action);
};
