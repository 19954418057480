import React from "react";
import PropTypes from "prop-types";
import { formatTimeSrb } from "../../util/helpers/dateHelpers";
import { useTranslation } from "react-i18next";

const DayComponent = ({
  isPlaceholder = false,
  isToday,
  numberOfDay,
  nameOfDay,
  interviews,
  onClick,
}) => {
  const { t } = useTranslation();

  if (isPlaceholder) {
    return <div className="day-component-container-placeholder"></div>;
  }

  return (
    <div
      className={"day-component-container"}
      style={{ backgroundColor: isToday && "#f3f9fc" }}
      onClick={onClick}
    >
      <div className="day-component-day-informations-container">
        <text className="day-component-day-number">{numberOfDay}</text>
        <text className="day-component-day-name">{nameOfDay}</text>
      </div>
      {interviews &&
        interviews.slice(0, 2).map((interview, index) => (
          <div
            key={index}
            className="day-component-interviews-container"
            style={{ marginTop: "4px" }}
          >
            <p className="day-component-interviews-name">
              {`${formatTimeSrb(interview.date)} ${
                interview.selectionLevel.name
              }`}
            </p>
          </div>
        ))}
      {interviews && interviews.length > 2 && (
        <span className="day-component-more">
          +{interviews.length - 2} {t("schedule.items")}
        </span>
      )}
    </div>
  );
};

DayComponent.propTypes = {
  isPlaceholder: PropTypes.boolean,
  isToday: PropTypes.boolean,
  numberOfDay: PropTypes.number,
  nameOfDay: PropTypes.string,
  interviews: PropTypes.array,
  onClick: PropTypes.func,
};

export default DayComponent;
