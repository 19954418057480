import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  createPatternRequest,
  getAllPatterns,
  getFilteredPatterns,
  getPatternApplicantsById,
  getPatternById,
  scheduleAppointmentRequest,
  updatePatternRequest,
} from "../../request/patternsRequest";
import {
  setFilteredPatterns,
  setFilteredPatternsError,
  setPatterns,
  setPatternsError,
} from "../actions/patterns/patternsActions";
import { setPattern, setPatternError } from "../actions/pattern/patternActions";
import {
  createPattern,
  createPatternError,
} from "../actions/createPattern/createPatternActions";
import {
  FETCH_FILTERED_PATTERNS_REQ,
  FETCH_PATTERNS_REQ,
} from "../actions/patterns/patternsActionConstants";
import { FETCH_PATTERN_REQ } from "../actions/pattern/patternActionConstants";
import { CREATE_PATTERN_REQ } from "../actions/createPattern/createPatternActionConstants";
import { UPDATE_PATTERN_REQ } from "../actions/updatePattern/updatePatternActionConstants";
import {
  updatePattern,
  updatePatternError,
} from "../actions/updatePattern/updatePatternActions";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import { SCHEDULE_APPOINTMENT_REQ } from "../actions/scheduleAppointment/scheduleAppointmentActionConstants";
import { scheduleAppointment } from "../actions/scheduleAppointment/scheduleAppointmentActions";
import { FETCH_PATTERN_APPLICANTS_REQ } from "../actions/patternApplicants/patternApplicantsActionConstants";
import { setPatternApplicants } from "../actions/patternApplicants/patternApplicantsActions";

export function* getPatterns() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAllPatterns);
    yield put(setPatterns(result.data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setPatternsError(errorMessage));
  }
}

export function* getPattern({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getPatternById, payload.id);
    yield put(setPattern(result.data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setPatternError(errorMessage));
  }
}

export function* getPatternApplicants({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getPatternApplicantsById, payload.id);
    yield put(setPatternApplicants(result.data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setPatternError(errorMessage));
  }
}

export function* filterPatterns({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const { data } = yield call(getFilteredPatterns, payload);
    yield put(setFilteredPatterns(data));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setFilteredPatternsError(errorMessage));
  }
}

export function* createPatternSaga({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(createPatternRequest, payload);
    yield put(createPattern(result.data));
    const resultPatterns = yield call(getAllPatterns);
    yield put(setPatterns(resultPatterns.data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(createPatternError(errorMessage));
  }
}

export function* updatePatternSaga({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(updatePatternRequest, payload);
    yield put(updatePattern(result.data));
    const resultPatterns = yield call(getAllPatterns);
    yield put(setPatterns(resultPatterns.data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(updatePatternError(errorMessage));
  }
}

export function* scheduleAppointmentSaga({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const { data } = yield call(scheduleAppointmentRequest, payload);
    yield put(scheduleAppointment(data));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setFilteredPatternsError(errorMessage));
  }
}

export default function* adsSaga() {
  yield all([
    takeLatest(FETCH_PATTERNS_REQ, getPatterns),
    takeLatest(FETCH_PATTERN_REQ, getPattern),
    takeLatest(FETCH_PATTERN_APPLICANTS_REQ, getPatternApplicants),
    takeLatest(FETCH_FILTERED_PATTERNS_REQ, filterPatterns),
    takeLatest(CREATE_PATTERN_REQ, createPatternSaga),
    takeLatest(UPDATE_PATTERN_REQ, updatePatternSaga),
    takeLatest(SCHEDULE_APPOINTMENT_REQ, scheduleAppointmentSaga),
  ]);
}
