import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import xIcon from "../../../assets/images/x.png";

const EducationForm = ({
  field,
  index,
  cvData,
  setCvData,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const eduErrors = errors[index];

  const handleChangeInput = (index, event) => {
    const educations = [...cvData.educations];
    educations[index][event.target.name] = event.target.value;

    setCvData((state) => ({ ...state, educations }));
    setErrors((prevState) => {
      const updatedErrors = [...prevState];
      updatedErrors[index] = {
        ...prevState[index],
        [event.target.name]: false,
      };
      return updatedErrors;
    });
  };

  const handleSetIsCourse = () => {
    setCvData((prevState) => {
      const educations = [...prevState.educations];
      educations[index] = {
        ...educations[index],
        isCourse: !educations[index].isCourse,
      };
      return { ...prevState, educations };
    });
  };

  const handleRemoveFields = (index) => {
    const educations = [...cvData.educations];

    if (educations.length === 1) return;

    educations.splice(index, 1);
    setCvData((state) => ({ ...state, educations }));
  };

  const getHelperText = (fieldName) => {
    return `${t(`cv.${fieldName}`)} ${t("cv.helperText")}`;
  };

  return (
    <Box className="cv-exp-form-main">
      <Box className="cv-exp-form-header">
        <Typography sx={{ mb: 1 }}>
          {`${t("cv.education")} ${index + 1}`}
        </Typography>
        <Box sx={{ pr: 1 }}>
          <IconButton onClick={handleRemoveFields}>
            <img alt="x" src={xIcon} />
          </IconButton>
        </Box>
      </Box>
      <Grid
        component="label"
        container
        alignItems="center"
        spacing={1}
        sx={{ pb: 1 }}
      >
        <Grid item>{t("cv.faculty")}</Grid>
        <Grid item>
          <Switch checked={field.isCourse} onClick={handleSetIsCourse} />
        </Grid>
        <Grid item>{t("cv.course")}</Grid>
      </Grid>

      <Box className="cv-exp-form-row">
        <Box className="cv-exp-form-left-col" sx={{ mr: 3 }}>
          <Box className="cv-form-control">
            <label>{t("cv.degreeTitle")}</label>
            <TextField
              name="degreeTitle"
              size="small"
              placeholder="ex. Bachelor of Science in Engineering"
              helperText={
                eduErrors?.degreeTitle && getHelperText("degreeTitle")
              }
              error={eduErrors?.degreeTitle}
              value={field.degreeTitle}
              onChange={(event) => handleChangeInput(index, event)}
            />
          </Box>
          {!field.isCourse && (
            <>
              <Box className="cv-form-control">
                <label>{t("cv.unversity")}</label>
                <TextField
                  name="university"
                  size="small"
                  placeholder="ex. University of Niš"
                  value={field.university}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </Box>
              <Box className="cv-form-control">
                <label>{t("cv.faculty")}</label>
                <TextField
                  name="faculty"
                  size="small"
                  placeholder="ex. Faculty of Electronic Engineering"
                  helperText={eduErrors?.faculty && getHelperText("faculty")}
                  error={eduErrors?.faculty}
                  value={field.faculty}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </Box>
            </>
          )}
        </Box>
        <Box className="cv-exp-form-left-col">
          <Box className="cv-exp-form-row">
            <Box className="cv-form-control" sx={{ mr: 3 }}>
              <label>{t("cv.startDate")}</label>
              <TextField
                name="startDate"
                size="small"
                type="date"
                inputProps={{
                  max: field.endDate,
                }}
                helperText={eduErrors?.startDate && getHelperText("startDate")}
                error={eduErrors?.startDate}
                value={field.startDate}
                onChange={(event) => handleChangeInput(index, event)}
              />
            </Box>
            <Box className="cv-form-control">
              <label>{t("cv.endDate")}</label>
              <TextField
                name="endDate"
                size="small"
                type="date"
                inputProps={{
                  min: field.startDate,
                }}
                value={field.endDate}
                onChange={(event) => handleChangeInput(index, event)}
              />
            </Box>
          </Box>
          {!field.isCourse && (
            <Box className="cv-exp-form-row">
              <Box className="cv-form-control" sx={{ mr: 3 }}>
                <label>{t("cv.companyCity")}</label>
                <TextField
                  name="city"
                  size="small"
                  placeholder="ex. Niš"
                  helperText={eduErrors?.city && getHelperText("companyCity")}
                  error={eduErrors?.city}
                  value={field.city}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </Box>
              <Box className="cv-form-control">
                <label>{t("cv.companyCountry")}</label>
                <TextField
                  name="country"
                  size="small"
                  placeholder="ex. Serbia"
                  helperText={
                    eduErrors?.country && getHelperText("companyCountry")
                  }
                  error={eduErrors?.country}
                  value={field.country}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

EducationForm.propTypes = {
  index: PropTypes.number,
  field: PropTypes.object,
  cvData: PropTypes.object,
  setCvData: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};

export default EducationForm;
