import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import plusIcon from "../../../assets/images/plus.png";
import trashIcon from "../../../assets/images/delete.png";

const TechnologyForm = ({
  index,
  field,
  skillIndex,
  cvData,
  setCvData,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const techsErrors = errors[skillIndex]?.techs[index];

  const handleChangeInput = (index, event) => {
    const skills = [...cvData.skills];
    skills[skillIndex].techs[index][event.target.name] = event.target.value;

    setCvData((state) => ({ ...state, skills }));
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[skillIndex].techs[index] = {
        ...newErrors[skillIndex].techs[index],
        [event.target.name]: false,
      };
      return newErrors;
    });
  };

  const handleAddFields = () => {
    const skills = [...cvData.skills];
    const techs = [
      ...skills[skillIndex].techs,
      {
        techOrTool: "",
        yearsExperience: 0,
      },
    ];

    skills[skillIndex].techs = techs;

    setCvData((state) => ({ ...state, skills }));

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[skillIndex].techs.push({
        techOrTool: false,
      });

      return newErrors;
    });
  };

  const handleRemoveFields = (index) => {
    const skills = [...cvData.skills];
    const techs = [...skills[skillIndex].techs];

    if (techs.length === 1) return;

    techs.splice(index, 1);
    skills[skillIndex].techs = techs;

    setCvData((state) => ({ ...state, skills }));
  };

  return (
    <Box className="cv-skills-form-techortool">
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <Box className="cv-form-control" sx={{ mr: 3 }}>
            <label>{t("cv.techOrTool")}</label>
            <TextField
              name="techOrTool"
              size="small"
              placeholder="ex. ASP.NET MVC"
              helperText={
                techsErrors?.techOrTool &&
                `${t(`cv.techOrTool`)} ${t("cv.helperText")}`
              }
              error={techsErrors?.techOrTool}
              value={field.techOrTool}
              onChange={(event) => handleChangeInput(index, event)}
            />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box className="cv-form-control">
            <label>{t("cv.yearsExperience")}</label>
            <TextField
              name="yearsExperience"
              type="number"
              size="small"
              sx={{ width: "8rem" }}
              value={field.yearsExperience}
              onChange={(event) => handleChangeInput(index, event)}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ mr: 2, ml: 2 }}>
        <IconButton
          className={"c-btn--primary-outlined c-btn td-btn"}
          onClick={() => handleRemoveFields(index)}
        >
          <img src={trashIcon} width={15} height={15} />
        </IconButton>
      </Box>
      <Box>
        <IconButton
          className={"c-btn--primary-outlined c-btn td-btn"}
          onClick={handleAddFields}
        >
          <img src={plusIcon} width={15} height={15} />
        </IconButton>
      </Box>
    </Box>
  );
};

TechnologyForm.propTypes = {
  index: PropTypes.number,
  field: PropTypes.object,
  skillIndex: PropTypes.number,
  cvData: PropTypes.object,
  setCvData: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};

export default TechnologyForm;
