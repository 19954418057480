import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useRef } from "react";
import arrow_left from "../../assets/images/arrow_left.png";
import arrow_right from "../../assets/images/arrow_right.png";
import Slider from "react-slick";
import { JOB_DETAILS_PAGE } from "../../constants/pages";
import StatsAd from "../../components/Ads/StatsAd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getStatsReq } from "../../store/actions/stats/statsActions";
import { useTranslation } from "react-i18next";
import { returni18nLevel } from "../../util/helpers/stringHelpers";
import { FETCH_STATS_LOADING } from "../../store/actions/stats/statsActionConstants";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";

const StatsPage = ({ history }) => {
  const dispatch = useDispatch();
  const { stats } = useSelector((s) => s.stats);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getStatsReq());
  }, [dispatch]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const sliderRef = useRef();

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const arrowLeftHandler = () => {
    sliderRef.current.slickPrev();
  };

  const arrowRightHandler = () => {
    sliderRef.current.slickNext();
  };

  const isLoading = useSelector(
    selectIsLoadingByActionType(FETCH_STATS_LOADING)
  );

  return isLoading ? (
    <div>
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <div className="loader-container h-withHeader">
        <span>Loading</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <h1
        style={{ letterSpacing: "1px" }}
        className="page-heading px36-heading"
      >
        {t("stats.statistic")}
      </h1>
      <div className="stats-section">
        <h2 className="section-header">{t("stats.selectionFlow")}</h2>
        <div className="stats-items">
          {stats.levels &&
            stats.levels.map((n) => (
              <div
                key={n.level}
                className="stats-item"
                data-testid="stats-item"
              >
                <div className="stats-item-content">
                  <h3>{n.countDone}</h3>
                  <p>
                    {returni18nLevel(n.level) != "FD" && t("common.done")}
                    <br></br>
                    {t("selectionLevels.done." + returni18nLevel(n.level))}
                  </p>
                </div>
                <div className="bottom-static"></div>
              </div>
            ))}
        </div>
      </div>
      <div className="stats-section">
        <h2 className="section-header">{t("stats.relations")}</h2>
        <div className="stats-items-dynamic">
          {stats.levels &&
            stats.levels.map((n) => (
              <div
                key={n.level}
                className="stats-item"
                data-testid="stats-item2"
              >
                <div className="stats-item-content">
                  <h3>
                    {n.countDone}
                    <span style={{ padding: "0px 3px" }}>:</span>
                    {n.countAll}
                  </h3>
                  <p>
                    {t("stats.number")}{" "}
                    {t("selectionLevels.done." + returni18nLevel(n.level))}:
                    <br></br>
                    {t("stats.contacted")}
                  </p>
                </div>
                <div className="bottom-dynamic">
                  <div
                    style={{
                      width: `${((n.countDone * 1.0) / n.countAll) * 100}%`,
                    }}
                    className="bottom-loader-indicator"
                  ></div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="stats-section">
        <h2 className="section-header">{t("stats.registered")}</h2>
      </div>
      <div className="jobs stat-jobs">
        {stats.ads && stats.ads.length > 0 && (
          <div className="archived-jobs">
            {/* <div className="archived-jobs-header">
              <h2>{t("ads.archiveAds")}</h2>
            </div> */}
            <div className="archived-jobs-jobs">
              {!matches && (
                <div className="archived-jobs-jobs-a">
                  <div className="archived-jobs-jobs-arrows">
                    <button onClick={arrowLeftHandler} data-testid="left-arrow">
                      <img src={arrow_left} alt="arrow-left" />
                    </button>
                    {stats.ads.length > 3 && (
                      <button
                        onClick={arrowRightHandler}
                        data-testid="right-arrow"
                      >
                        <img src={arrow_right} alt="arrow-right" />
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div className="archived-jobs-jobs-job">
                <Slider
                  ref={sliderRef}
                  {...settings}
                  slidesToShow={5}
                  slidesToScroll={5}
                  style={{ width: "100%" }}
                >
                  {stats.ads.map((ad, index) => (
                    <StatsAd
                      count={ad.count}
                      key={index}
                      title={ad.title}
                      minimumExperience={ad.minimumExperience}
                      createdAt={ad.createdAt}
                      expiredAt={ad.expiredAt}
                      onShowAdDetails={() =>
                        history.push(JOB_DETAILS_PAGE.replace(":id", ad.id))
                      }
                    />
                  ))}
                  {/* {stats.ads.length <= 5 &&
                    getDummyArchiveAds(archiveAds.length)} */}
                </Slider>
              </div>
            </div>
            {matches && (
              <div className="active-jobs-jobs-arrows">
                <button onClick={arrowLeftHandler} data-testid="left-arrow">
                  <img src={arrow_left} alt="arrow-left" />
                </button>
                {stats.ads.length > 2 && (
                  <button onClick={arrowRightHandler} data-testid="right-arrow">
                    <img src={arrow_right} alt="arrow-right" />
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
};

export default StatsPage;

StatsPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
