import {
  FETCH_PATTERN_REQ,
  FETCH_PATTERN_ERR,
  FETCH_PATTERN_SUCCESS,
} from "./patternActionConstants";

export const setPatternReq = (payload) => ({
  type: FETCH_PATTERN_REQ,
  payload,
});

export const setPatternError = (payload) => ({
  type: FETCH_PATTERN_ERR,
  payload,
});

export const setPattern = (payload) => ({
  type: FETCH_PATTERN_SUCCESS,
  payload,
});
