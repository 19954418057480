import {
    FETCH_APPLICANT_PROCESSES_REQ,
    FETCH_APPLICANT_PROCESSES_ERR,
    FETCH_APPLICANT_PROCESSES_SUCCESS,
  } from "./processesActionConstants";
  
  export const setApplicantReq = (payload) => ({
    type: FETCH_APPLICANT_PROCESSES_REQ,
    payload
  });
  
  export const setApplicantError = (payload) => ({
    type: FETCH_APPLICANT_PROCESSES_ERR,
    payload,
  });
  
  export const setApplicant = (payload) => ({
    type: FETCH_APPLICANT_PROCESSES_SUCCESS,
    payload,
  });