import { createSelector } from "@reduxjs/toolkit";

export const candidateSelector = (state) => state.candidate;

export const selectCandidate = createSelector(
  candidateSelector,
  (state) => state.candidate,
);

export const selectCandidateError = createSelector(
  candidateSelector,
  (state) => state.errorMessage
);