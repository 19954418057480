import { createSelector } from "@reduxjs/toolkit";

export const patternApplicantsSelector = (state) => state.patternApplicants;

export const selectPatternApplicants = createSelector(
  patternApplicantsSelector,
  (state) => state.patternApplicants
);

export const selectPatternApplicantsError = createSelector(
  patternApplicantsSelector,
  (state) => state.errorMessage
);
