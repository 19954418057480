import {
  FETCH_PROCESSES_ERR,
  FETCH_PROCESSES_SUCCESS
} from "../../actions/processes/processesActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  processes: [],
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_PROCESSES_SUCCESS]: setStateProcesses,
    [FETCH_PROCESSES_ERR]: setProcessesErrorMessage,
  },
  initialState
);

function setStateProcesses(state, action) {
  return {
    ...state,
    processes: action.payload,
  };
}

function setProcessesErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}