import createReducer from "../../utils/createReducer";
import {
  ADS_CANDIDATES_ERROR,
  ADS_CANDIDATES_SUCCESS,
  FILTER_CANDIDATES_SUCCESS,
  FILTER_CANDIDATES_ERROR
} from "../../actions/candidates/candidatesActionConstants";

const initialState = {
  candidates: [],
  adsCandidates: [],
  errorMessage: "",
  pagination: 0
};

export default createReducer(
  {
    [FILTER_CANDIDATES_SUCCESS]: filterCandidates,
    [FILTER_CANDIDATES_ERROR]: setFilterCandidatesError,
    [ADS_CANDIDATES_SUCCESS]: setAdsCandidates,
    [ADS_CANDIDATES_ERROR]: setAdsCandidatesError,
  },
  initialState
);

function filterCandidates(state, action) {
  return {
    ...state,
    candidates: action.payload.items,
    pagination: action.payload.total,
  };
}

function setFilterCandidatesError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setAdsCandidates(state, action) {
  return {
    ...state,
    adsCandidates: action.payload,
  };
}

function setAdsCandidatesError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}