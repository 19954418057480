import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import x from "../../assets/images/x.png";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  //   TextField,
  //   TextField,
} from "@mui/material";
import IconButton from "../IconButton/IconButton";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDispatch, useSelector } from "react-redux";
// import { format, isValid } from "date-fns";
// import { fetchInitProcess } from "../../store/actions/candidates/candidatesActions";
import { useEffect } from "react";
import { SelectionContext } from "../../context/SelectionContext";
import { setUpdateInterviewerReq } from "../../store/actions/processes/processAction";
import { useTranslation } from "react-i18next";
// import { setUpdateStatusReq } from "../../store/actions/processes/processAction";

const InterviewerDialog = ({
  title,
  subtitle,
  imgSrc,
  onClose,
  open,
  maxWidth,
  fullWidth,
  responsive,
}) => {
  const { activeInterview, setActiveInterview } = useContext(SelectionContext);
  const [selected, setSelected] = useState("");
  const {t} = useTranslation()

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { users } = useSelector((s) => s.users);
  const { isSuccess } = useSelector((s) => s.interviewerUpdate);

  const dispatch = useDispatch();

  useEffect(() => {
    handleClose();
  }, [dispatch, isSuccess]);

  useEffect(() => {
    setSelected("");
  }, [onClose]);

  const handleClose = () => {
    onClose();
  };

  const submitHandler = () => {
    dispatch(
      setUpdateInterviewerReq({
        data: {
          schedulerId: selected,
          processId: activeInterview.id,
        },
        responseHandler: apiSuccess,
      })
    );
  };

  const apiSuccess = () => {
    setActiveInterview(null);
    // console.log("ok");
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      keepMounted={false}
      fullWidth={fullWidth}
      fullScreen={responsive && fullScreen}
      onClose={handleClose}
      open={open}
      style={{
        padding: "36px",
      }}
    >
      {/* {activeInterview?.id} */}
      <div style={{ padding: "36px" }}>
        <DialogTitle style={{ padding: 0 }}>
          {fullScreen ? (
            <>
              <div className="flex-center" style={{ justifyContent: "start" }}>
                <img
                  style={{
                    position: "relative",
                    top: -0.25,
                    paddingRight: "10px",
                  }}
                  src={imgSrc}
                />
                <h5 style={{ textAlign: "start" }}>{title}</h5>
                <div style={{ justifySelf: "stretch", flex: "1" }}></div>
                <IconButton onClick={onClose}>
                  <img
                    style={{
                      position: "relative",
                      top: -0.25,
                    }}
                    src={x}
                  />
                </IconButton>
              </div>
              <p
                className="dialog-subtitle"
                style={{ paddingLeft: "23px", marginTop: "-10px" }}
              >
                | {subtitle}
              </p>
            </>
          ) : (
            <div
              className="flex-center"
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex-center" style={{ justifyContent: "start" }}>
                <img
                  style={{
                    position: "relative",
                    top: -0.25,
                    paddingRight: "10px",
                  }}
                  src={imgSrc}
                />
                <h5>{title}</h5>
                <div className="vr"></div>
                <p className="dialog-subtitle">{subtitle}</p>
              </div>
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <form className="modal-content interviewDialog">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("dialogs.interviewerName2")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  selected
                    ? selected
                    : activeInterview
                    ? activeInterview.scheduler.id
                    : ""
                }
                label="Ime intervjuera"
                onChange={(e) => {
                  setSelected(e.target.value);
                }}
              >
                {users
                  ? users.map(({ id, firstName, lastName }, index) => (
                      <MenuItem
                        key={index}
                        sx={{ textAlign: "left" }}
                        value={id}
                      >
                        {firstName} {lastName}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
            {/* {activeProcess.process && activeProcess.process.date ? <p>Proces ima zakazan termin</p> : ''} */}
          </form>
        </DialogContent>
        <DialogActions style={{ padding: 0, justifyContent: "space-between" }}>
          {!fullScreen ? (
            <IconButton
              data-testid="editbtn"
              className={`c-btn--primary-outlined interview-btn c-btn dialog-btn`}
              onClick={onClose}
            >
              {t("common.cancel")}
            </IconButton>
          ) : (
            ""
          )}
          <IconButton
            data-testid="editbtn"
            className={`c-btn--primary-outlined sm-full interview-btn c-btn dialog-btn`}
            onClick={submitHandler}
          >
            {t("common.confirm")}
          </IconButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

InterviewerDialog.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  imgSrc: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  fullWidth: PropTypes.bool,
  responsive: PropTypes.bool,
};

export default InterviewerDialog;
