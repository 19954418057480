import createReducer from "../../utils/createReducer";
import {
  FETCH_PATTERN_APPLICANTS_SUCCESS,
  FETCH_PATTERN_APPLICANTS_ERR,
} from "../../actions/patternApplicants/patternApplicantsActionConstants";

const initialState = {
  patternApplicants: [],
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_PATTERN_APPLICANTS_SUCCESS]: setStatePatternApplicants,
    [FETCH_PATTERN_APPLICANTS_ERR]: setStateErrorMessage,
  },
  initialState
);

function setStatePatternApplicants(state, action) {
  return { ...state, patternApplicants: action.payload };
}

function setStateErrorMessage(state, action) {
  return { ...state, errorMessage: action.payload };
}
