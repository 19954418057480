import { createSelector } from "@reduxjs/toolkit";

export const adSelector = (state) => state.ad;

export const selectAd = createSelector(adSelector, (state) => state.ad);

export const selectAdError = createSelector(
  adSelector,
  (state) => state.errorMessage
);
