import React from "react";
import PropTypes from "prop-types";
import { selectLogo } from "../../util/helpers/technologiesLogos";
import { useTranslation } from "react-i18next";

const ArchiveJob = ({
  className,
  title,
  minimumExperience,
  createdAt,
  expiredAt,
  onShowAdDetails,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`archive-job ${className}`} onClick={onShowAdDetails}>
      <div className="archive-job-date">
        <p>
          {new Date(createdAt).toLocaleDateString()} -{" "}
          {new Date(expiredAt).toLocaleDateString()}
        </p>
      </div>
      <div className="archive-job-title">
        <h3>{title}</h3>
      </div>
      <div className="archive-job-image">
        <img src={selectLogo(title)} alt=".net icon" />
      </div>
      <div className="archive-job-experience">
        {minimumExperience > 0 ? (
          <p>
            {minimumExperience}+ {t("common.experience")}
          </p>
        ) : (
          <p>{t("ads.noExperience")}</p>
        )}
      </div>
    </div>
  );
};

ArchiveJob.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  minimumExperience: PropTypes.number,
  createdAt: PropTypes.any,
  expiredAt: PropTypes.any,
  onShowAdDetails: PropTypes.func,
  className: PropTypes.any,
};

export default ArchiveJob;
