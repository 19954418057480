import {
  FETCH_ADS_ERR,
  FETCH_ADS_SUCCESS,
  FETCH_FILTERED_ADS_ERR,
  FETCH_FILTERED_ADS_SUCCESS,
} from "../../actions/ads/adsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  ads: [],
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_ADS_SUCCESS]: setStateAds,
    [FETCH_ADS_ERR]: setAdsErrorMessage,
    [FETCH_FILTERED_ADS_SUCCESS]: setStateFilteredAds,
    [FETCH_FILTERED_ADS_ERR]: setFilteredAdsErrorMessage,
  },
  initialState
);

function setStateAds(state, action) {
  return {
    ...state,
    ads: action.payload,
  };
}

function setAdsErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setStateFilteredAds(state, action) {
  return {
    ...state,
    ads: action.payload,
  };
}

function setFilteredAdsErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}