import { createSelector } from "@reduxjs/toolkit";

export const patternSelector = (state) => state.pattern;

export const selectPattern = createSelector(
  patternSelector,
  (state) => state.pattern
);

export const selectPatternError = createSelector(
  patternSelector,
  (state) => state.errorMessage
);
