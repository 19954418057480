export default {
  app: {
    title: "HR Center",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    close: "Close",
    trademark: "TM",
    search: "Search",
    error: "Error",
    continue: "CONTINUE",
    labelUsername: "Username",
    labelPassword: "Password",
    or: "or",
    and: "and",
    next: "Next",
    nextPage: "Next page",
    previousPage: "Previous page",
    back: "BACK",
    goBack: "Go Back",
    ok: "Ok",
    done: "Done",
    confirm: "Confirm",
    printDownload: "Print/Download",
    cancel: "Cancel",
    delete: "Delete",
    change: "Change",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    to: "To",
    from: "From",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} to {{end}}",
    },
    experience: "years of experience",
    noExperience: "No experience",
    firstName: "First name",
    lastName: "Last name",
    gender: "Gender",
    male: "Male",
    female: "Female",
    dateOfBirth: "Date of birth",
    phoneNumber: "Phone number",
    socialNetwork: "The social network",
    address: "address",
    specialist: "Specialist",
    title: "Title",
    description: "Description",
  },
  login: {
    welcome: "Welcome!",
    firstnameRequired: "An first name is required.",
    lastnameRequired: "A last name is required.",
    emailRequired: "An Email is required.",
    dontHaveAccount: "Don't have an account? ",
    emailFormat: "Invalid email address format.",
    noUsers: "There are no users with that email.",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    signUpRecommendation: "Sign up",
    email: "Please enter your email address or username to log in:",
    logInTitle: "Log In",
    logIn: "Log In",
    signUp: "Sign Up",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Forgot your password?",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
    signInWithGoogle: "Continue with google",
    invalidEmail: "Invalid email format",
    specialCharacterRequired:
      "Paswword must contain at least one special character",
    numberRequired: "Paswword must contain at least one number",
    lowercaseRequired: "Paswword must contain at least one lowercase character",
    uppercaseRequired: "Paswword must contain at least one uppercase character",
    numberOfCharactersRequired: "Password must contain at least 8 characters",
    retypePassword: "Please retype your password.",
    matchPasswords: "Your passwords do not match.",
    passwordConfirmation: "Password confirmation",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Forgot Password",
    label: "Send email",
    emailRequired: "An email is required.",
    emailFormat: "Invalid email address format.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
  },
  nav: {
    navigation: "Navigation",
    jobs: "Ads",
    selectionFlow: "Selection flow",
    candidates: "Candidates",
    planer: "Planer",
    patterns: "Patterns",
    statistics: "Statistics",
    users: "Users",
    signOut: "Sign Out",
    schedule: "Schedule",
  },
  ads: {
    activeAds: "Active Ads",
    archiveAds: "Archive",
    adDetailsDescription:
      "Team Diligent is constantly growing! We are looking for a team player that will work with experienced engineers. If technology is your passion and you are ready to move the boundaries of your knowledge every day, then, Diligent is the right place for you. If you are not from Niš, we are offering a full remote position.",
    adDetailsExperience: "years of experience",
    adDetailsExpiredAt: "Expired at",
    adDetailsKeyResponsibilities: "Key Responsibilities",
    adDetailsRequirements: "Requirements",
    adDetailsOffer: "What We Offer",
    archiveAdsCandidates: "Registered candidates",
    adding: "Adding",
    ad: "Ad",
    ads: "Ads",
    signUp: "Sign up",
    dragPdf1: "Drag the .pdf document in this part of the screen or",
    dragPdf2: "on the computer",
    coverLetter: "Cover letter (Optional)",
    professionalQualification: "Professional qualification",
    technologies: "Technologies you know",
    others: "Others",
    noExperience2: "No experience needed",
    registered: "registered",
    backToAds: "Back to all ads",
    thereIsNoAds: "Unfortunately, there are currently no active ads",
    addAd: "You can always add a new one in just a few simple steps",
    adNewAd: "Add an ad",
    expirationDate: "Ad Expiration Date",
    duties: "Main duties",
    conditions: "Conditions",
    offer: "What we offer",
    archivingAd: "Archiving ad",
    archivingAdQuestion: "Are you sure you want to archive the ad?",
  },
  users: {
    management: "User management",
    fullName: "Name and surname",
    position: "Position",
    invite: "Invite",
    inviteUser: "Invite user",
    regLink: "Registration link",
    receiver: "Receiver",
    user: "User",
    contact: "Contact",
    phone: "Phone",
    socials: "Social Media",
    resetPassword: "Reset password",
    resetLink: "Are you sure you want to send password reset link?",
    disableUser: "Disable user",
    questionDisableUser: "Are you sure you want to disable user?",
    deleteUser: "Delete user",
    questionDeleteUser: "Are you sure you want to delete user?",
    block: "Block",
    unblock: "Unblock",
    profile: "Edit profile",
    positionNotDeclared: "Position has not been declared yet.",
    backToUsers: "Back to list of users",
    noSocialMedia: "User takes not part in any social media.",
    noPhoneNumber: "User has no phone number saved.",
  },
  selectionLevels: {
    done: {
      HR: "HR interview",
      ST: "Screening test",
      TI: "Technical interview",
      FD: "Candidates accepted",
    },
  },
  registration: {
    phone: "Phone",
    linkedinProfile: "LinkedIn profile",
    position: "Position",
    link: "Registration link",
    register: "Register",
    twoSteps: "Two steps to the HR Center.",
    mistake: "There was a mistake...",
  },
  filters: {
    filters: "Filters",
    experience: "Years of experience",
    technologies: "Technologies",
    employmentType: "Type of employment",
    internship: "Internship",
    work: "Work",
    workHour: "Work hour",
    partTime: "Part-time",
    fullTime: "Full-time",
    search: "Search",
    dateOfApplication: "Date of application",
    category: "Category",
    creationDate: "Creation date",
    advancedTechnologies: "Advanced technologies",
  },
  candidates: {
    candidates: "Candidates",
    candidate: "Candidate",
    experience: "Experience",
    informations: "Informations",
    contact: "Contact",
    comment: "Comment",
    allApplications: "All applications",
    backToCandidates: "Back to all candidates",
    tableView: "Table view",
    search: "Search",
    position: "Position",
    deleteCandidate: "Deleting candidates",
    deleteCandidateQuestion: "Are you sure you want to delete the candidate?",
    sendCVInviteTitle: "CV creation invitiation",
    sendCvInviteQuestion:
      "Are you sure you want to send the candidate the link to create a CV?",
  },
  dialogs: {
    candidateName: "Candidate's name",
    interviewerName: "Interviewer's name (optional)",
    interviewerName2: "Interviewer's name",
    duration: "Duration (hours)",
    appointment: "Appointment",
    comment: "Your comment (optional)",
  },
  schedule: {
    items: "items",
    planner: "Activity planner",
  },
  selection: {
    report: "Detailed report",
    link: "Link to Google Meet",
    noCandidates: "There are no candidates in the selection",
    interviewer: "Interviewer",
    noInterviewer: "The process has no interviewer.",
    title: "Selection flow",
    subtitle: "All candidates",
    addInterviewer: "Adding an interviewer",
    selection: "Selection",
    changeInterviewer: "Change of interviewer",
    addCandidate: "Adding candidate",
    interview: "HR interview",
    filterDate: "Date",
    finalDecision: "Please clarify the decision",
    tipHeader: "Advice for a successful screening test",
    tipBody:
      "Remember that the human resources department contains the word \"human\" for a reason. HR should see you for who you are and help you connect with the company. Therefore, it's better to behave naturally and relaxed. It's also important to demonstrate strong interpersonal skills and behave professionally. On the day of the interview, be punctual, introduce yourself politely, and dress appropriately. This means considering the company's image but also dressing in a way that makes you comfortable throughout the day.",
  },
  patterns: {
    made: "Made",
    pattern: "Pattern",
    scheduling: "Scheduling an appointment",
    messageText: "Message text",
    message: "MESSAGE",
    editing: "Editing",
    patternsMade: "Patterns made",
    noPatterns: "There are currently no patterns added",
    addPattern: "Pattern",
    editing2: "Editing mode",
    titleFieldPlaceholder: "ex. Date of HR interview",
    addingPatternButton: "ADD PATTERN",
  },
  stats: {
    statistic: "Statistic",
    selectionFlow: "Selection flow",
    relations: "Relations",
    number: "Number",
    contacted: "Number of contacted:",
    registered: "Registered by positions",
  },
  processStatus: {
    scheduled: "Scheduled",
    finished: "Done",
    waitingAppointment: "Waiting for an appointment",
    unsuccesfully: "Unsuccessfully",
    waitingAnswer: "Waiting for an answer",
  },
  processes: {
    // Header naslovi procesa
    hr: "HR interview",
    st: "Screening test",
    ti: "Technical interview",
    fd: "Final decision",
  },
  cv: {
    profile: "Profile",
    experience: "Experience",
    skills: "Skills",
    education: "Education",
    otherSkillsAndAccomplishments: "Other skills and accomplishments",
    create: "Create CV",
    generate: "Generate CV",
    submit: "Submit",
    finish: "Finish",
    createDesc: "Diligent Template CV",
    createSubDesc: "Please fill in the fields in English.",
    positionName: "Position name",
    companyName: "Company name",
    companyCity: "City",
    companyCountry: "Country",
    startDate: "Start date",
    endDate: "End date",
    projectName: "Project name",
    projectDesc: "Description",
    experienceSm: "Experience",
    projects: "Notable projects",
    skill: "Skill",
    techStack: "Technology stack",
    techsAndToolsTitle: "Technologies and tools",
    techOrTool: "Name of technology or tool",
    yearsExperience: "Years of experience",
    degreeTitle: "Degree title",
    unversity: "University",
    faculty: "Faculty",
    hobby: "Hobby",
    driversLicence: "Driver's licence",
    maritalStatus: "Marital status",
    married: "Married",
    notMarried: "Not married",
    interests: "Interests",
    successfullSubmit: "Your CV has been stored successfully in our database!",
    successfullSubmitAdmin: "CV has been stored successfully in our database!",
    backToCandidate: "Back to candidate page",
    helperText: "field is required.",
    course: "Course",
    sendCvInvite: "Send CV Invite",
    downloadDiligCv: "Download Dilig CV",
    inviteSentMessage: "Invite successfully sent.",
    job: "Job",
  },
};
