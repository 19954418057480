import createReducer from "../../utils/createReducer";
import {
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_ERR,
  CHANGE_STATUS_ISCHECKED_VALUE,
} from "../../actions/processes/processesActionConstants";

const initialState = {
  statuses: [],
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_STATUSES_SUCCESS]: setStateStatuses,
    [FETCH_STATUSES_ERR]: setStateErrorMessage,
    [CHANGE_STATUS_ISCHECKED_VALUE]: setIsCheckedStatus,
  },
  initialState
);

function setStateStatuses(state, action) {
  return { ...state, statuses: action.payload };
}

function setStateErrorMessage(state, action) {
  return { ...state, errorMessage: action.payload };
}

function setIsCheckedStatus(state, action) {
  const tmpIndex = state.statuses.findIndex(
    (x) => x.name === action.payload
  );

  if (tmpIndex === -1) {
    return state;
  }

  return {
    ...state,
    statuses: state.statuses.map((status, index) =>
      tmpIndex === index ? { ...status, isChecked: !status.isChecked } : status
    ),
  };
}
