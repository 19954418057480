import { createSelector } from "@reduxjs/toolkit";

export const patternsSelector = (state) => state.patterns;

export const selectPatterns = createSelector(
  patternsSelector,
  (state) => state.patterns
);

export const selectPatternsError = createSelector(
  patternsSelector,
  (state) => state.errorMessage
);
