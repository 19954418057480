/* istanbul ignore file */
export const BASE_PAGE = "/";
export const FORGOT_PASSWORD_PAGE = "/forgot-password";
export const HOME_PAGE = "/home";
export const ADS_PAGE = "/jobs";
export const JOB_DETAILS_PAGE = "/jobs/:id";
export const ERROR_PAGE = "/error-page";
export const NOT_FOUND_PAGE = "/not-found";
export const USERS_PAGE = "/users";
export const USER_DETAILS_PAGE = "/users/:id";
export const CANDIDATES_PAGE = "/candidates";
export const CANDIDATES_DETAILS_PAGE = "/candidates/:id";
export const FORGOT_PASSWORD_CONFIRMATION_PAGE =
  "/forgot-password-confirmation";
export const RESET_PASSWORD_PAGE = "/reset-password";
export const SELECTION_PROCESS_PAGE = "/selectionFlow";
export const SELECTION_PROCESS_OF_APPLICANT_PAGE = "/selectionFlow/:id";
export const PATTERNS_PAGE = "/patterns";
export const PATTERN_DETAILS_PAGE = "/patterns/:id";
export const SCHEDULE_PAGE = "/schedule";
export const STATS_PAGE = "/statistics";
export const REGISTER_PAGE = "/register";
export const CREATE_AD_PAGE = "/create-job";
export const CREATE_CV_PAGE = "/create-cv/:token";
