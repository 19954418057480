import {
  createFetchType,
  createSuccessType,
  createErrorType,
} from "../actionHelpers";

const APPLY_FOR_AD_SCOPE = "APPLY_FOR_AD";

export const APPLY_FOR_AD_REQ = createFetchType(APPLY_FOR_AD_SCOPE);
export const APPLY_FOR_AD_ERR = createErrorType(APPLY_FOR_AD_SCOPE);
export const APPLY_FOR_AD_SUCCESS = createSuccessType(APPLY_FOR_AD_SCOPE);
