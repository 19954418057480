import React, { useEffect, useRef } from "react";
import arrow_left from "../../assets/images/arrow_left.png";
import arrow_right from "../../assets/images/arrow_right.png";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import ApplicantSelection from "../../components/Selection/ApplicantSelection";
import { setProcessesReq } from "../../store/actions/processes/processesAction";
import { selectProcesses } from "../../store/selectors/processesSelectors";
import { selectApplicant } from "../../store/selectors/applicantWithProcessSelector";
import { setApplicantReq } from "../../store/actions/processes/applicantAction";
import parse from "html-react-parser";

const SelectionProcessOfApplicantPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const applicant = useSelector(selectApplicant); //
  const processes = applicant?.selectionProcesses;
  const levels = useSelector(selectProcesses);
  const { id } = useParams();
  const activeAdsSliderRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setApplicantReq(id));
    dispatch(setProcessesReq());
  }, []);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const activeAdsArrowLeftHandler = () => {
    activeAdsSliderRef.current.slickPrev();
  };

  const activeAdsArrowRightHandler = () => {
    activeAdsSliderRef.current.slickNext();
  };

  const concatLevels = () => {
    const applicantSelections = [];

    for (var i = processes.length; i < levels.length; i++) {
      applicantSelections.push(
        <ApplicantSelection
          levelNumber={levels[i].id}
          levelName={levels[i].name}
          schedguler={""}
          date={""}
          status={"Čeka na zakazivanje"}
          id={id}
          key={i}
        />
      );
    }
    applicantSelections.push(
      <ApplicantSelection key={i} className="hiddenAd" date={""} />
    );
    return applicantSelections;
  };

  console.log(processes);
  console.log(applicant?.selectionProcesses);

  return (
    <>
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      {/* <AdFilters /> */}
      <div data-testid="appl-sel" className="jobs">
        {processes && processes.length > 0 && (
          <div className="active-jobs">
            <div className="active-jobs-header">
              <h1>
                {t("selection.title")}
                <span className="level-header-spliter">|</span>
                <span className="level-header-subheader">
                  {applicant.firstName + " " + applicant.lastName}
                </span>
              </h1>
            </div>

            <div className="active-jobs-jobs">
              <div className="active-jobs-jobs-a">
                {!matches && (
                  <div className="active-jobs-jobs-arrows">
                    <button onClick={activeAdsArrowLeftHandler}>
                      <img src={arrow_left} alt="arrow-left" />
                    </button>
                    <button onClick={activeAdsArrowRightHandler}>
                      <img src={arrow_right} alt="arrow-right" />
                    </button>
                  </div>
                )}
              </div>
              <div className="active-jobs-jobs-job">
                <Slider
                  {...settings}
                  slidesToShow={4}
                  slidesToScroll={4}
                  style={{ width: "100%" }}
                  ref={activeAdsSliderRef}
                >
                  {processes.map((process, index) => {
                    return (
                      <ApplicantSelection
                        levelNumber={index + 1}
                        levelName={process.selectionLevel.name}
                        schedguler={
                          process?.scheduler
                            ? `${process?.scheduler?.firstName} ${process?.scheduler?.lastName}`
                            : t("selection.noInterviewer")
                        }
                        link={process.link}
                        date={new Date(process.date)}
                        status={process.status}
                        id={id}
                        key={index}
                        className={
                          index === processes.length - 1 ? "active-process" : ""
                        }
                        comment={process.comment}
                      />
                    );
                  })}
                  {processes.length <= levels.length && concatLevels()}
                </Slider>
              </div>
            </div>
          </div>
        )}
        {matches && (
          <div className="active-jobs-jobs-arrows">
            <button onClick={activeAdsArrowLeftHandler}>
              <img src={arrow_left} alt="arrow-left" />
            </button>
            <button onClick={activeAdsArrowRightHandler}>
              <img src={arrow_right} alt="arrow-right" />
            </button>
          </div>
        )}
        <div className="active-process-tip">
          <h3>
            {applicant?.selectionProcesses?.some(
              (n) => n.status === "Neuspešno"
            )
              ? t("candidates.comment") + ":"
              : t("selection.tipHeader")}
          </h3>
          <p>
            {/* {processes?.some(n => n.status === "Neuspešno") && parse(processes?.comment)} */}
            {applicant?.selectionProcesses?.some(
              (n) => n.status === "Neuspešno"
            )
              ? parse(
                  applicant?.selectionProcesses.find(
                    (n) => n.status === "Neuspešno"
                  ).comment
                )
              : t("selection.tipBody")}
            {/* {t("selection.tipBody")} */}
          </p>
        </div>
      </div>
      <div className="add-job">
        <Link className="ad-details-buttons-link" to="/selectionFlow">
          {t("candidates.backToCandidates")}
        </Link>
      </div>
    </>
  );
};

export default SelectionProcessOfApplicantPage;
