import {
  APPLY_FOR_AD_ERR,
  APPLY_FOR_AD_REQ,
  APPLY_FOR_AD_SUCCESS,
} from "./applyForAdActionConstants";

export const applyForAdReq = (payload) => ({
  type: APPLY_FOR_AD_REQ,
  payload,
});

export const applyForAdError = (payload) => ({
  type: APPLY_FOR_AD_ERR,
  payload,
});

export const applyForAd = (payload) => ({
  type: APPLY_FOR_AD_SUCCESS,
  payload,
});
