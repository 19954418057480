import {
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
} from "../actionHelpers";

const ADS_CANDIDATES_SCOPE = "ADS_CANDIDATES";
const FILTER_CANDIDATES_SCOPE = "FILTER_CANDIDATES";
const CANDIDATE_OPTIONS_SCOPE = "CANDIDATE_OPTIONS";
const CANDIDATE_INIT_PROCESS_SCOPE = "CANDIDATE_INIT_PROCESS";

export const FILTER_CANDIDATES = createFetchType(FILTER_CANDIDATES_SCOPE);
export const FILTER_CANDIDATES_ERROR = createErrorType(FILTER_CANDIDATES_SCOPE);
export const FILTER_CANDIDATES_SUCCESS = createSuccessType(FILTER_CANDIDATES_SCOPE);
export const FILTER_CANDIDATES_LOADING = createLoadingType(FILTER_CANDIDATES_SCOPE);

export const ADS_CANDIDATES_FETCH = createFetchType(ADS_CANDIDATES_SCOPE);
export const ADS_CANDIDATES_ERROR = createErrorType(ADS_CANDIDATES_SCOPE);
export const ADS_CANDIDATES_SUCCESS = createSuccessType(ADS_CANDIDATES_SCOPE);
export const ADS_CANDIDATES_LOADING = createLoadingType(ADS_CANDIDATES_SCOPE);

export const CANDIDATE_OPTIONS_FETCH = createFetchType(CANDIDATE_OPTIONS_SCOPE);
export const CANDIDATE_OPTIONS_ERROR = createErrorType(CANDIDATE_OPTIONS_SCOPE);
export const CANDIDATE_OPTIONS_SUCCESS = createSuccessType(CANDIDATE_OPTIONS_SCOPE);
export const CANDIDATE_OPTIONS_LOADING = createLoadingType(CANDIDATE_OPTIONS_SCOPE);

export const CANDIDATE_INIT_PROCESS_FETCH = createFetchType(CANDIDATE_INIT_PROCESS_SCOPE);
export const CANDIDATE_INIT_PROCESS_ERROR = createErrorType(CANDIDATE_INIT_PROCESS_SCOPE);
export const CANDIDATE_INIT_PROCESS_SUCCESS = createSuccessType(CANDIDATE_INIT_PROCESS_SCOPE);
export const CANDIDATE_INIT_PROCESS_LOADING = createLoadingType(CANDIDATE_INIT_PROCESS_SCOPE);