import IconButton from "../../components/IconButton/IconButton";
import React, { useEffect, useState } from "react";
import tableImage from "../../assets/images/table.png";
import filterImage from "../../assets/images/filters.png";
import searchImage from "../../assets/images/search.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import PropTypes from "prop-types";
import CandidateFilters from "../../components/Candidates/CandidateFilters";
import { PAGE_SIZE_CANDIDATES } from "../../constants/keyCodeConstants";
import AdsCandidatesPage from "./AdsCandidatesPage";
import TableViewPage from "./TableViewPage";
import { setTechnologiesReq } from "../../store/actions/technologies/technologiesActions";
import { selectTechnologies } from "../../store/selectors/technologiesSelectors";
import { useDispatch, useSelector } from "react-redux";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";

const CandidatesPage = ({ history }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isTableView, setIsTableView] = useState(true);
  const [toggleFiltersDrawer, setToggleFiltersDrawer] = useState(false);
  const [page, setPage] = React.useState(1);
  const technologies = useSelector(selectTechnologies);
  const [search, setSearch] = useState("");
  const [isSearchFieldVisible, setIsSearchFieldVisible] = useState(false);

  const [sliderValue, setSliderValue] = useState([0, 0]);
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [typesOfEmployments, setTypesOfEmployments] = useState([
    { name: "Posao", isChecked: false },
    { name: "Intership", isChecked: false },
  ]);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setTechnologiesReq());
  }, []);

  const changeView = () => {
    setSearch("");
    setIsTableView(!isTableView);
  };

  const handleToggleFiltersDrawer = () => {
    setToggleFiltersDrawer((oldState) => !oldState);
  };

  const handleChangeVisibility = () => {
    setIsSearchFieldVisible(!isSearchFieldVisible);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const input = (
    <div>
      <input
        placeholder={`${t("filters.search")}`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="candidate-search-field"
        onClick={stopPropagation}
        role="input"
      />
    </div>
  );

  return (
    <div
      className="main-candidates-container"
      onClick={() => setIsSearchFieldVisible(false)}
      data-testid="candidates-page"
    >
      <CandidateFilters
        open={toggleFiltersDrawer}
        handleClose={handleToggleFiltersDrawer}
        pageSize={PAGE_SIZE_CANDIDATES}
        currentPage={page}
        isTableView={isTableView}
        technologies={technologies}
        sliderValue={sliderValue}
        startingDate={startingDate}
        endingDate={endingDate}
        typesOfEmployments={typesOfEmployments}
        setSliderValue={setSliderValue}
        setStartingDate={setStartingDate}
        setEndingDate={setEndingDate}
        setTypesOfEmployments={setTypesOfEmployments}
      />
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <div className="top-candidates-container">
        {!matches ? (
          <p className="candidates-header" data-testid="candidates-header1">
            {t("candidates.candidates")}
          </p>
        ) : (
          <p
            className="candidates-header"
            data-testid="candidates-header2"
            style={{ fontSize: "22px" }}
          >
            {t("candidates.candidates")}
          </p>
        )}
        <div style={{ postion: "relative" }}>
          <Fade in={isSearchFieldVisible} timeout={500} className="proba">
            {input}
          </Fade>
          <Fade in={isSearchFieldVisible} timeout={500}>
            <div
              style={{
                position: "absolute",
                zIndex: 10000,
                right: 95,
                marginTop: 15,
              }}
            >
              <img src={searchImage} />
            </div>
          </Fade>
          <div className="candidates-options-container">
            {!matches ? (
              isTableView ? (
                <IconButton
                  className="c-btn c-btn--primary-outlined candidate-btn all-white-btn"
                  onClick={changeView}
                >
                  {t("candidates.tableView")}
                  <img
                    src={tableImage}
                    alt="table"
                    className="candidates-image"
                  />
                </IconButton>
              ) : (
                <IconButton
                  className="c-btn c-btn--primary-outlined candidate-btn candidate-btn-view-2"
                  onClick={changeView}
                >
                  {t("candidates.tableView")}
                  <img
                    src={tableImage}
                    alt="table"
                    className="candidates-image"
                  />
                </IconButton>
              )
            ) : (
              <IconButton
                className="c-btn--primary-outlined c-btn candidate-btn-mobile"
                onClick={changeView}
              >
                <img
                  className="candidate-image-mobile"
                  src={tableImage}
                  alt="table"
                />
              </IconButton>
            )}
            {!matches && (
              <IconButton
                className="c-btn c-btn--primary-outlined candidate-btn"
                onClick={handleChangeVisibility}
              >
                {t("candidates.search")}
                <img
                  src={searchImage}
                  alt="filter"
                  className="candidates-image"
                />
              </IconButton>
            )}
            {!matches ? (
              <IconButton
                className="c-btn c-btn--primary-outlined candidate-btn candidate-btn-filters1"
                onClick={handleToggleFiltersDrawer}
              >
                {t("filters.filters")}
                <img
                  src={filterImage}
                  alt="filter"
                  className="candidates-image"
                />
              </IconButton>
            ) : (
              <IconButton
                className="c-btn c-btn--primary-outlined candidate-btn-mobile candidate-btn-filters2"
                onClick={handleToggleFiltersDrawer}
              >
                <img
                  src={filterImage}
                  alt="filter"
                  className="candidate-image-mobile"
                />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {isTableView ? (
          <TableViewPage
            history={history}
            page={page}
            setPage={setPage}
            search={search}
            sliderValue={sliderValue}
            startingDate={startingDate}
            endingDate={endingDate}
            typesOfEmployments={typesOfEmployments}
            technologies={technologies}
          />
        ) : (
          <AdsCandidatesPage history={history} search={search} />
        )}
      </div>
    </div>
  );
};

CandidatesPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default CandidatesPage;
