import {
    createFetchType,
    createSuccessType,
    createErrorType,
  } from "../actionHelpers";
  
  const SCHEDULE_APPOINTMENT_SCOPE = "SCHEDULE_APPOINTMENT";
  
  export const SCHEDULE_APPOINTMENT_REQ = createFetchType(SCHEDULE_APPOINTMENT_SCOPE);
  export const SCHEDULE_APPOINTMENT_ERR = createErrorType(SCHEDULE_APPOINTMENT_SCOPE);
  export const SCHEDULE_APPOINTMENT_SUCCESS = createSuccessType(SCHEDULE_APPOINTMENT_SCOPE);

  export const CLEAR_NOT_SENT_EMAILS_ARRAY = "CLEAR_NOT_SENT_EMAILS_ARRAY";
  