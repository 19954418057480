import {
  FETCH_PATTERN_APPLICANTS_REQ,
  FETCH_PATTERN_APPLICANTS_ERR,
  FETCH_PATTERN_APPLICANTS_SUCCESS,
} from "./patternApplicantsActionConstants";

export const setPatternApplicantsReq = (payload) => ({
  type: FETCH_PATTERN_APPLICANTS_REQ,
  payload,
});

export const setPatternApplicantsError = (payload) => ({
  type: FETCH_PATTERN_APPLICANTS_ERR,
  payload,
});

export const setPatternApplicants = (payload) => ({
  type: FETCH_PATTERN_APPLICANTS_SUCCESS,
  payload,
});
