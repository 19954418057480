import createReducer from "../../utils/createReducer";
import { FETCH_STATS_ERR, FETCH_STATS_SUCCESS } from "../../actions/stats/statsActionConstants";

const initialState = {
  stats: {},
  fetchStatsErrorMessage: "",
};

export default createReducer(
  {
    [FETCH_STATS_SUCCESS]: setStateStats,
    [FETCH_STATS_ERR]: setStatsErrorMessage,
  },
  initialState
);

function setStateStats(state, action) {
    // console.log('called setState')
  return {
    ...state,
    stats: action.payload,
  };
}

function setStatsErrorMessage(state, action) {
  return {
    ...state,
    fetchStatsErrorMessage: action.payload,
  };
}
