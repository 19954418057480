export function separateByUppercase(string) {
  return string.split(/(?=[A-Z])/).join(' ');
}

export function separateByUnderscore(string) {
  return string.replaceAll('_', ' ');
}

export function joinArrayWithComma(arr) {
  return arr.join(', ');
}

export function returni18nLevel(lvl) {
  switch (lvl) {
    case "HR intervju":
      return "HR";
    case "Screening test":
      return "ST";
    case "Tehnicki intervju":
      return "TI";
    case "Konacna odluka":
      return "FD";
  }
}
