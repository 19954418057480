import {
  FETCH_CV_PREVIEW_REQ,
  FETCH_CV_PREVIEW_ERR,
  FETCH_CV_PREVIEW_SUCCESS,
  SEND_CV_INVITE_ERR,
  SEND_CV_INVITE_SUCCESS,
  SEND_CV_INVITE_REQ,
  RESPOND_TO_CV_INVITE_REQ,
  RESPOND_TO_CV_INVITE_ERR,
  RESPOND_TO_CV_INVITE_SUCCESS,
  CREATE_CV_SUCCESS,
  CREATE_CV_ERR,
  CREATE_CV_REQ,
} from "./cvActionsConstants";

export const setCvPreviewReq = (payload) => ({
  type: FETCH_CV_PREVIEW_REQ,
  payload,
});

export const setCvPreviewError = (payload) => ({
  type: FETCH_CV_PREVIEW_ERR,
  payload,
});

export const setCvPreview = (payload) => ({
  type: FETCH_CV_PREVIEW_SUCCESS,
  payload,
});

export const setSendCvInviteReq = (payload) => ({
  type: SEND_CV_INVITE_REQ,
  payload,
});

export const setSendCvInviteError = (payload) => ({
  type: SEND_CV_INVITE_ERR,
  payload,
});

export const setSendCvInviteSuccess = (payload) => ({
  type: SEND_CV_INVITE_SUCCESS,
  payload,
});

export const setRespondToCvInviteReq = (payload) => ({
  type: RESPOND_TO_CV_INVITE_REQ,
  payload,
});

export const setRespondToCvInviteSuccess = (payload) => ({
  type: RESPOND_TO_CV_INVITE_SUCCESS,
  payload,
});

export const setRespondToCvInviteError = (payload) => ({
  type: RESPOND_TO_CV_INVITE_ERR,
  payload,
});

export const setCreateCvReq = (payload) => ({
  type: CREATE_CV_REQ,
  payload,
});

export const setCreateCvSuccess = (payload) => ({
  type: CREATE_CV_SUCCESS,
  payload,
});

export const setCreateCvError = (payload) => ({
  type: CREATE_CV_ERR,
  payload,
});
