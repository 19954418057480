import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../util/helpers/dateHelpers";
import dotnetImage from "../../assets/images/.net_icon.png";

const CandidateAd = ({ add, className, onclick }) => {
  return add === undefined ? (
    <p></p>
  ) : (
    <div
      onClick={onclick}
      style={{ cursor: "pointer" }}
      className={`applicant-add ${className !== undefined ? className : ""}`}
    >
      <p className="applicant-add-date">{formatDate(add.createdAt)}</p>
      <p className="applicant-add-title">{add.title}</p>
      <img
        src={dotnetImage}
        alt="technology-image"
        className="applicant-add-technology-image"
      />
      <div className="applicant-add-site">dilig.net</div>
    </div>
  );
};

CandidateAd.propTypes = {
  add: PropTypes.object,
  className: PropTypes.any,
  onclick: PropTypes.func,
};

export default CandidateAd;
