export default {
  app: {
    title: "HR Centar",
  },
  refresh: {
    //   title: 'Are you active?',
    //   cta:
    //     "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    //   close: 'Close',
    //   trademark: 'TM',
    search: "Pretraži",
    //   error: 'Error',
    //   continue: 'Continue',
    labelUsername: "Korisničko ime",
    labelPassword: "Šifra",
    labelConfirmPassword: "Ponovljena šifra",
    or: "ili",
    and: "i",
    //   next: 'Next',
    //   nextPage: 'Next page',
    //   previousPage: 'Previous page',
    back: "NAZAD",
    //   goBack: 'Go Back',
    //   ok: 'Ok',
    //   done: 'Done',
    confirm: "Potvrdi",
    //   printDownload: 'Print/Download',
    cancel: "Otkaži",
    delete: "Obriši",
    change: "Promeni",
    //   remove: 'Remove',
    //   invite: 'Invite',
    //   save: 'Save',
    //   complete: 'Complete',
    download: "Preuzmi",
    //   yes: 'Yes',
    //   no: 'No',
    to: "Do",
    from: "Od",
    //   select: 'Select...',
    //   none: 'None',
    //   date: {
    //     range: '{{start}} to {{end}}',
    //   },
    continue: "NASTAVI",
    firstName: "Ime",
    lastName: "Prezime",
    gender: "Pol",
    male: "Muški",
    female: "ženski",
    dateOfBirth: "Datum rođenja",
    phoneNumber: "Broj telefona",
    socialNetwork: "Društvene mreže",
    address: "adresa",
    specialist: "Specijalista",
    title: "Naslov",
    experience: "godina iskustva",
    description: "Opis",
  },
  login: {
    welcome: "Dobrodošli!",
    //   dontHaveAccount: "Don't have an account? ",
    //   emailFormat: 'Invalid email address format.',
    //   emailRequired: 'An email or username is required.',
    //   noUsers: 'There are no users with that email.',
    //   passwordStrength: 'Your password is {{strength}}.',
    //   passwordLength: 'Your password contain between 8 and 50 characters.',
    //   signUpRecommendation: 'Sign up',
    //   email: 'Please enter your email address or username to log in:',
    logInTitle: "Prijavi se",
    logIn: "Prijavi se",
    firstnameRequired: "Potrebno je uneti ime.",
    lastnameRequired: "Potrebno je uneti prezime.",
    emailRequired: "Potrebno je uneti email adresu.",
    //   signUp: 'Sign Up',
    usernameRequired: "Potrebno je uneti korisničko ime.",
    passwordRequired: "Potrebno je uneti lozinku.",
    forgotYourPassword: "Zaboravio/la si lozinku?",
    resetYourPassword: "Nova lozinka",
    resetYourPasswordHelpText: "Unesi novu lozinku.",
    forgotYourPasswordHelpText:
      "Samo unesi e-mail adresu svog HR Center profila.",
    forgotYourPasswordButton: "POŠALJI",
    forgotYourPasswordBackLink: "Nazad na Login",
    forgotYourPasswordConfimation:
      "Proveri email adresu da bi resetovao lozinku.",
    passwordDontMatch: "Lozinke se ne poklapaju.",
    //   _useDifferentEmail: 'Use different email address or username',
    //   get useDifferentEmail() {
    //       return this._useDifferentEmail;
    //   },
    //   set useDifferentEmail(value) {
    //       this._useDifferentEmail = value;
    //   },
    signInWithGoogle: "Prijava putem Google-a",
    invalidEmail: "Format adrese nije validan",
    specialCharacterRequired:
      "Lozinka mora da sadrži najmanje jedan poseban znak",
    numberRequired: "Lozinka mora da sadrži najmanje jedan broj",
    lowercaseRequired: "Lozinka mora da sadrži najmanje jedno malo slovo",
    uppercaseRequired: "Lozinka mora da sadrži najmanje jedno veliko slovo",
    numberOfCharactersRequired: "Lozinka mora da sadrži najmanje 8 karaktera",
    retypePassword: "Molimo ponovo unesite lozinku.",
    matchPasswords: "Lozinke se ne poklapaju",
    passwordConfirmation: "Potvrda lozinke",
  },
  // password: {
  //   weak: 'weak',
  //   average: 'average',
  //   good: 'good',
  //   strong: 'strong',
  // },
  // forgotPassword: {
  //   title: 'Forgot Password',
  //   label: 'Send email',
  //   emailRequired: 'An email is required.',
  //   emailFormat: 'Invalid email address format.',
  //   forgotPassword: {
  //     title: 'Forgot Password',
  //     subtitle:
  //       'Please answer the security question to gain access to your account:',
  //     label: 'Reset Password',
  //   },
  // },
  // notFound: {
  //   text: "We're sorry but we couldn't find the page you were looking for.",
  //   goBack: 'Go back to homepage',
  // },
  // errorPage: {
  //   text:
  //     "We're sorry, an internal server error came up. Please be patient or try again later.",
  //   goBack: 'Go back to homepage',
  //   logout: 'Logout',
  // },
  // apiErrors:{
  //   ClientIpAddressIsNullOrEmpty:"Client Ip address is null or empty",
  //   UsernameDoesNotExist: "Username does not exist"
  // },

  nav: {
    navigation: "Navigacija",
    jobs: "Oglasi",
    selectionFlow: "Tok Selekcije",
    candidates: "Kandidati",
    schedule: "Planer",
    patterns: "Šabloni",
    statistics: "Statistika",
    users: "Korisnici",
    signOut: "Izloguj se",
  },
  ads: {
    activeAds: "Aktivni Oglasi",
    archiveAds: "Arhiva",
    adDetailsDescription:
      "Tim Diligent neprestano raste! Tražimo timskog igrača koji će raditi s iskusnim inženjerima. Ako je tehnologija vaša strast i spremni ste svaki dan pomicati granice svog znanja, onda je Diligent pravo mesto za vas. Ukoliko niste iz Niša, nudimo full-time remote poziciju.",
    adDetailsExperience: "godina iskustva",
    adDetailsExpiredAt: "Rok prijave do",
    adDetailsKeyResponsibilities: "Ključne Odgovornosti",
    adDetailsRequirements: "Zahtevi",
    adDetailsOffer: "Šta Nudimo",
    archiveAdsCandidates: "Prijavljeni kandidati",
    experience: "godina iskustva",
    noExperience: "Nema iskustva",
    adding: "Dodavanje",
    ad: "Oglas",
    ads: "Oglasi",
    signUp: "Prijavi se",
    dragPdf1: "Prevuci .pdf dokument u ovom delu ekrana ili",
    dragPdf2: "na racunaru",
    coverLetter: "Propratno pismo (Opciono)",
    professionalQualification: "Strucna sprema",
    technologies: "Tehnologije koje znas",
    others: "Druge",
    noExperience2: "Nije potrebno iskustvo",
    registered: "prijavljenih",
    backToAds: "Nazad na sve oglase",
    thereIsNoAds: "Nažalost, trenutno nema aktivnih oglasa",
    addAd: "Uvek možete dodati novi u samo par jednostavnih koraka",
    adNewAd: "Dodaj oglas",
    expirationDate: "Datum isteka oglasa",
    duties: "Glavna zaduženja",
    conditions: "Uslovi",
    offer: "Šta nudimo",
    archivingAds: "Arhiviranje oglasa",
    archivingAdQuestion: "Da li ste sigurni da želite da arhivirate oglas?",
  },
  // selection: {
  //   title: "Tok Selekcije",
  //   subtitle: "Svi kandidati",
  //   tipHeader: "Savet za uspešan Screening test",
  //   tipBody:
  //     "Zapamtite da odeljenje za ljudske resurse u sebi sadrži reč „ljudski“. HR treba da vas vidi kakvi ste i da bi stekli osećaj za vašu stvarnu ličnost i postarali se da se dobro uklopite u kompaniju. Zato je bolje da se ponašate prirodno i opušteno. Važno je i pokazati da posedujete snažne međuljudske veštine i da se ponašate profesionalno. Na dan intervjua budite tačni, predstavite se pristojno i obucite se na odgovarajući način. To znači da razmislite o slici kompanije, ali i da se odevate na način koji vam je ugodan tokom dana.",
  //   filterStatus: "Status",
  //   filterDate: "Datum",
  //   filterFrom: "Od",
  //   filterTo: "Do",
  //   filterSubmit: "Pretraži",
  // },
  users: {
    management: "Upravljanje korisnicima",
    fullName: "Ime i prezime",
    position: "Pozicija",
    invite: "Pozovi",
    inviteUser: "Pozovi korisnika",
    regLink: "Registracioni link",
    receiver: "Primalac",
    user: "Korisnik",
    contact: "Kontakt",
    phone: "Telefon",
    socials: "Društvene mreže",
    resetPassword: "Resetuj lozinku",
    resetLink:
      "Da li ste sigurni da želite da pošaljete link za resetovanje lozinke?",
    disableUser: "Onemogući korisnika",
    questionDisableUser:
      "Da li ste sigurni da želite da onemogućite korisnika?",
    deleteUser: "Obrišite korisnika",
    questionDeleteUser: "Da li ste sigurni da želite ukloniti korisnika?",
    block: "Blokiraj",
    unblock: "Odblokiraj",
    profile: "Uredi profil",
    positionNotDeclared: "Pozicija nije još uvek određena.",
    backToUsers: "Nazad na listu korisnika",
    noSocialMedia: "Korisnik nije deo nijedne društvene mreže.",
    noPhoneNumber: "Korisnik nema sacuvan broj telefona.",
  },
  filter: {
    title: "Filteri",
  },
  selectionLevels: {
    done: {
      HR: "HR intervjua",
      ST: "Screening testova",
      TI: "Tehničkih intervjua",
      FD: "Primljenih kandidata",
    },
  },
  registration: {
    phone: "Broj Telefona",
    linkedinProfile: "LinkedIn profil",
    position: "Pozicija",
    link: "Registracioni link",
    register: "Registruj se",
    twoSteps: "Dva koraka do HR Centra.",
    mistake: "Došlo je do greške...",
  },
  filters: {
    filters: "Filteri",
    experience: "Godine iskustva",
    technologies: "Tehnologije",
    employmentType: "Tip zaposlenja",
    internship: "Stručna praksa",
    work: "Posao",
    workHour: "Radno vreme",
    partTime: "Skraćeno vreme",
    fullTime: "Puno vreme",
    search: "Pretraži",
    dateOfApplication: "Datum prijave",
    category: "Kategorija",
    creationDate: "Datum kreiranja",
    advancedTechnologies: "Napredne tehnologije",
  },
  candidates: {
    candidates: "Kandidati",
    candidate: "Kandidat",
    experience: "Iskustvo",
    informations: "Informacije",
    contact: "Kontakt",
    comment: "Komentar",
    allApplications: "Sve prijave",
    backToCandidates: "Nazad na sve kandidate",
    tableView: "Tablični prikaz",
    search: "Pretraga",
    position: "Pozicija",
    deleteCandidate: "Brisanje kandidata",
    deleteCandidateQuestion:
      "Da li ste sigurni da želite da obrišete kandidata?",
    sendCVInviteTitle: "Link za kreiranje CV-a",
    sendCvInviteQuestion:
      "Da li ste sigurni da želite da pošaljete kandidatu link za kreiranje CV-a?",
  },
  dialogs: {
    candidateName: "Ime kandidata",
    interviewerName: "Ime intervjuera (opciono)",
    interviewerName2: "Ime intervjuera",
    duration: "Trajanje (sati)",
    appointment: "Termin",
    comment: "Vaš komentar (opciono)",
  },
  schedule: {
    items: "stavke",
    planner: "Planer aktivnosti",
  },
  selection: {
    report: "Detaljni izveštaj",
    link: "Link do Google Meet-a",
    noCandidates: "Nema kandidata u selekciji",
    interviewer: "Intervjuer",
    noInterviewer: "Proces nema intervjuera.",
    title: "Tok selekcije",
    subtitle: "Svi kandidati",
    addInterviewer: "Dodavanje intervjuera",
    selection: "Selekcija",
    changeInterviewer: "Promena intervjuera",
    addCandidate: "Dodavanje kandidata",
    interview: "HR intervju",
    filterDate: "Datum",
    finalDecision: "Molimo vas pojasnite odluku",
    tipHeader: "Savet za uspešan Screening test",
    tipBody:
      "Zapamtite da odeljenje za ljudske resurse u sebi sadrži reč „ljudski“. HR treba da vas vidi kakvi ste i da bi stekli osećaj za vašu stvarnu ličnost i postarali se da se dobro uklopite u kompaniju. Zato je bolje da se ponašate prirodno i opušteno. Važno je i pokazati da posedujete snažne međuljudske veštine i da se ponašate profesionalno. Na dan intervjua budite tačni, predstavite se pristojno i obucite se na odgovarajući način. To znači da razmislite o slici kompanije, ali i da se odevate na način koji vam je ugodan tokom dana.",
  },
  patterns: {
    made: "Napravljen",
    pattern: "Šablon",
    scheduling: "Zakazivanje termina",
    messageText: "Tekst poruke",
    message: "PORUKU",
    editing: "Uređivanje",
    patternsMade: "Napravljeni Šabloni",
    noPatterns: "Trenutno nema dodatih sablona",
    addPattern: "Šablon",
    editing2: "Režim uređivanja",
    titleFieldPlaceholder: "ex. Datum HR intervjua",
    addingPatternButton: "DODAJ ŠABLON",
  },
  stats: {
    statistic: "Statistika",
    selectionFlow: "Tok selekcije",
    relations: "Odnosi",
    number: "Broj",
    contacted: "Broj kontaktiranih:",
    registered: "Prijavljeni po pozicijama",
  },
  processStatus: {
    scheduled: "Zakazan",
    finished: "Odrađen",
    waitingAppointment: "Čeka na zakazivanje",
    unsuccesfully: "Neuspešno",
    waitingAnswer: "Čeka se odgovor",
  },
  processes: {
    // Header naslovi procesa
    hr: "HR intervju",
    st: "Screening test",
    ti: "Tehnicki intervju",
    fd: "Konacna odluka",
  },
  cv: {
    profile: "Profil",
    experience: "Iskustvo",
    skills: "Veštine",
    education: "Edukacija",
    otherSkillsAndAccomplishments: "Druge veštine i dostignuća",
    create: "Kreirajte CV",
    generate: "Generiši CV",
    submit: "Sačuvaj",
    finish: "Završi",
    createDesc: "CV po Diligent šablonu",
    createSubDesc: "Molimo vas da popunite polja na engleskom jeziku.",
    positionName: "Naziv pozicije",
    companyName: "Ime firme",
    companyCity: "Grad",
    companyCountry: "Država",
    startDate: "Početni datum",
    endDate: "Krajnji datum",
    projectName: "Ime projekta",
    projectDesc: "Opis",
    experienceSm: "Iskustvo",
    projects: "Značajni projekti",
    skill: "Veština",
    techStack: "Tehnološki skup",
    techsAndToolsTitle: "Tehnologije i alati",
    techOrTool: "Ime tehnologije ili alata",
    yearsExperience: "Godine iskustva",
    degreeTitle: "Naziv stručne spreme",
    unversity: "Univerzitet",
    faculty: "Fakultet",
    hobby: "Hobi",
    driversLicence: "Vozačka dozvola",
    maritalStatus: "Bračni status",
    married: "Oženjen / Udata",
    notMarried: "Neoženjen / Neudata",
    interests: "Interesi",
    successfullSubmit: "Vaš CV je uspešno sačuvan u našoj bazi podataka!",
    successfullSubmitAdmin: "CV je uspešno sačuvan u našoj bazi podataka!",
    backToCandidate: "Nazad na stranicu kandidata",
    helperText: "polje je obavezno.",
    course: "Kurs",
    sendCvInvite: "Pošalji CV Invite",
    downloadDiligCv: "Preuzmi Dilig CV",
    inviteSentMessage: "Poziv je uspešno poslat.",
    job: "Posao",
  },
};
