import createReducer from "../../utils/createReducer";
import {
  FETCH_PATTERN_SUCCESS,
  FETCH_PATTERN_ERR,
} from "../../actions/pattern/patternActionConstants";

const initialState = {
  pattern: null,
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_PATTERN_SUCCESS]: setStatePattern,
    [FETCH_PATTERN_ERR]: setStateErrorMessage,
  },
  initialState
);

function setStatePattern(state, action) {
  return { ...state, pattern: action.payload };
}

function setStateErrorMessage(state, action) {
  return { ...state, errorMessage: action.payload };
}
