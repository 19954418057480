import {
  FETCH_CV_PREVIEW_ERR,
  FETCH_CV_PREVIEW_SUCCESS,
} from "../../actions/cv/cvActionsConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  cvPreview: null,
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_CV_PREVIEW_SUCCESS]: setStateCvPreview,
    [FETCH_CV_PREVIEW_ERR]: setStateErrorMessage,
  },
  initialState
);

function setStateCvPreview(state, action) {
  return { ...state, cvPreview: action.payload };
}

function setStateErrorMessage(state, action) {
  return { ...state, errorMessage: action.payload };
}
