import {
  ARCHIVE_ACTIVE_AD_SUCCESS,
  ARCHIVE_ACTIVE_AD_ERR,
} from "../../actions/archiveActiveAd/archiveActiveAdActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  errorMessage: "",
};

export default createReducer(
  {
    [ARCHIVE_ACTIVE_AD_SUCCESS]: setArchiveActiveAd,
    [ARCHIVE_ACTIVE_AD_ERR]: setArchiveActiveAdErrorMessage,
  },
  initialState
);

function setArchiveActiveAd(state) {
  return {
    ...state,
  };
}

function setArchiveActiveAdErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
