import React, { useContext, useState, useRef } from "react";
import PropTypes from "prop-types";
import x from "../../assets/images/x.png";
import { Editor } from "@tinymce/tinymce-react"; // Dodato Novo!
import {
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import IconButton from "../IconButton/IconButton";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDispatch, useSelector } from "react-redux";
import { format, isValid } from "date-fns";
// import { fetchInitProcess } from "../../store/actions/candidates/candidatesActions";
import { useEffect } from "react";
import { SelectionContext } from "../../context/SelectionContext";
import { setUpdateStatusReq } from "../../store/actions/processes/processAction";
import {
  createScreeningTest,
  fetchScreeningTests,
} from "../../store/actions/screeningTests/screeningTestActions";
import { useTranslation } from "react-i18next";
import { TINYMCE_API_KEY } from "../../secrets";

const StatusDialog = ({
  title,
  subtitle,
  imgSrc,
  onClose,
  open,
  maxWidth,
  fullWidth,
  responsive,
}) => {
  const [selected, setSelected] = useState("");
  const [value, setValue] = useState(null);
  const [selectedScreeningTest, setSelectedScreeningTest] = useState("");
  const [duration, setDuration] = useState(48);

  const { activeProcess, setActiveProcess } = useContext(SelectionContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { users } = useSelector((s) => s.users);
  const { isSuccess } = useSelector((s) => s.initProcess);
  const { screeningTests } = useSelector((s) => s.screeningTests);
  const { t } = useTranslation();
  const editorContent = useRef();

  if (activeProcess?.process.selectionLevelId === 4) {
    title = t("selection.finalDecision");
    subtitle = "";
  }

  const dispatch = useDispatch();

  useEffect(() => {
    handleClose();
  }, [dispatch, isSuccess]);

  const handleChange = (newValue) => {
    if (isValid(newValue)) {
      // throws an error if invalid value is set
      var date = format(newValue, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      setValue(date);
    }
  };

  useEffect(() => {
    setSelected("");
    setValue(null);
    setSelectedScreeningTest("");
    setDuration(48);
  }, [onClose]);

  useEffect(() => {
    if (activeProcess?.process.selectionLevelId === 2) {
      dispatch(fetchScreeningTests());
    }
  }, [activeProcess]);

  const handleClose = () => {
    onClose();
  };

  const submitHandler = () => {
    if (
      activeProcess.process.selectionLevelId !== 2 &&
      activeProcess.process.selectionLevelId !== 4
    ) {
      dispatch(
        setUpdateStatusReq({
          data: {
            schedulerId: selected,
            appointment: value,
            newStatus: activeProcess.status,
            processId: activeProcess.process.id,
          },
          responseHandler: apiSuccess,
        })
      );
    } else if (activeProcess.process.selectionLevelId === 2) {
      dispatch(
        setUpdateStatusReq({
          data: {
            schedulerId: selected,
            appointment: value,
            newStatus: activeProcess.status,
            processId: activeProcess.process.id,
          },
          responseHandler: apiSuccessScreeningTests,
        })
      );
    } else {
      // FINAL DECISION - edited
      dispatch(
        setUpdateStatusReq({
          data: {
            comment: editorContent.current.getContent(),
            newStatus: activeProcess.status,
            processId: activeProcess.process.id,
          },
        })
      );
    }
  };

  const apiSuccessScreeningTests = () => {
    const user = users.find((k) => k.id === selected);
    dispatch(
      createScreeningTest({
        adminEmail: user.email,
        email: activeProcess.process.applicant.email,
        duration: Number.parseInt(duration),
        testId: parseInt(selectedScreeningTest),
        url: "https:dzenis-meris.com",
      })
    );
    setActiveProcess(null);
  };

  const apiSuccess = () => {
    setActiveProcess(null);
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      keepMounted={false}
      fullWidth={fullWidth}
      fullScreen={responsive && fullScreen}
      onClose={handleClose}
      data-testid="status-dialog"
      open={open}
      style={{
        padding: "36px",
      }}
    >
      <div style={{ padding: "36px" }} data-testid="status-dialog">
        <DialogTitle style={{ padding: 0 }}>
          {fullScreen ? (
            <>
              <div className="flex-center" style={{ justifyContent: "start" }}>
                <img
                  style={{
                    position: "relative",
                    top: -0.25,
                    paddingRight: "10px",
                  }}
                  src={imgSrc}
                />
                <h5 style={{ textAlign: "start" }}>{title}</h5>
                <div style={{ justifySelf: "stretch", flex: "1" }}></div>
                <IconButton onClick={onClose}>
                  <img
                    style={{
                      position: "relative",
                      top: -0.25,
                    }}
                    src={x}
                  />
                </IconButton>
              </div>
              <p
                className="dialog-subtitle"
                style={{ paddingLeft: "23px", marginTop: "-10px" }}
              >
                | {subtitle}
              </p>
            </>
          ) : (
            <div
              className="flex-center"
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex-center" style={{ justifyContent: "start" }}>
                <img
                  style={{
                    position: "relative",
                    top: -0.25,
                    paddingRight: "10px",
                  }}
                  src={imgSrc}
                />
                <h5>{title}</h5>
                <div className="vr"></div>
                <p className="dialog-subtitle">{subtitle}</p>
              </div>
            </div>
          )}
        </DialogTitle>
        {activeProcess !== null &&
          activeProcess.process.selectionLevelId !== 4 && (
            <DialogContent>
              <form className="modal-content interviewDialog">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t("dialogs.interviewerName2")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected}
                    label={t("dialogs.interviewerName2")}
                    onChange={(e) => {
                      setSelected(e.target.value);
                    }}
                  >
                    {users
                      ? users.map(({ id, firstName, lastName }, index) => (
                          <MenuItem
                            key={index}
                            sx={{ textAlign: "left" }}
                            value={id}
                          >
                            {firstName} {lastName}
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                </FormControl>
                {activeProcess !== null &&
                  activeProcess.process.selectionLevelId === 2 && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Screening test
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedScreeningTest}
                        label="Screening test"
                        onChange={(e) => {
                          setSelectedScreeningTest(e.target.value);
                        }}
                      >
                        {screeningTests
                          ? screeningTests.map((screeningTest, index) => (
                              <MenuItem
                                key={index}
                                sx={{ textAlign: "left" }}
                                value={screeningTest.id}
                              >
                                {screeningTest.name}
                              </MenuItem>
                            ))
                          : ""}
                      </Select>
                    </FormControl>
                  )}

                {activeProcess !== null &&
                  activeProcess.process.selectionLevelId === 2 && (
                    <TextField
                      name="duration"
                      label={t("dialogs.duration")}
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      fullWidth
                    />
                  )}

                {/* {activeProcess.process && activeProcess.process.date ? <p>Proces ima zakazan termin</p> : ''} */}
                <DateTimePicker
                  label={t("dialogs.appointment")}
                  value={value}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </form>
            </DialogContent>
          )}
        {activeProcess !== null &&
          activeProcess.process.selectionLevelId === 4 && (
            <DialogContent>
              <form className="modal-content comment">
                <p
                  style={{
                    width: "100%",
                    textAlign: "left",
                    alignSelf: "flex-start",
                    marginBottom: "10px",
                  }}
                >
                  {t("dialogs.comment")}
                </p>
                <Editor
                  apiKey={TINYMCE_API_KEY}
                  onInit={(evt, editor) => (editorContent.current = editor)}
                  style={{ height: "20px" }}
                />
              </form>
            </DialogContent>
          )}
        <DialogActions style={{ padding: 0, justifyContent: "space-between" }}>
          {!fullScreen ? (
            <IconButton
              data-testid="editbtn"
              className={`c-btn--primary-outlined interview-btn c-btn dialog-btn`}
              onClick={onClose}
            >
              {t("common.cancel")}
            </IconButton>
          ) : (
            ""
          )}
          <IconButton
            data-testid="editbtn"
            className={`c-btn--primary-outlined sm-full interview-btn c-btn dialog-btn`}
            onClick={submitHandler}
          >
            {t("common.confirm")}
          </IconButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

StatusDialog.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  imgSrc: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  fullWidth: PropTypes.bool,
  responsive: PropTypes.bool,
};

export default StatusDialog;
