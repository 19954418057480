import createReducer from "../../utils/createReducer";
import {
  FETCH_TECHNOLOGIES_SUCCESS,
  FETCH_TECHNOLOGIES_ERR,
  CHANGE_ISCHECKED_VALUE,
  RESET_IS_CHECKED_VALUE
} from "../../actions/technologies/technologiesActionConstants";

const initialState = {
  technologies: [],
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_TECHNOLOGIES_SUCCESS]: setStateTechnologies,
    [FETCH_TECHNOLOGIES_ERR]: setStateErrorMessage,
    [CHANGE_ISCHECKED_VALUE]: setIsCheckedTechnology,
    [RESET_IS_CHECKED_VALUE]: resetIsCheckedTechnologies
  },
  initialState
);

function setStateTechnologies(state, action) {
  return { ...state, technologies: action.payload };
}

function setStateErrorMessage(state, action) {
  return { ...state, errorMessage: action.payload };
}

function setIsCheckedTechnology(state, action) {
  const tmpIndex = state.technologies.findIndex(
    (x) => x.name === action.payload
  );

  if (tmpIndex === -1) {
    return state;
  }

  return {
    ...state,
    technologies: state.technologies.map((tech, index) =>
      tmpIndex === index ? { ...tech, isChecked: !tech.isChecked } : tech
    ),
  };
}

function resetIsCheckedTechnologies(state){
  return {...state,technologies:state.technologies.map(t => ({...t,isChecked:false}))}
}
