import {
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
} from "../actionHelpers";

const SCREENING_TESTS_SCOPE = "SCREENING_TESTS";
const CREATE_SCREENING_TEST_SCOPE = "CREATE_SCREENING_TEST";

export const SCREENING_TESTS_FETCH = createFetchType(SCREENING_TESTS_SCOPE);
export const SCREENING_TESTS_ERROR = createErrorType(SCREENING_TESTS_SCOPE);
export const SCREENING_TESTS_SUCCESS = createSuccessType(SCREENING_TESTS_SCOPE);
export const SCREENING_TESTS_LOADING = createLoadingType(SCREENING_TESTS_SCOPE);

export const CREATE_SCREENING_TEST = createFetchType(CREATE_SCREENING_TEST_SCOPE);
export const CREATE_SCREENING_TEST_ERROR = createErrorType(CREATE_SCREENING_TEST_SCOPE);
export const CREATE_SCREENING_TEST_SUCCESS = createSuccessType(CREATE_SCREENING_TEST_SCOPE);
export const CREATE_SCREENING_TEST_LOADING = createLoadingType(CREATE_SCREENING_TEST_SCOPE);
