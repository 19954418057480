import {
  ADS_CANDIDATES_FETCH,
  ADS_CANDIDATES_ERROR,
  ADS_CANDIDATES_SUCCESS,
  FILTER_CANDIDATES,
  FILTER_CANDIDATES_ERROR,
  FILTER_CANDIDATES_SUCCESS,
  CANDIDATE_OPTIONS_FETCH,
  CANDIDATE_OPTIONS_ERROR,
  CANDIDATE_OPTIONS_SUCCESS,
  CANDIDATE_INIT_PROCESS_ERROR,
  CANDIDATE_INIT_PROCESS_FETCH,
  CANDIDATE_INIT_PROCESS_SUCCESS,
} from "./candidatesActionConstants";

export const filterCandidates = (payload) => ({
  type: FILTER_CANDIDATES,
  payload,
});

export const filterCandidatesError = (payload) => ({
  type: FILTER_CANDIDATES_ERROR,
  payload,
});

export const filterCandidatesSuccess = (payload) => ({
  type: FILTER_CANDIDATES_SUCCESS,
  payload,
});

export const fetchAdsCandidates = (payload) => ({
  type: ADS_CANDIDATES_FETCH,
  payload,
});

export const fetchAdsCandidatesError = (payload) => ({
  type: ADS_CANDIDATES_ERROR,
  payload,
});

export const fetchAdsCandidatesSuccess = (payload) => ({
  type: ADS_CANDIDATES_SUCCESS,
  payload,
});

export const fetchCandidateOptions = () => {
  return {
    type: CANDIDATE_OPTIONS_FETCH,
  };
};

export const fetchCandidateOptionsError = (payload) => ({
  type: CANDIDATE_OPTIONS_ERROR,
  payload,
});

export const fetchCandidateOptionsSuccess = (payload) => ({
  type: CANDIDATE_OPTIONS_SUCCESS,
  payload,
});

export const fetchInitProcess = (payload) => {
  return {
    type: CANDIDATE_INIT_PROCESS_FETCH,
    payload
  };
};

export const fetchInitProcessError = (payload) => ({
  type: CANDIDATE_INIT_PROCESS_ERROR,
  payload,
});

export const fetchInitProcessSuccess = (payload) => ({
  type: CANDIDATE_INIT_PROCESS_SUCCESS,
  payload,
});
