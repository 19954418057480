import React from "react";
import PropTypes from "prop-types";
// import logoReact from "../../assets/images/logo_react.png";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import linkedin from "../../assets/images/linkedin.png";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import { selectLogo } from "../../util/helpers/technologiesLogos";
import { useTranslation } from "react-i18next";

const Ad = ({
  title,
  minimumExperience,
  createdAt,
  expiredAt,
  onShowAdDetails,
  className,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <div className={`job-card ${className}`} onClick={onShowAdDetails}>
      <div className="job-card-date">
        <p>
          {new Date(createdAt).toLocaleDateString()} -{" "}
          {new Date(expiredAt).toLocaleDateString()}
        </p>
      </div>

      <div className="job-card-title">
        <h3>{title}</h3>
      </div>

      <div className="job-card-logo">
        <img src={selectLogo(title)} alt="logo-react" />
      </div>

      <div className="job-card-experience">
        <p>
          {minimumExperience}+ {t("common.experience")}
        </p>
      </div>

      {!matches && (
        <div className="job-card-buttons">
          <button>LinkedIn</button>
          <button>Facebook</button>
          <button disabled>Instagram</button>
        </div>
      )}
      {matches && (
        <div className="job-card-buttons">
          <button className="job-card-buttons-button">
            <img src={linkedin} />
          </button>
          <button className="job-card-buttons-button">
            <img src={facebook} />
          </button>
          <button disabled className="job-card-buttons-button">
            <img src={instagram} />
          </button>
        </div>
      )}
    </div>
  );
};

Ad.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  minimumExperience: PropTypes.number,
  createdAt: PropTypes.any,
  expiredAt: PropTypes.any,
  onShowAdDetails: PropTypes.func,
  className: PropTypes.any,
};

export default Ad;
