import React, { useState } from "react";
import CustomDrawer from "../UI/CustomDrawer";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setFilteredPatternsReq } from "../../store/actions/patterns/patternsActions";
import { useTranslation } from "react-i18next";

const PatternFilters = ({
  openFilterDrawer,
  handleClose,
  selectionLevelFilter,
  onChangeFilterCheckboxes,
}) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const submitFiltersHandler = (e) => {
    e.preventDefault();

    const selectionLevels = selectionLevelFilter
      .filter((level) => level.isChecked === true)
      .map((level) => level.id);

    dispatch(
      setFilteredPatternsReq({
        fromDate: fromDate === "" ? null : fromDate,
        toDate: toDate === "" ? null : toDate,
        selectionLevels,
        handleApiResponseSuccess,
      })
    );
  };

  const handleApiResponseSuccess = () => {
    handleClose();
  };

  const hasSelectedFilters = () => {
    const checked = selectionLevelFilter.filter(
      (level) => level.isChecked === true
    );

    if (checked.length > 0) {
      return true;
    }

    if (fromDate !== "") {
      return true;
    }

    if (toDate !== "") {
      return true;
    }

    return false;
  };

  return (
    <CustomDrawer
      title="Šabloni"
      open={openFilterDrawer}
      onCloseDrawer={handleClose}
    >
      <form onSubmit={submitFiltersHandler}>
        <div className="custom-drawer">
          <div>
            <div className="custom-drawer-sub-card">
              <div className="custom-drawer-sub-card-label">
                <p>{t("filters.category")}</p>
              </div>
              <div className="custom-drawer-sub-card-content">
                <FormGroup>
                  {selectionLevelFilter.map((process) => (
                    <FormControlLabel
                      key={process.id}
                      control={
                        <Checkbox
                          value={process.name}
                          checked={process.isChecked}
                          onChange={() => onChangeFilterCheckboxes(process.id)}
                          className="pattern-filters-checkbox"
                        />
                      }
                      label={process.name}
                    />
                  ))}
                </FormGroup>
              </div>
            </div>
            <div className="custom-drawer-sub-card">
              <div className="custom-drawer-sub-card-label">
                <p>{t("filters.creationDate")}</p>
              </div>
              <div className="custom-drawer-sub-card-content">
                <FormGroup style={{ marginBottom: "9px" }}>
                  <div className="custom-drawer-sub-card-content-sub">
                    <label className="custom-drawer-sub-card-content-sub-label">
                      {t("common.from")}
                    </label>
                    <input
                      type="date"
                      className="custom-drawer-sub-card-content-input-1"
                      onChange={(e) => setFromDate(e.target.value)}
                      value={fromDate}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="custom-drawer-sub-card-content-sub">
                    <label className="custom-drawer-sub-card-content-sub-label">
                      {t("common.to")}
                    </label>
                    <input
                      type="date"
                      className="custom-drawer-sub-card-content-input-2"
                      onChange={(e) => setToDate(e.target.value)}
                      value={toDate}
                    />
                  </div>
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="custom-drawer-submit">
            <button
              className="c-btn c-btn--primary"
              data-testid="custom-drawer-submit-search"
              disabled={!hasSelectedFilters()}
            >
              {t("common.search")}
            </button>
          </div>
        </div>
      </form>
    </CustomDrawer>
  );
};

PatternFilters.propTypes = {
  openFilterDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  selectionLevelFilter: PropTypes.any,
  onChangeFilterCheckboxes: PropTypes.func,
};

export default PatternFilters;
