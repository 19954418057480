import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

const ApplyForAdSecondStage = ({
  professionalQualification,
  setProfessionalQualification,
  technologies,
  setTechnologies,
  experience,
  setExperience,
  onIncreaseStage,
  onDecreaseStage,
}) => {
  const {t} = useTranslation()
  let disabled = true;
  let isTechnologySelected = false;
  if (technologies.length > 0) {
    isTechnologySelected = technologies.some((x) => x.isChecked === true);
  }
  if (isTechnologySelected) {
    if (professionalQualification.length > 0) {
      disabled = false;
    }
  }

  const handleCheckboxes = (technologyId) => {
    const tmpTechnologies = technologies.map((tech) =>
      tech.technologyId === technologyId
        ? { ...tech, isChecked: !tech.isChecked }
        : tech
    );
    setTechnologies(tmpTechnologies);
  };

  return (
    <div data-testid="apply-for-job-second-stage">
      <div className="apply-for-job-header-title">
        <p>{t("ads.professionalQualification")}</p>
      </div>
      <div className="apply-for-job-modal-form-control">
        <input
          type="text"
          placeholder="ex. Elektrotehnicki fakultet"
          data-testid="apply-for-ad-second-stage-professional-qualification"
          value={professionalQualification}
          onChange={(e) => setProfessionalQualification(e.target.value)}
        />
      </div>
      <div className="apply-for-job-header-title">
        <p>{t("ads.technologies")}</p>
      </div>
      <div className="apply-for-job-header-sub">
        <div className="apply-for-job-header-sub-group">
          <label>Back-End</label>
          <div className="apply-for-job-header-sub-group-checkboxes">
            {technologies
              .filter((x) => x.technologyType === "Backend")
              .map((x) => (
                <FormControlLabel
                  key={x.technologyId}
                  control={
                    <Checkbox
                      className="apply-for-job-second-stage-checkbox"
                      value={x.name}
                      checked={x.isChecked}
                      onChange={handleCheckboxes.bind(this, x.technologyId)}
                    />
                  }
                  label={x.name}
                />
              ))}
          </div>
        </div>
        <div className="apply-for-job-header-sub-group">
          <label>Front-End</label>
          <div className="apply-for-job-header-sub-group-checkboxes">
            {technologies
              .filter((x) => x.technologyType === "Frontend")
              .map((x) => (
                <FormControlLabel
                  key={x.technologyId}
                  control={
                    <Checkbox
                      className="apply-for-job-second-stage-checkbox"
                      value={x.name}
                      checked={x.isChecked}
                      onChange={handleCheckboxes.bind(this, x.technologyId)}
                    />
                  }
                  label={x.name}
                />
              ))}
          </div>
        </div>
        <div className="apply-for-job-header-sub-group">
          <label>{t("ads.others")}</label>
          <div className="apply-for-job-header-sub-group-checkboxes">
            {technologies
              .filter((x) => x.technologyType === "Other")
              .map((x) => (
                <FormControlLabel
                  key={x.technologyId}
                  control={
                    <Checkbox
                      className="apply-for-job-second-stage-checkbox"
                      value={x.name}
                      checked={x.isChecked}
                      onChange={handleCheckboxes.bind(this, x.technologyId)}
                    />
                  }
                  label={x.name}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="apply-for-job-modal-form-control">
        <label>{t("filters.experience")}</label>
        <input
          type="number"
          placeholder="ex. 3 godine iskustva"
          value={experience}
          data-testid="apply-for-ad-second-stage-experience-input"
          onChange={(e) => setExperience(e.target.value)}
        />
      </div>
      <div className="apply-for-job-buttons">
        <button onClick={onDecreaseStage}>{t("common.back")}</button>
        <button onClick={onIncreaseStage} disabled={disabled}>
          {t("common.continue")}
        </button>
      </div>
    </div>
  );
};

ApplyForAdSecondStage.propTypes = {
  professionalQualification: PropTypes.string,
  setProfessionalQualification: PropTypes.func,
  technologies: PropTypes.any,
  setTechnologies: PropTypes.any,
  experience: PropTypes.number,
  setExperience: PropTypes.any,
  onIncreaseStage: PropTypes.func,
  onDecreaseStage: PropTypes.func,
};

export default ApplyForAdSecondStage;
