import { all, call, put, takeEvery } from "redux-saga/effects";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import {
  createComment,
  getCandidate,
  getFilteredAdsCandidates,
  deleteCandidate,
  getFilteredCandidates,
  getCandidateOptions,
  initializeProcessRequest,
} from "../../request/candidatesRequest";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import {
  ADS_CANDIDATES_FETCH,
  CANDIDATE_INIT_PROCESS_FETCH,
  CANDIDATE_OPTIONS_FETCH,
  FILTER_CANDIDATES,
} from "../actions/candidates/candidatesActionConstants";
import {
  fetchAdsCandidatesError,
  fetchAdsCandidatesSuccess,
  filterCandidatesSuccess,
  filterCandidatesError,
  fetchCandidateOptionsSuccess,
  fetchCandidateOptionsError,
  fetchInitProcessSuccess,
  fetchInitProcessError,
} from "../actions/candidates/candidatesActions";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import {
  fetchCandidateError,
  fetchCandidateSuccess,
  createCandidateCommentSuccess,
  createCandidateCommentError,
  deleteCandidateSuccess,
  deleteCandidateError,
} from "../actions/candidate/candidateActions";
import {
  CANDIDATE_FETCH,
  CANDIDATE_COMMENTS_FETCH,
  DELETE_CANDIDATE,
} from "../actions/candidate/candidateActionConstants";

export function* filterCandidates({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const { data } = yield call(getFilteredCandidates, payload);
    yield put(filterCandidatesSuccess(data));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(filterCandidatesError(errorMessage));
    }
  }
}

export function* getSingleCandidate({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const { data } = yield call(getCandidate, payload.id);
    yield put(fetchCandidateSuccess(data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(fetchCandidateError(errorMessage));
    }
  }
}

export function* addComment(data) {
  const { user, ...myObj } = data.payload;
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    yield call(createComment, myObj);
    yield put(createCandidateCommentSuccess({ user, myObj }));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(createCandidateCommentError(errorMessage));
    }
  }
}

export function* getAdsCandidates({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const { data } = yield call(getFilteredAdsCandidates, payload);
    yield put(fetchAdsCandidatesSuccess(data));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(fetchAdsCandidatesError(errorMessage));
    }
  }
}

export function* deleteSingleCandidate({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    yield call(deleteCandidate, payload.id);
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(deleteCandidateSuccess());
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(deleteCandidateError(errorMessage));
    }
  }
}

export function* getOptions() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const { data } = yield call(getCandidateOptions);
    yield put(fetchCandidateOptionsSuccess(data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(fetchCandidateOptionsError(errorMessage));
    }
  }
}

export function* initializeProcess({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    yield call(initializeProcessRequest, payload.model);
    yield put(fetchInitProcessSuccess());
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(fetchInitProcessError(errorMessage));
    }
  }
}

export default function* candidatesSaga() {
  yield all([takeEvery(CANDIDATE_FETCH, getSingleCandidate)]);
  yield all([takeEvery(ADS_CANDIDATES_FETCH, getAdsCandidates)]);
  yield all([takeEvery(CANDIDATE_COMMENTS_FETCH, addComment)]);
  yield all([takeEvery(DELETE_CANDIDATE, deleteSingleCandidate)]);
  yield all([takeEvery(FILTER_CANDIDATES, filterCandidates)]);
  yield all([takeEvery(CANDIDATE_OPTIONS_FETCH, getOptions)]);
  yield all([takeEvery(CANDIDATE_INIT_PROCESS_FETCH, initializeProcess)]);
}
