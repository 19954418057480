import { FETCH_STATS_ERR, FETCH_STATS_REQ, FETCH_STATS_SUCCESS } from "./statsActionConstants";

// STATS
export const getStatsReq = () => ({
    type: FETCH_STATS_REQ,
  });
  
  export const getStatsError = (payload) => ({
    type: FETCH_STATS_ERR,
    payload,
  });
  
  export const getStatsSuccess = (payload) => ({
    type: FETCH_STATS_SUCCESS,
    payload,
  });