import {
    FETCH_TECHNOLOGIES_ERR,
    FETCH_TECHNOLOGIES_SUCCESS,
    FETCH_TECHNOLOGIES_REQ,
    CHANGE_ISCHECKED_VALUE,
    RESET_IS_CHECKED_VALUE
  } from "./technologiesActionConstants";
  
  export const setTechnologiesReq = () => ({
      type: FETCH_TECHNOLOGIES_REQ,
    });
    
    export const setTechnologiesError = (payload) => ({
      type: FETCH_TECHNOLOGIES_ERR,
      payload,
    });
    
    export const setTechnologies = (payload) => ({
      type: FETCH_TECHNOLOGIES_SUCCESS,
      payload,
    });
    
    export const changeIsCheckedValue = (payload) => ({
      type: CHANGE_ISCHECKED_VALUE,
      payload,
    });

    export const resetIsCheckedValue = () => ({
      type:RESET_IS_CHECKED_VALUE
    }) 