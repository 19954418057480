import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Box } from "@mui/material";
import PropTypes from "prop-types";
import { EXPERIENCE_STEP, PROFILE_STEP } from "../../constants/cv";

const CreateCvProfileStep = ({
  cvData,
  setCvData,
  setActiveStep,
  nextStep,
  setNextStep,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    description: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCvData((state) => ({ ...state, [name]: value }));
    setErrors((state) => ({ ...state, [name]: false }));
  };

  useEffect(() => {
    validate();
  }, [nextStep]);

  const validate = () => {
    if (nextStep !== EXPERIENCE_STEP) return;

    const fieldsToValidate = [
      "firstName",
      "lastName",
      "phone",
      "email",
      "description",
    ];

    let hasErrors = false;

    fieldsToValidate.forEach((field) => {
      const fieldValue = cvData[field].trim();
      const fieldHasError = fieldValue.length === 0;
      setErrors((prev) => ({ ...prev, [field]: fieldHasError }));
      if (fieldHasError) {
        hasErrors = true;
      }
    });

    if (!hasErrors) {
      setActiveStep(EXPERIENCE_STEP);
      return;
    }

    setNextStep(PROFILE_STEP);
  };

  const getHelperText = (fieldName) => {
    return `${t(`common.${fieldName}`)} ${t("cv.helperText")}`;
  };

  return (
    <Box className="cv-profile-main">
      <Box className="cv-profile-row">
        <Box className="cv-profile-col1">
          <Box className="cv-form-control">
            <label>{t("common.firstName")}</label>
            <TextField
              name="firstName"
              placeholder="ex. Petar"
              helperText={errors.firstName && getHelperText("firstName")}
              error={errors.firstName}
              value={cvData.firstName}
              onChange={handleInputChange}
            />
          </Box>
          <Box className="cv-form-control">
            <label>{t("common.lastName")}</label>
            <TextField
              name="lastName"
              placeholder="ex. Petrovic"
              helperText={errors.lastName && getHelperText("lastName")}
              error={errors.lastName}
              value={cvData.lastName}
              onChange={handleInputChange}
            />
          </Box>
          <Box className="cv-form-control">
            <label>Email</label>
            <TextField
              name="email"
              placeholder="ex. petarpetrovic@gmail.com"
              helperText={errors.email && `Email ${t("cv.helperText")}`}
              error={errors.email}
              value={cvData.email}
              onChange={handleInputChange}
            />
          </Box>
          <Box className="cv-form-control">
            <label>{t("common.phoneNumber")}</label>
            <TextField
              name="phone"
              placeholder="ex. +381/60/000/0000"
              helperText={errors.phone && getHelperText("phoneNumber")}
              error={errors.phone}
              value={cvData.phone}
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Box className="cv-form-control" sx={{ mt: "auto" }}>
          <label>{t("common.description")}</label>
          <TextField
            name="description"
            multiline
            rows={10}
            placeholder="ex. Soware engineer with strong experience gained in real projects..."
            helperText={errors.description && getHelperText("description")}
            error={errors.description}
            value={cvData.description}
            onChange={handleInputChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

CreateCvProfileStep.propTypes = {
  cvData: PropTypes.object,
  setCvData: PropTypes.func,
  setActiveStep: PropTypes.func,
  nextStep: PropTypes.number,
  setNextStep: PropTypes.func,
};

export default CreateCvProfileStep;
