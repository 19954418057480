import {
  FETCH_INTERVIEWER_CHANGE_ERR,
  FETCH_INTERVIEWER_CHANGE_REQ,
  FETCH_INTERVIEWER_CHANGE_SUCCESS,
  FETCH_STATUS_CHANGE_ERR,
    FETCH_STATUS_CHANGE_REQ,
    FETCH_STATUS_CHANGE_SUCCESS,
    PUT_PROCESS_ERR,
    PUT_PROCESS_REQ,
    PUT_PROCESS_SUCCESS
  } from "./processesActionConstants";
  
  export const setDoneProcessReq = (payload) => ({
    type: PUT_PROCESS_REQ,
    payload
  });
  
  export const setDoneProcessError = (payload) => ({
    type: PUT_PROCESS_ERR,
    payload,
  });
  
  export const setDoneProcess = (payload) => ({
    type: PUT_PROCESS_SUCCESS,
    payload
  });
  
  //
  export const setUpdateStatusReq = (payload) => ({
    type: FETCH_STATUS_CHANGE_REQ,
    payload
  });
  
  export const setUpdateStatusErr = (payload) => ({
    type: FETCH_STATUS_CHANGE_ERR,
    payload,
  });
  
  export const setUpdateStatusSucc = () => ({
    type: FETCH_STATUS_CHANGE_SUCCESS,
  });
  
  //
  export const setUpdateInterviewerReq = (payload) => ({
    type: FETCH_INTERVIEWER_CHANGE_REQ,
    payload
  });
  
  export const setUpdateInterviewerErr = (payload) => ({
    type: FETCH_INTERVIEWER_CHANGE_ERR,
    payload,
  });
  
  export const setUpdateInterviewerSucc = () => ({
    type: FETCH_INTERVIEWER_CHANGE_SUCCESS,
  });
  