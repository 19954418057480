import {
    createErrorType,
    createFetchType,
    createLoadingType,
    createSuccessType,
  } from "../actionHelpers";
  
  const CREATE_AD_SCOPE = "CREATE_AD";
  
  export const CREATE_AD_REQ = createFetchType(CREATE_AD_SCOPE);
  export const CREATE_AD_ERR = createErrorType(CREATE_AD_SCOPE);
  export const CREATE_AD_SUCCESS = createSuccessType(CREATE_AD_SCOPE);
  export const CREATE_AD_LOADING = createLoadingType(CREATE_AD_SCOPE);
  