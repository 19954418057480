import { all } from "redux-saga/effects";
import adsSaga from "./adsSaga";
import candidatesSaga from "./candidatesSaga";
import loginSaga from "./loginSaga";
import technologiesSaga from "./technologiesSaga";
import usersSaga from "./usersSaga";
import processesSaga from "./processSaga";
import patternsSage from "./patternsSaga";
import statsSaga from "./statsSaga";
import scheduleSaga from "./scheduleSaga";
import registerSaga from "./registerSaga";
import applicantsSaga from "./applicantsSaga";
import screeningTestsSaga from "./screeningTestsSaga";
import cvPreviewSaga from "./cvSaga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    usersSaga(),
    adsSaga(),
    technologiesSaga(),
    candidatesSaga(),
    processesSaga(),
    patternsSage(),
    statsSaga(),
    scheduleSaga(),
    registerSaga(),
    applicantsSaga(),
    screeningTestsSaga(),
    cvPreviewSaga(),
  ]);
}
