import React, { useState } from "react";
import PropTypes from "prop-types";
import uploadIcon from "../../assets/images/upload.png";
import { useTranslation } from "react-i18next";

const ApplyForAdFourthStage = ({
  coverLetter,
  setCoverLetter,
  pdfFile,
  setPdfFile,
  onDecreaseStage,
  onFinishedFourStages,
}) => {
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const {t} = useTranslation()

  const disabled = pdfFile === null || coverLetter === "";

  const handleDrop = (e) => {
    e.preventDefault();
    setPdfFile(e.dataTransfer.files[0]);
  };

  return (
    <div data-testid="apply-for-job-modal-fourth-stage">
      <div className="apply-for-job-modal-form-control">
        <label>CV</label>
        <div
          className="uploadCV-input"
          onDragOver={(e) => {
            setDropzoneActive(true);
            e.preventDefault();
          }}
          onDragLeave={(e) => {
            setDropzoneActive(false);
            e.preventDefault();
          }}
          onDrop={(e) => handleDrop(e)}
          style={{
            backgroundColor: dropzoneActive ? "#F4F4F4" : "#ffffff",
          }}
        >
          <div className="uploadCV-input-sub-container">
            <img src={uploadIcon} />
            <div className="uploadCV-input-sub-container">
              {pdfFile !== null ? (
                <p>{pdfFile.name}</p>
              ) : (
                <>
                  <p>
                    {t("ads.dragPdf1")} &nbsp;
                    <label
                      htmlFor="upload-file"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#1E92D0",
                      }}
                    >
                      {t("common.search")}
                    </label>
                    &nbsp;{t("ads.dragPdf2")}
                  </p>
                  <input
                    type="file"
                    name="photo"
                    id="upload-file"
                    style={{ display: "none", zIndex: -1 }}
                    value={pdfFile}
                    data-testid="apply-for-job-modal-fourth-stage-pdf-input"
                    onChange={(e) => {
                      console.log("Ovde smo");
                      setPdfFile(e.target.files[0]);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="apply-for-job-modal-form-control">
        <label>{t("ads.coverLetter")}</label>
        <textarea
          value={coverLetter}
          onChange={(e) => setCoverLetter(e.target.value)}
          data-testid="apply-for-job-modal-fourth-stage-cover-letter-input"
          placeholder="ex. Kao student Elektronskog fakulteta u Nišu..."
          rows={5}
        ></textarea>
      </div>
      <div className="apply-for-job-buttons">
        <button
          onClick={onDecreaseStage}
          data-testid="apply-for-job-modal-fourth-stage-go-back-button"
        >
          {t("common.back")}
        </button>
        <button
          disabled={disabled}
          data-testid="apply-for-job-modal-fourth-stage-go-forward-button"
          onClick={onFinishedFourStages}
        >
          {t("ads.signUp")}
        </button>
      </div>
    </div>
  );
};

ApplyForAdFourthStage.propTypes = {
  coverLetter: PropTypes.string,
  setCoverLetter: PropTypes.any,
  pdfFile: PropTypes.any,
  setPdfFile: PropTypes.any,
  onDecreaseStage: PropTypes.func,
  onFinishedFourStages: PropTypes.func,
};

export default ApplyForAdFourthStage;
