import {
  createFetchType,
  createSuccessType,
  createErrorType,
  createLoadingType,
} from "../actionHelpers";

const CREATE_AD_SCOPE = "FETCH_ADS";

export const FETCH_ADS_REQ = createFetchType(CREATE_AD_SCOPE);
export const FETCH_ADS_ERR = createErrorType(CREATE_AD_SCOPE);
export const FETCH_ADS_SUCCESS = createSuccessType(CREATE_AD_SCOPE);
export const FETCH_ADS_LOADING = createLoadingType(CREATE_AD_SCOPE);

export const FETCH_FILTERED_ADS_REQ = "FETCH_FILTERED_ADS_REQ";
export const FETCH_FILTERED_ADS_ERR = "FETCH_FILTERED_ADS_ERR";
export const FETCH_FILTERED_ADS_SUCCESS = "FETCH_FILTERED_ADS_SUCCESS";
