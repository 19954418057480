import React from "react";
import PropTypes from "prop-types";
import editIcon from "../../assets/images/edit.png";
import IconButton from "../IconButton/IconButton";

const PatternCard = ({
  createdAt,
  title,
  selectionProcess,
  isShownEdit,
  onShowPatternDetails,
  onOpenEditModal,
}) => {
  return (
    <div className="pattern-card-with-icon">
      {isShownEdit && (
        <div className="pattern-card-edit">
          <IconButton
            onClick={onOpenEditModal}
            className={`c-btn--primary-outlined c-btn pattern-card-edit`}
          >
            <img
              style={{ width: "16px !important", height: "16px !important" }}
              src={editIcon}
            />
          </IconButton>
        </div>
      )}
      <div className="pattern-card" onClick={onShowPatternDetails}>
        <div className="pattern-card-date">
          <p>{new Date(createdAt).toLocaleDateString()}</p>
        </div>
        <div className="pattern-card-title">
          <p>{title}</p>
        </div>
        <div className="pattern-card-selection-proccess">
          <p>{selectionProcess}</p>
        </div>
      </div>
    </div>
  );
};

PatternCard.propTypes = {
  createdAt: PropTypes.any,
  title: PropTypes.string,
  selectionProcess: PropTypes.string,
  isShownEdit: PropTypes.bool,
  onShowPatternDetails: PropTypes.func,
  onOpenEditModal: PropTypes.func,
};

export default PatternCard;
