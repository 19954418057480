import { all, call, put, takeEvery } from "redux-saga/effects";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import { getStats } from "../../request/statsRequests";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import { FETCH_STATS_REQ } from "../actions/stats/statsActionConstants";
import { getStatsError, getStatsSuccess } from "../actions/stats/statsActions";

export function* getAppStats() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getStats);
    yield put(getStatsSuccess(result.data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(getStatsError(errorMessage));
    }
  }
}
export default function* statsSaga() {
  yield all([
    takeEvery(FETCH_STATS_REQ, getAppStats),
  ]);
}
