import { combineReducers } from "redux";
import loginReducer from "./login/loginReducer";
import loadingReducer from "./loading/loadingReducer";
import userReducer from "./user/userReducer";
import usersReducer from "./user/usersReducer";
import candidateReducer from "./candidate/candidateReducer";
import adsReducer from "./ad/adsReducer";
import adReducer from "./ad/adReducer";
import archiveAdsReducer from "./ad/archiveAdsReducer";
import candidatesReducer from "./candidates/candidatesReducer";
import technologiesReducer from "./technology/technologiesReducer";
import addAddTechnologiesReducer from "./technology/addAddTechnologiesReducer";
import createAdReducer from "./ad/createAdReducer";
import processesReducer from "./processes/processesReducer";
import processReducer from "./processes/processReducer";
import applicantWithProcessesReducer from "./processes/applicantWithProcessesReducer";
import userDetailsReducer from "./user/userDetailsReducer";
import inviteUserReducer from "./user/inviteUserReducer";
import statusReducer from "./processes/statusReducer";
import patternsReducer from "./pattern/patternsReducer";
import patternReducer from "./pattern/patternReducer";
import createPatternReducer from "./pattern/createPatternReducer";
import updatePatternReducer from "./pattern/updatePatternReducer";
import statsReducer from "./stats/statsReducer";
import scheduleReducer from "./schedule/scheduleReducer";
import scheduleAppointmentReducer from "./pattern/scheduleAppointmentReducer";
import patternApplicantsReducer from "./pattern/patternApplicantsReducer";
import archiveActiveAdReducer from "./ad/archiveActiveAdReducer";
import registerReducer from "./register/registerReducer";
import candidateOptionsReducer from "./candidates/candidateOptionsReducer";
import initProcessReducer from "./candidates/initProcessReducer";
import applyForAdReducer from "./applicants/applyForAdReducer";
import statusUpdateReducer from "./processes/statusUpdateReducer";
import interviewerUpdateReducer from "./processes/interviewerUpdateReducer";
import screeningTestsReducer from "./screeningTests/screeningTestsReducer";
import cvPreviewReducer from "./cv/cvPreviewReducer";
import cvInviteReducer from "./cv/cvInviteReducer";
import cvCreateReducer from "./cv/cvCreateReducer";

export default combineReducers({
  login: loginReducer,
  user: userReducer,
  loading: loadingReducer,
  users: usersReducer,
  candidate: candidateReducer,
  ads: adsReducer,
  ad: adReducer,
  archiveAds: archiveAdsReducer,
  technologies: technologiesReducer,
  addAdTechnologies: addAddTechnologiesReducer,
  createAd: createAdReducer,
  archiveActiveAd: archiveActiveAdReducer,
  candidates: candidatesReducer,
  processes: processesReducer,
  process: processReducer,
  applicant: applicantWithProcessesReducer,
  userDetails: userDetailsReducer,
  invite: inviteUserReducer,
  statuses: statusReducer,
  patterns: patternsReducer,
  pattern: patternReducer,
  createPattern: createPatternReducer,
  updatePattern: updatePatternReducer,
  stats: statsReducer,
  schedule: scheduleReducer,
  register: registerReducer,
  options: candidateOptionsReducer,
  initProcess: initProcessReducer,
  scheduleAppointment: scheduleAppointmentReducer,
  patternApplicants: patternApplicantsReducer,
  applyForAd: applyForAdReducer,
  statusUpdate: statusUpdateReducer,
  interviewerUpdate: interviewerUpdateReducer,
  screeningTests: screeningTestsReducer,
  cvPreview: cvPreviewReducer,
  cvInvite: cvInviteReducer,
  cvCreate: cvCreateReducer,
});
