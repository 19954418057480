import {
  FETCH_ARCHIVE_ADS_REQ,
  FETCH_ARCHIVE_ADS_SUCCESS,
  FETCH_ARCHIVE_ADS_ERR,
} from "./archiveAdsActionConstants";

export const setArchiveAdsReq = () => ({
  type: FETCH_ARCHIVE_ADS_REQ,
});

export const setArchiveAdsError = (payload) => ({
  type: FETCH_ARCHIVE_ADS_ERR,
  payload,
});

export const setArchiveAds = (payload) => ({
  type: FETCH_ARCHIVE_ADS_SUCCESS,
  payload,
});
