import {
    createErrorType,
    createFetchType,
    createLoadingType,
    createSuccessType,
  } from "../actionHelpers";
  
  const SCHEDULE_SCOPE = "SCHEDULE";
  
  export const SCHEDULE_FETCH = createFetchType(SCHEDULE_SCOPE);
  export const SCHEDULE_ERROR = createErrorType(SCHEDULE_SCOPE);
  export const SCHEDULE_SUCCESS = createSuccessType(SCHEDULE_SCOPE);
  export const SCHEDULE_LOADING = createLoadingType(SCHEDULE_SCOPE);
  