import { createSelector } from "@reduxjs/toolkit";

export const cvPreviewSelector = (state) => state.cvPreview;

export const selectCvPreview = createSelector(
  cvPreviewSelector,
  (state) => state.cvPreview
);

export const selectAdError = createSelector(
  cvPreviewSelector,
  (state) => state.errorMessage
);
