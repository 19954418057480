import { createSelector } from "@reduxjs/toolkit";

export const archiveAdsSelector = (state) => state.archiveAds;

export const selectArchiveAds = createSelector(
  archiveAdsSelector,
  (state) => state.archiveAds
);

export const selectArchiveAdsError = createSelector(
  archiveAdsSelector,
  (state) => state.errorMessage
);
