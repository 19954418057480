import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { FINISH_STEP, OTHER_SKILLS_STEP, getCvDto } from "../../constants/cv";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  setCreateCvReq,
  setRespondToCvInviteReq,
} from "../../store/actions/cv/cvActions";

const CreateCvOtherSkillsAndAccomplishmentsStep = ({
  cvData,
  setCvData,
  nextStep,
  setNextStep,
  setActiveStep,
}) => {
  let { token } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { otherSkillsAndAccomplishments } = cvData;
  const [errors, setErrors] = useState([
    {
      hobby: false,
      driversLicence: false,
      interests: false,
    },
  ]);

  useEffect(() => {
    validate();
  }, [nextStep]);

  useEffect(() => {
    if (cvData.otherSkillsAndAccomplishments) return;

    setCvData((state) => ({
      ...state,
      otherSkillsAndAccomplishments: {
        hobby: "",
        driversLicence: "",
        maritalStatus: false,
        interests: "",
      },
    }));
  }, []);

  const handleChangeInput = (event) => {
    const { name, value } = event.target;

    setCvData((state) => {
      return {
        ...state,
        otherSkillsAndAccomplishments: {
          ...state.otherSkillsAndAccomplishments,
          [name]: value,
        },
      };
    });

    setErrors((state) => ({ ...state, [name]: false }));
  };

  const handleResponseSuccess = () => setActiveStep(FINISH_STEP);

  const validate = () => {
    if (nextStep !== FINISH_STEP) return;

    const newErrors = {
      hobby: !otherSkillsAndAccomplishments?.hobby.trim(),
      driversLicence: !otherSkillsAndAccomplishments?.driversLicence.trim(),
      interests: !otherSkillsAndAccomplishments?.interests.trim(),
    };

    const hasErrors = Object.values(newErrors).some((error) => error);

    if (hasErrors) {
      setErrors(newErrors);
      setNextStep(OTHER_SKILLS_STEP);
      return;
    }
    console.log(!isNaN(token));

    if (!isNaN(token)) {
      dispatch(
        setCreateCvReq({
          cvData: getCvDto(cvData),
          token,
          handleApiResponseSuccess: handleResponseSuccess,
        })
      );

      return;
    }

    dispatch(
      setRespondToCvInviteReq({
        cvData: getCvDto(cvData),
        token,
        handleApiResponseSuccess: handleResponseSuccess,
      })
    );
  };

  const getHelperText = (fieldName) => {
    return `${t(`cv.${fieldName}`)} ${t("cv.helperText")}`;
  };

  return (
    <Box className="cv-exp-form-main">
      <Box className="cv-form-control">
        <label>{t("cv.interests")}</label>
        <TextField
          name="interests"
          placeholder="ex. Science"
          helperText={errors.interests && getHelperText("interests")}
          error={errors.interests}
          value={otherSkillsAndAccomplishments?.interests}
          onChange={handleChangeInput}
        />
      </Box>
      <Box className="cv-form-control">
        <label>{t("cv.driversLicence")}</label>
        <TextField
          name="driversLicence"
          placeholder="ex. B Category"
          helperText={errors.driversLicence && getHelperText("driversLicence")}
          error={errors.driversLicence}
          value={otherSkillsAndAccomplishments?.driversLicence}
          onChange={handleChangeInput}
        />
      </Box>
      <label>{t("cv.maritalStatus")}</label>
      <RadioGroup
        row
        name="maritalStatus"
        defaultValue={false}
        value={otherSkillsAndAccomplishments?.maritalStatus}
        onChange={(event) =>
          setCvData((state) => ({
            ...state,
            otherSkillsAndAccomplishments: {
              ...state.otherSkillsAndAccomplishments,
              maritalStatus: event.target.value == "true",
            },
          }))
        }
      >
        <FormControlLabel
          value={false}
          control={<Radio />}
          label={t("cv.notMarried")}
        />
        <FormControlLabel
          value={true}
          control={<Radio />}
          label={t("cv.married")}
        />
      </RadioGroup>
      <Box className="cv-form-control" sx={{ mr: 3 }}>
        <label>{t("cv.hobby")}</label>
        <TextField
          name="hobby"
          placeholder="ex. Swimming, video and board games"
          helperText={errors.hobby && getHelperText("hobby")}
          error={errors.hobby}
          value={otherSkillsAndAccomplishments?.hobby}
          onChange={handleChangeInput}
        />
      </Box>
    </Box>
  );
};

CreateCvOtherSkillsAndAccomplishmentsStep.propTypes = {
  cvData: PropTypes.object,
  setCvData: PropTypes.func,
  nextStep: PropTypes.number,
  setNextStep: PropTypes.func,
  setActiveStep: PropTypes.func,
};

export default CreateCvOtherSkillsAndAccomplishmentsStep;
