import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import {
  CV_DEBOUNCE_INPUT_TIMEOUT,
  EDUCATION_STEP,
  EXPERIENCE_STEP,
  FINISH_STEP,
  OTHER_SKILLS_STEP,
  PROFILE_STEP,
  SKILLS_STEP,
  cvInitial,
  getCvDto,
  steps,
} from "../../constants/cv";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "../../hooks/useDebounce";
import { setCvPreviewReq } from "../../store/actions/cv/cvActions";
import { selectCvPreview } from "../../store/selectors/cvSelector";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CreateCvProfileStep from "./CreateCvProfileStep";
import CreateCvExperienceStep from "./CreateCvExperienceStep";
import CreateCvSkillsStep from "./CreateCvSkillsStep";
import CreateCvEducationStep from "./CreateCvEducationStep";
import CreateCvOtherSkillsAndAccomplishmentsStep from "./CreateCvOtherSkillsAndAccomplishmentsStep";
import CreateCvFinishScreen from "./CreateCvFinishScreen";
import PDFViewer from "./PDFViewer/PDFViewer";
import avatarIcon from "../../assets/images/Avatar.png";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { validateCvInviteReq } from "../../request/cvRequests";
import { NOT_FOUND_PAGE } from "../../constants/pages";
import { selectIsUserAuthenticated } from "../../store/selectors/userSelectors";
import { useLocation } from "react-router-dom";

const CreateCv = () => {
  const [activeStep, setActiveStep] = useState(PROFILE_STEP);
  const [nextStep, setNextStep] = useState(PROFILE_STEP);
  const [cvData, setCvData] = useState(cvInitial);
  const debouncedCvData = useDebounce(cvData, CV_DEBOUNCE_INPUT_TIMEOUT);
  const cvPreview = useSelector(selectCvPreview);
  const isUserAuthed = useSelector(selectIsUserAuthenticated);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useParams();
  const history = useHistory();
  const location = useLocation();
  const candidate = location.state?.candidate;

  useEffect(() => {
    if (isUserAuthed) {
      if (!candidate) return;

      setCvData((prev) => ({
        ...prev,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        email: candidate.email,
        phone: candidate.phoneNumber,
      }));

      return;
    }

    validateInvite();
  }, [isUserAuthed]);

  const validateInvite = async () => {
    validateCvInviteReq(token)
      .then((res) => {
        const applicant = res.data;
        setCvData((prev) => ({
          ...prev,
          firstName: applicant.firstName,
          lastName: applicant.lastName,
          email: applicant.email,
          phone: applicant.phoneNumber,
        }));
      })
      .catch(() => history.push(NOT_FOUND_PAGE));
  };

  useEffect(() => {
    dispatch(setCvPreviewReq(getCvDto(cvData)));
  }, [debouncedCvData]);

  const handleStepChange = (index) => {
    if (index === -1) {
      if (activeStep === PROFILE_STEP) return;

      setActiveStep((prev) => prev + index);
      setNextStep((prev) => prev + index);

      return;
    }

    setNextStep((prev) => prev + index);
  };

  const getNextBtnText = () => {
    switch (activeStep) {
      case OTHER_SKILLS_STEP:
        return t("cv.finish");
      case FINISH_STEP:
        return t("cv.submit");
      default:
        return t("common.continue");
    }
  };

  const renderStep = (step, StepComponent, optionalProps) => {
    if (activeStep === step)
      return (
        <StepComponent
          cvData={cvData}
          setCvData={setCvData}
          nextStep={nextStep}
          setNextStep={setNextStep}
          setActiveStep={setActiveStep}
          {...optionalProps}
        />
      );
  };

  return (
    <Container
      maxWidth={activeStep === FINISH_STEP ? "xl" : false}
      className="cv-container-main"
    >
      <Box
        sx={{
          width: activeStep === FINISH_STEP ? "100%" : "60%",
          paddingLeft: activeStep === FINISH_STEP ? 0 : "5rem",
        }}
      >
        <div className="l-t-rectangle"></div>
        <div className="cv-container-header-main">
          <div className="cv-container-header">
            <div className="cv-container-header-left">
              <div className="cv-container-header-left-image">
                <img src={avatarIcon} alt="plus" />
              </div>
              <div className="cv-container-header-left-image-title">
                <p>{t("cv.create")}</p>
              </div>
              <div className="cv-container-header-left-image-title-sub">
                <sub> | {t("cv.createDesc")}</sub>
              </div>
            </div>
          </div>
        </div>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{t(`cv.${label}`)}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box className="cv-container-main-content">
          {activeStep !== FINISH_STEP && (
            <Box className="cv-container-main-desc">
              <ErrorOutlineIcon sx={{ mr: 1, color: "#226cb0" }} />
              <Typography variant="body2">{t("cv.createSubDesc")}</Typography>
            </Box>
          )}
          {renderStep(PROFILE_STEP, CreateCvProfileStep)}
          {renderStep(EXPERIENCE_STEP, CreateCvExperienceStep)}
          {renderStep(SKILLS_STEP, CreateCvSkillsStep)}
          {renderStep(EDUCATION_STEP, CreateCvEducationStep)}
          {renderStep(
            OTHER_SKILLS_STEP,
            CreateCvOtherSkillsAndAccomplishmentsStep
          )}
          {renderStep(FINISH_STEP, CreateCvFinishScreen, { pdf: cvPreview })}
        </Box>
        {activeStep !== FINISH_STEP && (
          <Box className="cv-container-main-actions" sx={{ p: "1rem" }}>
            <button
              className="cv-back-btn"
              disabled={activeStep === PROFILE_STEP}
              onClick={() => handleStepChange(-1)}
            >
              {t("common.back")}
            </button>
            <button
              className={
                activeStep === OTHER_SKILLS_STEP || activeStep === FINISH_STEP
                  ? "cv-continue-btn-active"
                  : "cv-continue-btn"
              }
              variant="contained"
              onClick={() => handleStepChange(1)}
            >
              {getNextBtnText()}
            </button>
          </Box>
        )}
      </Box>
      {activeStep !== FINISH_STEP && (
        <Box className="cv-container-rightcol">
          <PDFViewer pdf={cvPreview} />
        </Box>
      )}
    </Container>
  );
};

export default CreateCv;
