import React from "react";
import PropTypes from "prop-types";
import x from "../../assets/images/x.png";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
  DialogContent,
} from "@mui/material";
import IconButton from "../IconButton/IconButton";
import { useTranslation } from "react-i18next";

const ConfirmDialog = ({
  title,
  subtitle,
  imgSrc,
  content,
  onConfirm,
  onClose,
  open,
  maxWidth,
  fullWidth,
  responsive,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={responsive && fullScreen}
      onClose={handleClose}
      open={open}
      style={{
        padding: "36px",
      }}
    >
      <div style={{ padding: "36px" }} data-testid="alert-container">
        <DialogTitle style={{ padding: 0 }}>
          {fullScreen ? (
            <>
              <div
                className="flex-center"
                style={{ justifyContent: "start" }}
                data-testid="full-screen-elem"
              >
                <img
                  style={{
                    position: "relative",
                    top: -0.25,
                    paddingRight: "10px",
                  }}
                  src={imgSrc}
                />
                <h5 style={{ textAlign: "start" }}>{title}</h5>
                <div style={{ justifySelf: "stretch", flex: "1" }}></div>
                <IconButton onClick={onClose}>
                  <img
                    style={{
                      position: "relative",
                      top: -0.25,
                    }}
                    src={x}
                  />
                </IconButton>
              </div>
              <p
                className="dialog-subtitle"
                style={{ paddingLeft: "23px", marginTop: "-10px" }}
                data-testid="full-screen-elem"
              >
                | {subtitle}
              </p>
            </>
          ) : (
            <div
              className="flex-center"
              data-testid="not-full-screen-elem"
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex-center" style={{ justifyContent: "start" }}>
                <img
                  style={{
                    position: "relative",
                    top: -0.25,
                    paddingRight: "10px",
                  }}
                  src={imgSrc}
                />
                <h5>{title}</h5>
                <div className="vr"></div>
                <p className="dialog-subtitle">{subtitle}</p>
              </div>
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <div className="modal-content" data-testid="modal-content">
            {content}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 0 }}>
          {!fullScreen ? (
            <IconButton
              className={`c-btn--primary-outlined  c-btn dialog-btn not-full-screen-btn`}
              onClick={onClose}
            >
              {t("common.cancel")}
            </IconButton>
          ) : (
            ""
          )}
          <IconButton
            className={`c-btn--primary-outlined sm-full c-btn dialog-btn`}
            onClick={onConfirm}
          >
            {t("common.confirm")}
          </IconButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  imgSrc: PropTypes.any,
  open: PropTypes.bool.isRequired,
  content: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  fullWidth: PropTypes.bool,
  responsive: PropTypes.bool,
};

export default ConfirmDialog;
