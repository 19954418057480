import {
  CREATE_PATTERN_ERR,
  CREATE_PATTERN_SUCCESS,
} from "../../actions/createPattern/createPatternActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  pattern: null,
  errorMessage: "",
};

export default createReducer(
  {
    [CREATE_PATTERN_SUCCESS]: setCreatePattern,
    [CREATE_PATTERN_ERR]: setCreatePatternErrorMessage,
  },
  initialState
);

function setCreatePattern(state, action) {
  return {
    ...state,
    pattern: action.payload,
  };
}

function setCreatePatternErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
