import {
  createFetchType,
  createSuccessType,
  createErrorType,
} from "../actionHelpers";

const CREATE_PATTERN_SCOPE = "CREATE_PATTERN";

export const CREATE_PATTERN_REQ = createFetchType(CREATE_PATTERN_SCOPE);
export const CREATE_PATTERN_ERR = createErrorType(CREATE_PATTERN_SCOPE);
export const CREATE_PATTERN_SUCCESS = createSuccessType(CREATE_PATTERN_SCOPE);
