import {
  SEND_CV_INVITE_ERR,
  SEND_CV_INVITE_SUCCESS,
} from "../../actions/cv/cvActionsConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  isSuccess: false,
  errorMessage: "",
};

export default createReducer(
  {
    [SEND_CV_INVITE_SUCCESS]: inviteSucceded,
    [SEND_CV_INVITE_ERR]: inviteFailed,
  },
  initialState
);

function inviteSucceded(state) {
  return {
    ...state,
    isSuccess: !state.isSuccess,
  };
}

function inviteFailed(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
