import React, { useState, useEffect } from "react";
import DayComponent from "../../components/Schedules/DayComponent";
import arrowRight from "../../assets/images/arrow_right.png";
import arrowLeft from "../../assets/images/arrow_left2.png";
import DayDetailsComponent from "../../components/Schedules/DayDetailsComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectSchedule } from "../../store/selectors/scheduleSelectors";
import { fetchSchedule } from "../../store/actions/schedule/scheduleActions";
import {
  getMonthString,
  getDayString,
  getFormatedDayOrMonth,
} from "../../util/helpers/dateHelpers";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isToday } from "date-fns";

const SchedulePage = ({ history }) => {
  const dispatch = useDispatch();
  const selectionProcesses = useSelector(selectSchedule);
  const [detailDialogShown, setDetailsDialogShown] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [numberOfDaysInMonth, setNumberOfDaysInMonth] = useState(
    new Date(currentYear, currentMonth + 1, 0).getDate()
  );
  const [currentlySelectedDate, setCurrentlySelectedDate] = useState("");
  const [currentlySelectedDay, setCurrentlySelectedDay] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchSchedule({ month: currentMonth + 1, year: currentYear }));
  }, [dispatch]);

  const handleCloseDialog = () => {
    setDetailsDialogShown(false);
  };

  const handleOpenDialog = (numberOfDay) => {
    setCurrentlySelectedDay(numberOfDay);
    setCurrentlySelectedDate(
      getFormatedDayOrMonth(numberOfDay) +
        "." +
        getFormatedDayOrMonth(currentMonth + 1) +
        "." +
        currentYear
    );
    setDetailsDialogShown(true);
  };

  const getSelectionProcessesForSpecificDay = (day) => {
    return (
      selectionProcesses &&
      selectionProcesses.filter(
        (k) =>
          new Date(k.date).getDate() === day &&
          new Date(k.date).getFullYear() === currentYear &&
          new Date(k.date).getMonth() === currentMonth
      )
    );
  };

  const goBackOneMonth = () => {
    dispatch(
      fetchSchedule({
        month: currentMonth - 1 === -1 ? 12 : currentMonth,
        year: currentMonth - 1 === -1 ? currentYear - 1 : currentYear,
      })
    );
    setNumberOfDaysInMonth(
      new Date(
        currentMonth - 1 === -1 ? currentYear - 1 : currentYear,
        currentMonth - 1 === -1 ? 12 : currentMonth,
        0
      ).getDate()
    );
    if (currentMonth - 1 === -1) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const goForwardOneMonth = () => {
    dispatch(
      fetchSchedule({
        month: currentMonth + 1 === 12 ? 1 : currentMonth + 2,
        year: currentMonth + 1 === 12 ? currentYear + 1 : currentYear,
      })
    );
    setNumberOfDaysInMonth(
      new Date(
        currentMonth + 1 === 12 ? currentYear + 1 : currentYear,
        currentMonth + 1 === 12 ? 1 : currentMonth + 2,
        0
      ).getDate()
    );
    if (currentMonth + 1 === 12) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const startEmptyCells = new Date(currentYear, currentMonth, 1).getDay() - 1;
  const gridCells = [];

  if (startEmptyCells === -1) {
    for (let i = 0; i < 6; i++) {
      gridCells.push(
        <DayComponent key={`placeholder-start-${i}`} isPlaceholder={true} />
      );
    }
  } else {
    for (let i = 0; i < startEmptyCells; i++) {
      gridCells.push(
        <DayComponent key={`placeholder-${i}`} isPlaceholder={true} />
      );
    }
  }

  for (let i = 0; i < numberOfDaysInMonth; i++) {
    const dateOfWeek = new Date(currentYear, currentMonth, i);
    const dayOfWeek = dateOfWeek.getDay();
    gridCells.push(
      <DayComponent
        key={i + 1}
        numberOfDay={i + 1}
        isToday={isToday(new Date(currentYear, currentMonth, i + 1))}
        nameOfDay={getDayString(dayOfWeek).toLowerCase().slice(0, 3)}
        interviews={getSelectionProcessesForSpecificDay(i)}
        onClick={() => handleOpenDialog(i)}
      />
    );
  }

  return (
    <div className="schedule-page-container pl-144 pt-36px">
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <DayDetailsComponent
        onClose={handleCloseDialog}
        open={detailDialogShown}
        selectedDate={currentlySelectedDate}
        selectionProcesses={getSelectionProcessesForSpecificDay(
          currentlySelectedDay
        )}
        numberOfDaysInMonth={numberOfDaysInMonth}
        setCurrentlySelected={setCurrentlySelectedDate}
        setCurrentlySelectedDay={setCurrentlySelectedDay}
        currentlySelectedDay={currentlySelectedDay}
        history={history}
      />
      <div>
        <p className="schedule-page-main-header">{t("schedule.planner")}</p>
        <p className="schedule-page-header">
          | {getMonthString(currentMonth)} {currentYear}
        </p>
      </div>
      <div className="schedule-page-arrows-container">
        <div className="schedule-page-arrow-container" onClick={goBackOneMonth}>
          <img src={arrowLeft} />
        </div>
        <div
          className="schedule-page-arrow-container"
          onClick={goForwardOneMonth}
        >
          <img src={arrowRight} />
        </div>
      </div>
      <div className="schedule-page-content-container">{gridCells}</div>
    </div>
  );
};

SchedulePage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default SchedulePage;
