import { getRequest, postRequest } from ".";
import apiEndpoints from "./apiEndpoints";

export const getAllLevels = () => getRequest(apiEndpoints.processes.allLevels);
export const doneProcess = (data) => postRequest(apiEndpoints.processes.doneProcess,data);
export const updateStatus = (data) => postRequest(apiEndpoints.processes.changeStatus,data);
export const updateInterviewer = (data) => postRequest(apiEndpoints.processes.changeInterviewer,data);
export const getProcessesOfApplicant = (id) => getRequest(`${apiEndpoints.processes.getApplicantProcesses}/${id}`);
export const getAllFilteredProcessesReq = (payload) => {
    let statusesQuery = "";
    for (let i = 0; i < payload.statuses.length; i++) {
      statusesQuery += `statuses=${payload.statuses[i]}&`;
    }

    if(payload.startAt && payload.startAt !== null)
    statusesQuery += `DateStart=${payload.startAt}`;

    if(payload.endAt && payload.endAt !== null)
    statusesQuery += `&DateEnd=${payload.endAt}`;

    

    return getRequest(
      apiEndpoints.processes.filteredLevels +
        "?" + 
        `${statusesQuery}`
    );
  };