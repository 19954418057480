import { CANDIDATE_OPTIONS_ERROR, CANDIDATE_OPTIONS_SUCCESS } from "../../actions/candidates/candidatesActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  options: [],
  errorMessage: "",
};

export default createReducer(
  {
    [CANDIDATE_OPTIONS_SUCCESS]: setOptions,
    [CANDIDATE_OPTIONS_ERROR]: setError,
  },
  initialState
);

function setOptions(state, action) {
  return {
    ...state,
    options: action.payload,
  };
}

function setError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
