import { createSelector } from "@reduxjs/toolkit";

export const applicantSelector = (state) => state.applicant;

export const selectApplicant = createSelector(
  applicantSelector,
  (state) => state.applicant
);

export const selectApplicantError = createSelector(
  applicantSelector,
  (state) => state.errorMessage
);
