import createReducer from "../../utils/createReducer";
import {
  APPLY_FOR_AD_SUCCESS,
  APPLY_FOR_AD_ERR,
} from "../../actions/applyForAd/applyForAdActionConstants";

const initialState = {
  errorMessage: "",
};

export default createReducer(
  {
    [APPLY_FOR_AD_SUCCESS]: setApplyForAdState,
    [APPLY_FOR_AD_ERR]: setApplyForAdError,
  },
  initialState
);

function setApplyForAdState(state) {
  return {
    ...state,
  };
}

function setApplyForAdError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
