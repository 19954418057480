import {
  SCREENING_TESTS_FETCH,
  SCREENING_TESTS_ERROR,
  SCREENING_TESTS_SUCCESS,
  CREATE_SCREENING_TEST,
  CREATE_SCREENING_TEST_ERROR,
  CREATE_SCREENING_TEST_SUCCESS,
} from "./screeningTestsActionConstants";

export const fetchScreeningTests = () => ({
  type: SCREENING_TESTS_FETCH,
});

export const fetchScreeningTestsError = (payload) => ({
  type: SCREENING_TESTS_ERROR,
  payload,
});

export const fetchScreeningTestsSuccess = (payload) => ({
  type: SCREENING_TESTS_SUCCESS,
  payload,
});

export const createScreeningTest = (payload) => ({
  type: CREATE_SCREENING_TEST,
  payload,
});

export const createScreeningTestError = (payload) => ({
  type: CREATE_SCREENING_TEST_ERROR,
  payload,
});

export const createScreeningTestSuccess = (payload) => ({
  type: CREATE_SCREENING_TEST_SUCCESS,
  payload,
});
