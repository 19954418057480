import {
  FETCH_FILTERED_PATTERNS_ERR,
  FETCH_FILTERED_PATTERNS_SUCCESS,
  FETCH_PATTERNS_ERR,
  FETCH_PATTERNS_SUCCESS,
} from "../../actions/patterns/patternsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  patterns: [],
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_PATTERNS_SUCCESS]: setStatePatterns,
    [FETCH_PATTERNS_ERR]: setPatternsErrorMessage,
    [FETCH_FILTERED_PATTERNS_SUCCESS]: setStateFilteredPatterns,
    [FETCH_FILTERED_PATTERNS_ERR]: setFilteredPatternsErrorMessage,
  },
  initialState
);

function setStatePatterns(state, action) {
  return {
    ...state,
    patterns: action.payload,
  };
}

function setPatternsErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setStateFilteredPatterns(state, action) {
  return {
    ...state,
    patterns: action.payload,
  };
}

function setFilteredPatternsErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
