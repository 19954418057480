import {
  CREATE_AD_SUCCESS,
  CREATE_AD_ERR,
} from "../../actions/createAd/createAdActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  ad: null,
  errorMessage: "",
};

export default createReducer(
  {
    [CREATE_AD_SUCCESS]: setStateCreateAd,
    [CREATE_AD_ERR]: setStateErrorMessage,
  },
  initialState
);

function setStateCreateAd(state, action) {
  return { ...state, ad: action.payload };
}

function setStateErrorMessage(state, action) {
  return { ...state, errorMessage: action.payload };
}
