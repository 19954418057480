import {
  ARCHIVE_ACTIVE_AD_REQ,
  ARCHIVE_ACTIVE_AD_ERR,
  ARCHIVE_ACTIVE_AD_SUCCESS,
} from "./archiveActiveAdActionConstants";

export const archiveActiveAdReq = (payload) => ({
  type: ARCHIVE_ACTIVE_AD_REQ,
  payload,
});

export const archiveActiveAdError = (payload) => ({
  type: ARCHIVE_ACTIVE_AD_ERR,
  payload,
});

export const archiveActiveAd = (payload) => ({
  type: ARCHIVE_ACTIVE_AD_SUCCESS,
  payload,
});
