import {
    PUT_PROCESS_ERR,
    PUT_PROCESS_SUCCESS
  } from "../../actions/processes/processesActionConstants";
  import createReducer from "../../utils/createReducer";
  
  const initialState = {
    doneProcess: false,
    errorMessage: "",
  };
  
  export default createReducer(
    {
      [PUT_PROCESS_SUCCESS]: setStateDoneProcess,
      [PUT_PROCESS_ERR]: setDoneProcessErrorMessage,
    },
    initialState
  );
  
  function setStateDoneProcess(state, action) {
    return {
      ...state,
      doneProcess: action.payload,
    };
  }
  
  function setDoneProcessErrorMessage(state, action) {
    return {
      ...state,
      errorMessage: action.payload,
    };
  }
  