import { getRequest, postRequest, putRequest } from ".";
import apiEndpoints from "./apiEndpoints";

export const getAllPatterns = () =>
  getRequest(apiEndpoints.patterns.allPatterns);
export const getPatternById = (id) =>
  getRequest(apiEndpoints.patterns.patternById.replace(":id", id));
export const getPatternApplicantsById = (id) =>
  getRequest(apiEndpoints.patterns.patternApplicantsById.replace(":id", id));
export const getFilteredPatterns = (payload) => {
  let selectionLevelsQuery = "";
  for (let i = 0; i < payload.selectionLevels.length; i++) {
    selectionLevelsQuery += `selectionLevels=${payload.selectionLevels[i]}&`;
  }
  return getRequest(
    apiEndpoints.patterns.filteredPatterns +
      `?fromDate=${
        payload.fromDate === null
          ? ""
          : new Date(payload.fromDate).toISOString()
      }&toDate=${
        payload.toDate === null ? "" : new Date(payload.toDate).toISOString()
      }&${selectionLevelsQuery}`
  );
};
export const createPatternRequest = (payload) =>
  postRequest(apiEndpoints.patterns.createPattern, payload);
export const updatePatternRequest = (payload) =>
  putRequest(
    apiEndpoints.patterns.updatePattern.replace(":id", payload.id),
    payload
  );
export const scheduleAppointmentRequest = (payload) =>
  postRequest(apiEndpoints.patterns.scheduleAppointment, payload);
