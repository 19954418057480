import {
  FETCH_AD_ERR,
  FETCH_AD_SUCCESS,
} from "../../actions/ad/adActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  ad: null,
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_AD_SUCCESS]: setStateAd,
    [FETCH_AD_ERR]: setStateErrorMessage,
  },
  initialState
);

function setStateAd(state, action) {
  return { ...state, ad: action.payload };
}

function setStateErrorMessage(state, action) {
  return { ...state, errorMessage: action.payload };
}
