import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Ad from "../../components/Ads/Ad";
import IconButton from "../../components/IconButton/IconButton";
import arrow_left from "../../assets/images/arrow_left.png";
import arrow_right from "../../assets/images/arrow_right.png";
import searchImage from "../../assets/images/search.png";
import { useTranslation } from "react-i18next";
import AdFilters from "../../components/Ads/AdFilters";
import { useDispatch } from "react-redux";
import {
  setAdsReq,
  setFilteredAdsReq,
} from "../../store/actions/ads/adsAction";
import { useSelector } from "react-redux";
import { selectAds } from "../../store/selectors/adsSelectors";
import { JOB_DETAILS_PAGE, CREATE_AD_PAGE } from "../../constants/pages";
import FilterButton from "../../components/Button/FilterButton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { selectArchiveAds } from "../../store/selectors/archiveAdsSelectors";
import { setArchiveAdsReq } from "../../store/actions/archiveAds/archiveAdsActions";
import noActiveAds from "../../assets/images/no_active_ads.png";
import { setTechnologiesReq } from "../../store/actions/technologies/technologiesActions";
import { selectTechnologies } from "../../store/selectors/technologiesSelectors";
import { useLocation } from "react-router-dom";
import Fade from "@mui/material/Fade";
import { FETCH_ADS_LOADING } from "../../store/actions/ads/adsActionConstants";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import ArchiveJob from '../../components/Ads/ArchiveJob'

const AdsPage = ({ history }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [toggleFiltersDrawer, setToggleFiltersDrawer] = useState(false);
  const [isSearchFieldVisible, setIsSearchFieldVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [tmp, setTmp] = useState(null);
  const ads = useSelector(selectAds);
  const technologies = useSelector(selectTechnologies);
  const archiveJobs = useSelector(selectArchiveAds);
  const activeAdsSliderRef = useRef();
  const archiveJobsSliderRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    dispatch(setTechnologiesReq());
    dispatch(setArchiveAdsReq());
  }, []);

  var slider1 = document.querySelector(".active-jobs-jobs-job .slick-track");
  var slider2 = document.querySelector(".archived-jobs-jobs-job .slick-track");

  useEffect(() => {
    if (slider1 && slider2) {
      setTmp(1);
    }
  }, [searchInput]);

  useEffect(() => {
    if (slider1 && slider2) {
      slider1.style.transform = null;
      slider2.style.transform = null;
    }
    setTmp(null);
  }, [tmp]);

  useEffect(() => {
    if (search) {
      // history.push("/ads");

      const searchParams = search.split("?")[1];
      const technologyParams = searchParams
        .split("&")
        .filter((x) => x.includes("technologies"));
      const technologies = [];
      technologyParams.forEach((p) => {
        const tech = p.split("=");
        technologies.push(tech[1]);
      });

      const params = new URLSearchParams(search);

      dispatch(
        setFilteredAdsReq({
          minExperience: params.get("minExperience"),
          maxExperience: params.get("maxExperience"),
          technologies,
          workHour: params.get("workHour"),
          employmentType: params.get("employmentType"),
        })
      );
    } else {
      dispatch(setAdsReq());
    }
  }, []);

  const handleToggleFiltersDrawer = () => {
    setToggleFiltersDrawer((oldState) => !oldState);
  };

  const createAd = () => {
    history.push(CREATE_AD_PAGE);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const activeAdsArrowLeftHandler = () => {
    activeAdsSliderRef.current.slickPrev();
  };

  const activeAdsArrowRightHandler = () => {
    activeAdsSliderRef.current.slickNext();
  };

  const archiveAdsArrowLeftHandler = () => {
    archiveJobsSliderRef.current.slickPrev();
  };

  const archiveAdsArrowRightHandler = () => {
    archiveJobsSliderRef.current.slickNext();
  };

  // const deleteFiltersHandler = () => {
  //   history.push("/ads");
  //   dispatch(setAdsReq());
  // };

  const getDummyAds = (len) => {
    const ads = [];

    for (let i = 0; i < 5 - len + 1; i++) {
      ads.push(<Ad key={i} className="hiddenAd" />);
    }

    return ads;
  };

  const getDummyArchiveJobs = (len) => {
    const ads = [];

    for (let i = 0; i < 5 - len + 1; i++) {
      ads.push(<ArchiveJob key={i} className="hiddenAd" />);
    }

    return ads;
  };

  const handleChangeVisibility = (state) => {
    setIsSearchFieldVisible(state);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const inputNormal = (
    <div>
      <input
        placeholder="Pretrazi..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className="jobs-search-field"
        onClick={stopPropagation}
        style={{ zIndex: 1000 }}
      />
    </div>
  );

  const inputResponsive = (
    <div>
      <input
        placeholder="Pretrazi..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className="jobs-search-field-responsive smaller"
        onClick={stopPropagation}
        style={{ zIndex: 1000 }}
      />
    </div>
  );
  const isLoading = useSelector(selectIsLoadingByActionType(FETCH_ADS_LOADING));

  return isLoading ? (
    <div>
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <div className="loader-container h-withHeader">
        <span>Loading</span>
      </div>
    </div>
  ) : (
    <div data-testid="jobs-page">
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      {/* <AdFilters /> */}
      <AdFilters
        open={toggleFiltersDrawer}
        handleClose={handleToggleFiltersDrawer}
        technologies={technologies}
      />
      <div style={{ postion: "absolute" }}>
        {!matches && (
          <>
            <Fade
              in={isSearchFieldVisible}
              timeout={500}
              className="jobs-page-search-by-title"
            >
              {inputNormal}
            </Fade>
            <Fade in={isSearchFieldVisible} timeout={500}>
              <div
                style={{
                  position: "absolute",
                  zIndex: 10000,
                  marginTop: 15,
                  right: "80px",
                }}
              >
                <img src={searchImage} />
              </div>
            </Fade>
          </>
        )}
        {matches && (
          <>
            <Fade in={isSearchFieldVisible} timeout={500}>
              {inputResponsive}
            </Fade>
            <Fade in={isSearchFieldVisible} timeout={500}>
              <div
                style={{
                  position: "absolute",
                  zIndex: 10000,
                  marginTop: 10,
                  right: "16px",
                }}
              >
                <img src={searchImage} />
              </div>
            </Fade>
          </>
        )}
      </div>
      <div className="jobs" onClick={() => handleChangeVisibility(false)}>
        {ads && ads.length > 0 && (
          <div className="active-jobs">
            <div className="active-jobs-header">
              <h1>{t("ads.activeAds")}</h1>
              <div className="active-jobs-header-buttons">
                <IconButton
                  className={
                    "c-btn--primary-outlined c-btn ads-page-btn userPageBtn ml-20px no-padding"
                  }
                  onClick={() => handleChangeVisibility(true)}
                >
                  {!matches && t("common.search")}
                  <img
                    style={{
                      position: "relative",
                      top: -0.25,
                      paddingLeft: matches ? "0px" : "10px",
                    }}
                    src={searchImage}
                  />
                </IconButton>
                <FilterButton onShowFilters={handleToggleFiltersDrawer} />
              </div>
            </div>
            <div className="active-jobs-jobs">
              <div className="active-jobs-jobs-a">
                {!matches && (
                  <div className="active-jobs-jobs-arrows">
                    <button onClick={activeAdsArrowLeftHandler}>
                      <img src={arrow_left} alt="arrow-left" />
                    </button>
                    {ads.length > 3 && (
                      <button onClick={activeAdsArrowRightHandler}>
                        <img src={arrow_right} alt="arrow-right" />
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className="active-jobs-jobs-job">
                <Slider
                  {...settings}
                  slidesToShow={3}
                  slidesToScroll={3}
                  style={{ width: "100%" }}
                  ref={activeAdsSliderRef}
                >
                  {ads
                    .filter((ad) =>
                      ad.title.toLowerCase().includes(searchInput.toLowerCase())
                    )
                    .map((ad, index) => (
                      <Ad
                        onShowAdDetails={() =>
                          history.push(JOB_DETAILS_PAGE.replace(":id", ad.id))
                        }
                        key={index}
                        title={ad.title}
                        minimumExperience={ad.minimumExperience}
                        createdAt={ad.createdAt}
                        expiredAt={ad.expiredAt}
                      />
                    ))}
                  {ads.length <= 3 && getDummyAds(ads.length)}
                </Slider>
              </div>
            </div>
          </div>
        )}
        {ads && ads.length > 0 && matches && (
          <div className="active-jobs-jobs-arrows">
            <button onClick={activeAdsArrowLeftHandler}>
              <img src={arrow_left} alt="arrow-left" />
            </button>
            {ads.length > 3 && (
              <button onClick={activeAdsArrowRightHandler}>
                <img src={arrow_right} alt="arrow-right" />
              </button>
            )}
          </div>
        )}
        {(!ads || ads.length === 0) && (
          <div className="active-jobs-jobs-no-jobs">
            <div className="active-jobs-jobs-no-jobs-filters">
              <FilterButton onShowFilters={handleToggleFiltersDrawer} />
            </div>
            <img src={noActiveAds} alt="noActiveAds" />
            <h1>{t("ads.thereIsNoAds")}</h1>
            <p>{t("ads.addAd")}</p>
            <div className="add-job add-job-no-jobs">
              <IconButton
                className="c-btn jobs-page-btn c-btn--primary add-job-btn"
                onClick={() => history.push(CREATE_AD_PAGE)}
              >
                {t("ads.adNewAd")}
              </IconButton>
            </div>
          </div>
        )}
        {archiveJobs && archiveJobs.length > 0 && (
          <div className="archived-jobs">
            <div className="archived-jobs-header">
              <h2>{t("ads.archiveAds")}</h2>
            </div>
            <div className="archived-jobs-jobs">
              {!matches && (
                <div className="archived-jobs-jobs-a">
                  <div className="archived-jobs-jobs-arrows">
                    <button onClick={archiveAdsArrowLeftHandler}>
                      <img src={arrow_left} alt="arrow-left" />
                    </button>
                    {archiveJobs.length > 5 && (
                      <button onClick={archiveAdsArrowRightHandler}>
                        <img src={arrow_right} alt="arrow-right" />
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div className="archived-jobs-jobs-job">
                <Slider
                  ref={archiveJobsSliderRef}
                  {...settings}
                  slidesToShow={5}
                  slidesToScroll={5}
                  style={{ width: "100%" }}
                >
                  {archiveJobs
                    .filter((ad) =>
                      ad.title.toLowerCase().includes(searchInput.toLowerCase())
                    )
                    .map((ad, index) => (
                      <ArchiveJob
                        key={index}
                        title={ad.title}
                        minimumExperience={ad.minimumExperience}
                        createdAt={ad.createdAt}
                        expiredAt={ad.expiredAt}
                        onShowAdDetails={() =>
                          history.push(JOB_DETAILS_PAGE.replace(":id", ad.id))
                        }
                      />
                    ))}
                  {archiveJobs.length <= 5 &&
                    getDummyArchiveJobs(archiveJobs.length)}
                </Slider>
              </div>
            </div>
            {matches && (
              <div className="active-jobs-jobs-arrows">
                <button onClick={archiveAdsArrowLeftHandler}>
                  <img src={arrow_left} alt="arrow-left" />
                </button>
                {archiveJobs.length > 5 && (
                  <button onClick={archiveAdsArrowRightHandler}>
                    <img src={arrow_right} alt="arrow-right" />
                  </button>
                )}
              </div>
            )}

            <div className="archive-jobs-no-active-jobs"></div>
          </div>
        )}
      </div>

      {ads && ads.length > 0 && (
        <div className="add-job">
          <IconButton
            className="c-btn ads-page-btn c-btn--primary add-job-btn"
            onClick={createAd}
          >
            + {t("ads.ad")}
          </IconButton>
        </div>
      )}
    </div>
  );
};

AdsPage.propTypes = {
  history: PropTypes.any,
};

export default AdsPage;
