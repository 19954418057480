import {
  createFetchType,
  createSuccessType,
  createErrorType,
} from "../actionHelpers";

const UPDATE_PATTERN_SCOPE = "UPDATE_PATTERN";

export const UPDATE_PATTERN_REQ = createFetchType(UPDATE_PATTERN_SCOPE);
export const UPDATE_PATTERN_ERR = createErrorType(UPDATE_PATTERN_SCOPE);
export const UPDATE_PATTERN_SUCCESS = createSuccessType(UPDATE_PATTERN_SCOPE);
