import {
  createClearType,
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
} from "../actionHelpers";

const FETCH_PROCESSES_SCOPE = "FETCH_PROCESSES";
export const FETCH_PROCESSES_REQ = createFetchType(FETCH_PROCESSES_SCOPE);
export const FETCH_PROCESSES_ERR = createErrorType(FETCH_PROCESSES_SCOPE);
export const FETCH_PROCESSES_SUCCESS = createSuccessType(FETCH_PROCESSES_SCOPE);
export const FETCH_PROCESSES_LOADING = createLoadingType(FETCH_PROCESSES_SCOPE);

export const FETCH_FILTERED_PROCESSES_REQ = createFetchType(
  FETCH_PROCESSES_SCOPE
);
export const FETCH_FILTERED_PROCESSES_LOADING = createLoadingType(
  FETCH_PROCESSES_SCOPE
);

const PUT_PROCESS_SCOPE = "PUT_PROCESS";
export const PUT_PROCESS_REQ = createFetchType(PUT_PROCESS_SCOPE);
export const PUT_PROCESS_SUCCESS = createSuccessType(PUT_PROCESS_SCOPE);
export const PUT_PROCESS_ERR = createErrorType(PUT_PROCESS_SCOPE);
export const CLEAR_PUT_PROCESS_ERR = createClearType(
  `${PUT_PROCESS_SCOPE}_ERR`
);
export const PUT_PROCESS_LOADING = createLoadingType(PUT_PROCESS_SCOPE);

export const FETCH_APPLICANT_PROCESSES_REQ = "FETCH_APPLICANT_PROCESSES_REQ";
export const FETCH_APPLICANT_PROCESSES_ERR = "FETCH_APPLICANT_PROCESSES_ERR";
export const FETCH_APPLICANT_PROCESSES_SUCCESS =
  "FETCH_APPLICANT_PROCESSES_SUCCESS";

export const FETCH_STATUSES_REQ = "FETCH_STATUSES_REQ";
export const FETCH_STATUSES_ERR = "FETCH_STATUSES_ERR";
export const FETCH_STATUSES_SUCCESS = "FETCH_STATUSES_SUCCESS";
export const CHANGE_STATUS_ISCHECKED_VALUE = "CHANGE_STATUS_ISCHECKED_VALUE";

export const FETCH_STATUS_CHANGE_REQ = "FETCH_STATUS_CHANGE_REQ";
export const FETCH_STATUS_CHANGE_ERR = "FETCH_STATUS_CHANGE_ERR";
export const FETCH_STATUS_CHANGE_SUCCESS = "FETCH_STATUS_CHANGE_SUCCESS";

export const FETCH_INTERVIEWER_CHANGE_REQ = "FETCH_INTERVIEWER_CHANGE_REQ";
export const FETCH_INTERVIEWER_CHANGE_ERR = "FETCH_INTERVIEWER_CHANGE_ERR";
export const FETCH_INTERVIEWER_CHANGE_SUCCESS =
  "FETCH_INTERVIEWER_CHANGE_SUCCESS";
