import { createSelector } from "@reduxjs/toolkit";

export const usersSelector = (state) => state.users;

// export const selectAuthUser = createSelector(
//   usersSelector,
//   (state) => state.user,
// );

export const selectUsers = createSelector(
  usersSelector,
  (state) => state.users
)