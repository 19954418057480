import {
  SCHEDULE_APPOINTMENT_REQ,
  SCHEDULE_APPOINTMENT_ERR,
  SCHEDULE_APPOINTMENT_SUCCESS,
  CLEAR_NOT_SENT_EMAILS_ARRAY,
} from "./scheduleAppointmentActionConstants";

export const scheduleAppointmentReq = (payload) => ({
  type: SCHEDULE_APPOINTMENT_REQ,
  payload,
});

export const scheduleAppointmentError = (payload) => ({
  type: SCHEDULE_APPOINTMENT_ERR,
  payload,
});

export const scheduleAppointment = (payload) => ({
  type: SCHEDULE_APPOINTMENT_SUCCESS,
  payload,
});

export const clearNotSentEmailsArray = () => ({
  type: CLEAR_NOT_SENT_EMAILS_ARRAY,
});
