import React, { useEffect } from "react";
import arrow_left from "../../assets/images/arrow_left.png";
import arrow_right from "../../assets/images/arrow_right.png";
import adImage from "../../assets/images/.net_icon.png";
import Slider from "react-slick";
import useDynamicRefs from "use-dynamic-refs";
import { fetchAdsCandidates } from "../../store/actions/candidates/candidatesActions";
import { useDispatch, useSelector } from "react-redux";
import CandidateCard from "../../components/Candidates/CandidateCard";
import PropTypes from "prop-types";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { selectAdsCandidates } from "../../store/selectors/candidatesSelectors";
import { useTranslation } from "react-i18next";

const AdsCandidatesPage = ({ history, search }) => {
  const dispatch = useDispatch();
  const adsCandidates = useSelector(selectAdsCandidates);
  const [getRef, setRef] = useDynamicRefs();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("361"));
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(
      fetchAdsCandidates({
        currentPage: 0,
        pageSize: 0,
        minExperience: 0,
        maxExperience: 0,
        employmentType: "",
        minDateOfApplication: "",
        maxDateOfApplication: "",
        technologies: [],
      })
    );
  }, [dispatch]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const getDummyCandidates = (len) => {
    const candidates = [];

    for (let i = 0; i < 4 - len + 1; i++) {
      candidates.push(
        <CandidateCard key={i} className="hiddenAd" history={history} />
      );
    }

    return candidates;
  };

  const activeAdsArrowLeftHandler = (index) => {
    getRef(index.toString()).current.slickPrev();
  };

  const activeAdsArrowRightHandler = (index) => {
    getRef(index.toString()).current.slickNext();
  };

  const filterByName = (adCandidates) => {
    return adCandidates.applicants.filter((candidate) =>
      (candidate.firstName + " " + candidate.lastName)
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  };

  return (
    <div className="jobs-candidates-container top-cnd">
      {adsCandidates.map((adCandidates, index) => (
        <div className="jobs-candidates" key={index}>
          <div className="jobs-candidates-top-container">
            <img src={adImage} className="jobs-candidates-image" />
            <p className="jobs-candidates-title">{adCandidates.title}</p>
            <p className="jobs-candidates-numberOfApplicants">
              | {adCandidates.nubmerOfApplicants} {t("ads.registered")}
            </p>
          </div>
          <div className="jobs-candidates-slider">
            {filterByName(adCandidates).length > 4 && (
              <div
                className="active-jobs-jobs-arrows"
                style={matches ? { marginLeft: 36 } : { marginLeft: 0 }}
              >
                <button onClick={() => activeAdsArrowLeftHandler(index)}>
                  <img src={arrow_left} alt="arrow-left" />
                </button>
                <button
                  onClick={() => activeAdsArrowRightHandler(index)}
                  className="kp"
                >
                  <img src={arrow_right} alt="arrow-right" />
                </button>
              </div>
            )}
            <Slider
              {...settings}
              ref={setRef(index.toString())}
              className={`left-move-candidateAd-page ${
                matches
                  ? filterByName(adCandidates).length > 4
                    ? "cls1"
                    : "cls2"
                  : filterByName(adCandidates).length > 4
                  ? "cls3"
                  : "cls4"
              }`}
            >
              {filterByName(adCandidates).map((candidate, index) => (
                <CandidateCard
                  key={index}
                  candidate={candidate}
                  history={history}
                />
              ))}
              {filterByName(adCandidates).length <= 4 &&
                getDummyCandidates(adCandidates.applicants.length)}
            </Slider>
          </div>
        </div>
      ))}
    </div>
  );
};

AdsCandidatesPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  search: PropTypes.string,
};

export default AdsCandidatesPage;
