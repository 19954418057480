import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { formatDate } from "../../util/helpers/dateHelpers";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CANDIDATES_PAGE } from "../../constants/pages";
import { filterCandidates } from "../../store/actions/candidates/candidatesActions";
import { PAGE_SIZE_CANDIDATES } from "../../constants/keyCodeConstants";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import {
  selectCandidates,
  selectPagination,
} from "../../store/selectors/candidatesSelectors";
import { getCV } from "../../request/candidatesRequest";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";

const TableViewPage = ({
  history,
  setPage,
  page,
  search,
  sliderValue,
  startingDate,
  endingDate,
  typesOfEmployments,
  technologies,
}) => {
  const dispatch = useDispatch();
  const candidates = useSelector(selectCandidates);
  const pagination = useSelector(selectPagination);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("361"));
  const [linkToCV, setLinkToCV] = useState("");
  const [isCVDisplayed, setIsCVDisplayed] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      filterCandidates({
        currentPage: page,
        pageSize: PAGE_SIZE_CANDIDATES,
        minExperience: 0,
        maxExperience: 0,
        employmentType: "",
        minDateOfApplication: "",
        maxDateOfApplication: "",
        technologies: [],
      })
    );
  }, [dispatch]);

  const getSelectedEmploymentType = () => {
    return typesOfEmployments.filter((e) => e.isChecked === true);
  };

  const selectedEmploymentType = getSelectedEmploymentType();

  const handleChange = (_, value) => {
    dispatch(
      filterCandidates({
        currentPage: value,
        pageSize: PAGE_SIZE_CANDIDATES,
        minExperience: sliderValue[0],
        maxExperience: sliderValue[1],
        employmentType:
          selectedEmploymentType && selectedEmploymentType.length === 0
            ? ""
            : selectedEmploymentType[0].name,
        minDateOfApplication: startingDate,
        maxDateOfApplication: endingDate,
        technologies: technologies
          .filter((tech) => tech.isChecked === true)
          .map((tech) => tech.name),
      })
    );
    setPage(value);
  };

  const formatLabel = (string, value) => {
    if (!value) {
      return string;
    }
    return (
      <span>
        {string.split(value).reduce((prev, current, i) => {
          if (!i) {
            return [current];
          }
          return prev.concat(
            <b className="highlighted" key={value + current}>
              {value}
            </b>,
            current
          );
        }, [])}
      </span>
    );
  };

  const onClickCV = (e, fileName) => {
    e.stopPropagation();
    getCV(fileName)
      .then((res) => {
        setLinkToCV(res.data);
        setIsCVDisplayed(true);
      })
      .catch((e) => console.log(e));
  };

  const navigateToCandidateDetailsPage = (applicantId) => {
    history.push({
      pathname: CANDIDATES_PAGE + "/" + applicantId,
    });
  };

  const filterCandidatesByName = () => {
    return candidates.filter((n) =>
      (n.firstName + " " + n.lastName)
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  };

  return (
    <div className="candidates-table">
      <div
        style={{
          overflowX: "auto",
          marginLeft: matches ? 36 : 72,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <table
          className="usersTable"
          style={{
            width: isCVDisplayed ? "784px" : "914px",
            height: "fit-content",
          }}
        >
          <thead>
            <tr className="headingRow">
              <th>
                {t("common.firstName")} {t("common.and")} {t("common.lastName")}
              </th>
              {!isCVDisplayed && <th>{t("candidates.experience")}</th>}
              <th>{t("filters.dateOfApplication")}</th>
              <th>{t("candidates.position")}</th>
              <th>CV link</th>
            </tr>
          </thead>
          <tbody>
            {candidates &&
              filterCandidatesByName().map((candidate, index) => (
                <tr
                  key={index}
                  className="secondaryRow cadidate-row"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigateToCandidateDetailsPage(candidate.applicantId)
                  }
                >
                  <td>
                    {(candidate.firstName + " " + candidate.lastName).includes(
                      search
                    ) ? (
                      formatLabel(
                        candidate.firstName + " " + candidate.lastName,
                        search
                      )
                    ) : (
                      <span>
                        {candidate.firstName + " " + candidate.lastName}
                      </span>
                    )}
                  </td>
                  {!isCVDisplayed && (
                    <td style={{ paddingLeft: 80 }}>{candidate.experience}</td>
                  )}
                  <td style={{ paddingLeft: 55 }}>
                    {formatDate(candidate.dateOfApplication)}
                  </td>
                  <td>{candidate.position}</td>
                  <td>
                    <span
                      onClick={(e) => onClickCV(e, candidate.cv)}
                      className="cvLink"
                    >
                      {candidate.firstName}
                      {candidate.lastName}.pdf
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Fade in={isCVDisplayed} timeout={400}>
          <embed
            src={`data:application/pdf;base64,${linkToCV}`}
            className="candidates-cv"
          />
        </Fade>
      </div>
      {search.length > 0 ? (
        ""
      ) : (
        <Pagination
          size={matches ? "small" : "medium"}
          count={
            parseInt(pagination) <= PAGE_SIZE_CANDIDATES
              ? 1
              : Math.ceil(parseInt(pagination) / PAGE_SIZE_CANDIDATES)
          }
          color="primary"
          className="candidates-pagination"
          onChange={handleChange}
          shape="rounded"
        />
      )}
    </div>
  );
};

TableViewPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  setPage: PropTypes.func,
  page: PropTypes.number,
  search: PropTypes.string,
  sliderValue: PropTypes.array,
  startingDate: PropTypes.string,
  endingDate: PropTypes.string,
  typesOfEmployments: PropTypes.array,
  technologies: PropTypes.array,
};

export default TableViewPage;
