/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import HrLogo from "../../assets/images/hrcenter.png";
import jwt_decode from "jwt-decode";
import {
  clearLoginErrors,
  fetchUser,
  fetchGoogleUser,
} from "../../store/actions/login/loginActions";
import { selectLoginError } from "../../store/selectors/loginSelectors";
import {
  ADS_PAGE,
  FORGOT_PASSWORD_PAGE,
  HOME_PAGE,
} from "../../constants/pages";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Backdrop from "../../components/MUI/BackdropComponent";
import ErrorMessage from "../../components/MUI/ErrorMessageComponent";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import {
  LOGIN_USER_LOADING,
  LOGIN_GOOGLE_USER_LOADING,
} from "../../store/actions/login/loginActionConstants";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { JWT_TOKEN } from "../../constants/localStorage";
import Step from "../../components/Registration/Step";
import { FormContext, FormProvider } from "../../context/FormContext";
import { selectIsUserAuthenticated } from "../../store/selectors/userSelectors";

const RegisterPage = ({ history }) => {
  //   const dispatch = useDispatch();
  const { t } = useTranslation();
  //   const error = useSelector(selectLoginError);
  const { search } = useLocation();

  const { activeStepIndex } = useContext(FormContext);

  const [showPassword, setShowPassword] = useState(false);
  const [firstDone, setFirst] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleCallbackResponse = (response) => {
    const userObject = jwt_decode(response.credential);
    const user = userObject;
    const token = response.credential;
    dispatch(clearLoginErrors());
    dispatch(
      fetchGoogleUser({
        user,
        token,
        handleApiResponseSuccess,
      })
    );
  };

  const isLoading = useSelector(
    selectIsLoadingByActionType(LOGIN_USER_LOADING)
  );

  const isGoogleLoading = useSelector(
    selectIsLoadingByActionType(LOGIN_GOOGLE_USER_LOADING)
  );

  const isUserAuthenticated = useSelector(selectIsUserAuthenticated);

  useEffect(() => {
    if (isInit) {
      setIsInit(false);
      return;
    }

    if (!isUserAuthenticated) {
      history.push({ pathname: ADS_PAGE });
    }
  }, [isInit]);

  return (
    <>
      <Container
        component="main"
        className="c-login-container"
        fullwidth="true"
      >
        <div className="l-t-rectangle"></div>
        <div className="r-b-rectangle"></div>
        <Box
          sx={{
            marginTop: 2,
            width: 289,
            height: 684,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={HrLogo} className="login-logo" />
          {search.split("&")[1]?.split("=")[1] ? (
            <>
              <Typography variant="h5" sx={{ m: 1, mt: 3 }}>
                {t("login.welcome")}
              </Typography>
              <Typography variant="p" sx={{ mb: 2 }}>
                {t("registration.twoSteps")}
              </Typography>
              <div className="steps-cont">
                <div
                  className={`step ${
                    activeStepIndex && activeStepIndex === 1
                      ? "done"
                      : "current"
                  }`}
                >
                  1
                </div>
                <div className="line-btw"></div>
                <div
                  className={`step ${
                    activeStepIndex && activeStepIndex === 1
                      ? "current"
                      : "disallowed"
                  }`}
                >
                  2
                </div>
              </div>
              <Step />
            </>
          ) : (
            <h3 data-testid='error-h' style={{ margin: "50px 0px" }}>{t("registration.mistake")}</h3>
          )}
        </Box>
      </Container>
    </>
  );
};

RegisterPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
export default RegisterPage;
