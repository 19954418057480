import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAllLevels,
  doneProcess,
  getProcessesOfApplicant,
  getAllFilteredProcessesReq,
  updateStatus,
  updateInterviewer,
} from "../../request/processesReguest";
import {
  setProcesses,
  setProcessesError,
} from "../actions/processes/processesAction";
import { addHeaderToken } from "../../request";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { JWT_TOKEN } from "../../constants/localStorage";
import {
  setDoneProcess,
  setDoneProcessError,
  setUpdateStatusErr,
  setUpdateStatusSucc,
  setUpdateInterviewerSucc,
  setUpdateInterviewerErr,
} from "../actions/processes/processAction";
import {
  setApplicant,
  setApplicantError,
} from "../actions/processes/applicantAction";
import {
  FETCH_PROCESSES_REQ,
  FETCH_FILTERED_PROCESSES_REQ,
  PUT_PROCESS_REQ,
  FETCH_APPLICANT_PROCESSES_REQ,
  FETCH_STATUS_CHANGE_REQ,
  FETCH_INTERVIEWER_CHANGE_REQ,
} from "../actions/processes/processesActionConstants";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";

export function* getProcesses() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAllLevels);
    yield put(setProcesses(result.data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(setProcessesError(errorMessage));
    }
  }
}

export function* getFilteredProcesses(payload) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAllFilteredProcessesReq, payload.payload);
    yield put(setProcesses(result.data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(setProcessesError(errorMessage));
    }
  }
}

export function* finishProcess(payload) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const model = payload.payload;
    const result = yield call(doneProcess, model);
    yield put(setDoneProcess(result.data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(setDoneProcessError(errorMessage));
    }
  }
}

export function* getApplicantProcesses(payload) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const id = payload.payload;
    const { data } = yield call(getProcessesOfApplicant, id);
    yield put(setApplicant(data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(setApplicantError(errorMessage));
    }
  }
}

export function* changeStatus({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    yield call(updateStatus, payload.data);
    yield put(setUpdateStatusSucc());
    if (payload.responseHandler) {
      yield call(payload.responseHandler);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(setUpdateStatusErr(errorMessage));
    }
  }
}

export function* changeInterviewer({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    yield call(updateInterviewer, payload.data);
    yield put(setUpdateInterviewerSucc());
    if (payload.responseHandler) {
      yield call(payload.responseHandler);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(setUpdateInterviewerErr(errorMessage));
    }
  }
}

export default function* processesSaga() {
  yield all([takeLatest(FETCH_PROCESSES_REQ, getProcesses)]);
  yield all([takeLatest(FETCH_INTERVIEWER_CHANGE_REQ, changeInterviewer)]);
  yield all([takeLatest(FETCH_STATUS_CHANGE_REQ, changeStatus)]);
  yield all([takeLatest(FETCH_FILTERED_PROCESSES_REQ, getFilteredProcesses)]);
  yield all([takeLatest(PUT_PROCESS_REQ, finishProcess)]);
  yield all([takeLatest(FETCH_APPLICANT_PROCESSES_REQ, getApplicantProcesses)]);
}
