import {
  FETCH_ADD_AD_TECHNOLOGIES_REQ,
  FETCH_ADD_AD_TECHNOLOGIES_ERR,
  FETCH_ADD_AD_TECHNOLOGIES_SUCCESS,
  CHANGE_ISCHECKED_VALUE_ADD_AD,
  RESET_ISCHECKED_VALUE_ADD_AD,
} from "./addAdTechnologiesActionConstants";

export const setTechnologiesAddAdReq = () => ({
  type: FETCH_ADD_AD_TECHNOLOGIES_REQ,
});

export const setTechnologiesAddAdError = (payload) => ({
  type: FETCH_ADD_AD_TECHNOLOGIES_ERR,
  payload,
});

export const setTechnologiesAddAd = (payload) => ({
  type: FETCH_ADD_AD_TECHNOLOGIES_SUCCESS,
  payload,
});

export const changeIsCheckedAddAdValue = (payload) => ({
  type: CHANGE_ISCHECKED_VALUE_ADD_AD,
  payload,
});

export const resetIsCheckedAddAdValue = () => ({
  type: RESET_ISCHECKED_VALUE_ADD_AD,
});
