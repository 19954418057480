import {
  createFetchType,
  createSuccessType,
  createErrorType,
} from "../actionHelpers";

const FETCH_PATTERNS_SCOPE = "FETCH_PATTERNS";
const FILTER_PATTERNS_SCOPE = "FILTER_PATTERNS";

export const FETCH_PATTERNS_REQ = createFetchType(FETCH_PATTERNS_SCOPE);
export const FETCH_PATTERNS_ERR = createErrorType(FETCH_PATTERNS_SCOPE);
export const FETCH_PATTERNS_SUCCESS = createSuccessType(FETCH_PATTERNS_SCOPE);

export const FETCH_FILTERED_PATTERNS_REQ = createFetchType(
  FILTER_PATTERNS_SCOPE
);
export const FETCH_FILTERED_PATTERNS_ERR = createErrorType(
  FILTER_PATTERNS_SCOPE
);
export const FETCH_FILTERED_PATTERNS_SUCCESS = createSuccessType(
  FILTER_PATTERNS_SCOPE
);
