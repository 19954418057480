import {
  createFetchType,
  createSuccessType,
  createErrorType,
} from "../actionHelpers";

const ARCHIVE_ACTIVE_AD_SCOPE = "ARCHIVE_ACTIVE_AD";

export const ARCHIVE_ACTIVE_AD_REQ = createFetchType(ARCHIVE_ACTIVE_AD_SCOPE);
export const ARCHIVE_ACTIVE_AD_ERR = createErrorType(ARCHIVE_ACTIVE_AD_SCOPE);
export const ARCHIVE_ACTIVE_AD_SUCCESS = createSuccessType(
  ARCHIVE_ACTIVE_AD_SCOPE
);
