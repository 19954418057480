import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  IconButton,
  TextField,
  Typography,
  Grid,
  Switch,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExperienceProjectsFrom from "./ExperienceProjectsFrom";
import xIcon from "../../../assets/images/x.png";

const ExperienceForm = ({
  expField,
  expIndex,
  cvData,
  setCvData,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const expErrors = errors[expIndex];

  const handleChangeInput = (index, event) => {
    const experiences = [...cvData.experiences];
    experiences[index][event.target.name] = event.target.value;

    setCvData((state) => ({ ...state, experiences }));

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[expIndex] = {
        ...newErrors[expIndex],
        [event.target.name]: false,
      };
      return newErrors;
    });
  };

  const handleSetIsFreelance = () => {
    setCvData((prevState) => {
      const experiences = [...prevState.experiences];
      experiences[expIndex] = {
        ...experiences[expIndex],
        isFreelance: !experiences[expIndex].isFreelance,
        positionName: !experiences[expIndex].isFreelance ? "Freelance" : "",
      };

      return { ...prevState, experiences };
    });
  };

  const handleRemoveFields = (index) => {
    const experiences = [...cvData.experiences];

    experiences.splice(index, 1);
    setCvData((state) => ({ ...state, experiences }));
  };

  const getHelperText = (fieldName) => {
    return `${t(`cv.${fieldName}`)} ${t("cv.helperText")}`;
  };

  return (
    <Box className="cv-exp-form-main">
      <Box className="cv-exp-form-header">
        <Typography sx={{ mb: 2 }}>
          {`${t("cv.experienceSm")} ${expIndex + 1}`}
        </Typography>
        <Box sx={{ pr: 1 }} onClick={() => handleRemoveFields(expIndex)}>
          <IconButton>
            <img alt="x" src={xIcon} />
          </IconButton>
        </Box>
      </Box>
      <Grid
        container
        component="label"
        alignItems="center"
        spacing={1}
        sx={{ pb: 1 }}
      >
        <Grid item>{t("cv.job")}</Grid>
        <Grid item>
          <Switch
            checked={expField.isFreelance}
            onClick={handleSetIsFreelance}
          />
        </Grid>
        <Grid item>Freelance</Grid>
      </Grid>
      <Box className="cv-exp-form-row">
        <Box className="cv-exp-form-left-col" sx={{ mr: 3 }}>
          <Box className="cv-form-control">
            <label>{t("cv.positionName")}</label>
            <TextField
              name="positionName"
              disabled={expField.isFreelance}
              size="small"
              placeholder="ex. Software Developer"
              helperText={
                expErrors?.positionName && getHelperText("positionName")
              }
              error={expErrors?.positionName}
              value={expField.positionName}
              onChange={(event) => handleChangeInput(expIndex, event)}
            />
          </Box>
          {!expField.isFreelance && (
            <Box className="cv-form-control">
              <label>{t("cv.companyName")}</label>
              <TextField
                name="companyName"
                size="small"
                placeholder="ex. Microsoft"
                helperText={
                  expErrors?.companyName && getHelperText("companyName")
                }
                error={expErrors?.companyName}
                value={expField.companyName}
                onChange={(event) => handleChangeInput(expIndex, event)}
              />
            </Box>
          )}
        </Box>
        <Box className="cv-exp-form-left-col">
          <Box className="cv-exp-form-row">
            <Box className="cv-form-control" sx={{ mr: 3 }}>
              <label>{t("cv.startDate")}</label>
              <TextField
                name="startDate"
                type="date"
                size="small"
                inputProps={{
                  max: expField.endDate,
                }}
                helperText={expErrors?.startDate && getHelperText("startDate")}
                error={expErrors?.startDate}
                value={expField.startDate}
                onChange={(event) => handleChangeInput(expIndex, event)}
              />
            </Box>
            <Box className="cv-form-control">
              <label>{t("cv.endDate")}</label>
              <TextField
                name="endDate"
                inputProps={{
                  min: expField.startDate,
                }}
                type="date"
                size="small"
                value={expField.endDate}
                onChange={(event) => handleChangeInput(expIndex, event)}
              />
            </Box>
          </Box>
          {!expField.isFreelance && (
            <Box className="cv-exp-form-row">
              <Box className="cv-form-control" sx={{ mr: 3 }}>
                <label>{t("cv.companyCity")}</label>
                <TextField
                  name="companyCity"
                  size="small"
                  placeholder="ex. Niš"
                  helperText={
                    expErrors?.companyCity && getHelperText("companyCity")
                  }
                  error={expErrors?.companyCity}
                  value={expField.companyCity}
                  onChange={(event) => handleChangeInput(expIndex, event)}
                />
              </Box>
              <Box className="cv-form-control">
                <label>{t("cv.companyCountry")}</label>
                <TextField
                  name="companyCountry"
                  size="small"
                  placeholder="ex. Serbia"
                  helperText={
                    expErrors?.companyCountry && getHelperText("companyCountry")
                  }
                  error={expErrors?.companyCountry}
                  value={expField.companyCountry}
                  onChange={(event) => handleChangeInput(expIndex, event)}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Typography fontSize={14} sx={{ mt: 1 }}>
        {`${t("cv.projects")}:`}
      </Typography>
      <Divider />
      {cvData.experiences[expIndex].projects.map((field, index) => (
        <div key={index}>
          <ExperienceProjectsFrom
            index={index}
            expIndex={expIndex}
            field={field}
            cvData={cvData}
            setCvData={setCvData}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      ))}
    </Box>
  );
};

ExperienceForm.propTypes = {
  expIndex: PropTypes.number,
  expField: PropTypes.object,
  cvData: PropTypes.object,
  setCvData: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};

export default ExperienceForm;
