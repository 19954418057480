import React, { useState } from "react";
import PropTypes from "prop-types";
import { selectDoneProcessError } from "../../store/selectors/processSelectors";
import { selectAuthUser } from "../../store/selectors/userSelectors";
import { setDoneProcessReq } from "../../store/actions/processes/processAction";
import { useDispatch, useSelector } from "react-redux";
// import { formatDateSrb, formatTimeSrb } from "../../util/helpers/dateHelpers";
import { SELECTION_PROCESS_OF_APPLICANT_PAGE } from "../../constants/pages";
import { PUT_PROCESS_LOADING } from "../../store/actions/processes/processesActionConstants";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import Backdrop from "../../components/MUI/BackdropComponent";
import { IconButton } from "@mui/material";
import plus from "../../assets/images/plus.png";
import SelectionCard from "./SelectionCard";
import { useTranslation } from "react-i18next";

const Selection = (props) => {
  const [over, setOver] = useState(false);
  const allApplicants = props.selection.selectionProcesses;
  const errorMessage = useSelector(selectDoneProcessError);
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const { t } = useTranslation();

  const dropItem = (e, selId) => {
    setOver(false);
    var data = e.dataTransfer.getData("text/plain");
    const selectionProcess = JSON.parse(data);

    if (
      selectionProcess.selectionLevelId < selId &&
      selectionProcess.status !== "Neuspešno"
    ) {
      dispatch(
        setDoneProcessReq({
          id: selectionProcess.id,
          name: "Some random name",
          applicantId: selectionProcess.applicant.applicantId,
          schedulerId: user.id,
        })
      );
    }

    if (errorMessage) {
      console.log(errorMessage);
    }
  };

  const dragStart = (e, applicant) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(applicant));
  };

  const dragOver = (e) => {
    e.preventDefault();
    setOver(true);
  };

  const isLoading = useSelector(
    selectIsLoadingByActionType(PUT_PROCESS_LOADING)
  );

  const handleOpenDetails = (id) => {
    props.history.push(SELECTION_PROCESS_OF_APPLICANT_PAGE.replace(":id", id));
  };

  const applicants = allApplicants?.filter(
    (a) => a.status !== "Odrađen" || a.selectionLevelId === 4
  );

  const renderList = applicants?.map((item, index) => {
    // Prikaz liste kandidata i statusa procesa
    return (
      <SelectionCard
        key={index}
        item={item}
        dragStart={(e) => dragStart(e, item)}
        click={() => handleOpenDetails(item.applicant.applicantId)} // Prikaz Flow-a za odabranog
        // kandidata
      />
    );
  });

  return (
    <div
      data-testid="selection-level"
      dropppable="true"
      id={props.selection.id}
      className={`selection-card ${over ? "over" : ""}`}
      onDragOver={(e) => dragOver(e)}
      onDragLeave={() => setOver(false)}
      onDrop={(e) => dropItem(e, props.selection.id)}
    >
      <div className="selection-card-title">
        <h3 style={{ marginRight: "50px" }}>
          {props.selection.name === "HR intervju" && t("processes.hr")}
          {props.selection.name === "Screening test" && t("processes.st")}
          {props.selection.name === "Tehnicki intervju" && t("processes.ti")}
          {props.selection.name === "Konacna odluka" && t("processes.fd")}
        </h3>
        {props.order === 0 ? (
          <IconButton
            sx={{ marginRight: "35px" }}
            className={`c-btn--primary-outlined c-btn td-btn`}
            onClick={props.modalEvent}
          >
            <img
              style={{
                position: "relative",
              }}
              src={plus}
              data-testid="interview-image"
            />
          </IconButton>
        ) : (
          ""
        )}
      </div>
      <Backdrop position="absolute" isLoading={isLoading} />
      {applicants &&
        applicants !== null &&
        applicants?.length > 0 &&
        renderList}
      {applicants && applicants !== null && applicants?.length === 0 && (
        <div className="sel-item-no-data">
          <div className="date">
            <p data-testid="empty-selection" style={{ width: "240px" }}>
              {t("selection.noCandidates")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

Selection.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  order: PropTypes.number,
  modalEvent: PropTypes.func,
  selection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    selectionProcesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        date: PropTypes.string,
        status: PropTypes.string,
        currentSelection: PropTypes.number,
        map: PropTypes.func,
        applicant: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      })
    ),
  }),
};

export default Selection;
