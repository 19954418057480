import createReducer from "../../utils/createReducer";
import {
  FETCH_INTERVIEWER_CHANGE_ERR,
  FETCH_INTERVIEWER_CHANGE_REQ,
  FETCH_INTERVIEWER_CHANGE_SUCCESS,
} from "../../actions/processes/processesActionConstants";

const initialState = {
  updateSuccess: false,
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_INTERVIEWER_CHANGE_REQ]: interviewerUpdateRequest,
    [FETCH_INTERVIEWER_CHANGE_ERR]: setStateErrorMessage,
    [FETCH_INTERVIEWER_CHANGE_SUCCESS]: interviewerUpdateSuccess,
  },
  initialState
);

function interviewerUpdateSuccess(state) {
    return { ...state, updateSuccess: true };
  }
  function interviewerUpdateRequest(state) {
    return { ...state, updateSuccess: false };
  }

function setStateErrorMessage(state, action) {
  return { ...state, errorMessage: action.payload };
}
