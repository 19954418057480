import { createSelector } from "@reduxjs/toolkit";

export const doneProcessSelector = (state) => state;

export const selectDoneProcess = createSelector(
  doneProcessSelector,
  (state) => state
);

export const selectDoneProcessError = createSelector(
  doneProcessSelector,
  (state) => state.errorMessage
);
