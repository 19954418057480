import { CANDIDATE_INIT_PROCESS_ERROR, CANDIDATE_INIT_PROCESS_SUCCESS} from "../../actions/candidates/candidatesActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  isSuccess: false,
  errorMessage: "",
};

export default createReducer(
  {
    [CANDIDATE_INIT_PROCESS_SUCCESS]: setSuccess,
    [CANDIDATE_INIT_PROCESS_ERROR]: setError,
  },
  initialState
);

function setSuccess(state) {
  return {
    ...state,
    isSuccess: !state.isSuccess
  };
}

function setError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
