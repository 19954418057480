import { createSelector } from "@reduxjs/toolkit";

export const scheduleAppointmentSelector = (state) => state.scheduleAppointment;

export const selectNotSentEmails = createSelector(
  scheduleAppointmentSelector,
  (state) => state.notSentEmails
);

export const selectScheduleAppointmentError = createSelector(
  scheduleAppointmentSelector,
  (state) => state.errorMessage
);
