import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, IconButton, TextField, Typography } from "@mui/material";
import xIcon from "../../../assets/images/x.png";
import TechnologyForm from "./TechnologyForm";
import PropTypes from "prop-types";

const SkillsForm = ({
  skillField,
  skillIndex,
  cvData,
  setCvData,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const skillErrors = errors[skillIndex];

  const handleChangeInput = (index, event) => {
    const skills = [...cvData.skills];
    skills[index][event.target.name] = event.target.value;

    setCvData((state) => ({ ...state, skills }));

    const updatedErrors = [...errors];
    updatedErrors[index].techStack = false;
    setErrors(updatedErrors);
  };

  const handleRemoveFields = (index) => {
    const skills = [...cvData.skills];

    if (skills.length === 1) return;

    skills.splice(index, 1);

    setCvData((state) => ({ ...state, skills }));
  };

  return (
    <Box className="cv-skills-form-main">
      <Box className="cv-exp-form-header">
        <Typography sx={{ mb: 2 }}>
          {`${t("cv.skill")} ${skillIndex + 1}`}
        </Typography>
        {skillIndex !== 0 && (
          <Box sx={{ pr: 1 }}>
            <IconButton onClick={() => handleRemoveFields(skillIndex)}>
              <img alt="x" src={xIcon} />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box className="cv-form-control">
        <label>{t("cv.techStack")}</label>
        <TextField
          name="techStack"
          placeholder="ex. Microsoft .NET/C#"
          size="small"
          sx={{ width: "40rem" }}
          helperText={
            skillErrors?.techStack &&
            `${t(`cv.techStack`)} ${t("cv.helperText")}`
          }
          error={skillErrors?.techStack}
          value={skillField.techStack}
          onChange={(event) => handleChangeInput(skillIndex, event)}
        />
      </Box>
      <Typography fontSize={14} sx={{ mt: 1 }}>
        {`${t("cv.techsAndToolsTitle")}:`}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {cvData.skills[skillIndex].techs.map((field, index) => (
        <div key={index}>
          <TechnologyForm
            field={field}
            index={index}
            skillIndex={skillIndex}
            cvData={cvData}
            setCvData={setCvData}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      ))}
    </Box>
  );
};

SkillsForm.propTypes = {
  skillIndex: PropTypes.number,
  skillField: PropTypes.object,
  cvData: PropTypes.object,
  setCvData: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};

export default SkillsForm;
