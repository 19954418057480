import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton } from "@mui/material";
import { EXPERIENCE_STEP, SKILLS_STEP } from "../../constants/cv";
import ExperienceForm from "./Forms/ExperienceForm";
import plusIcon from "../../assets/images/plus.png";
import PropTypes from "prop-types";

const CreateCvExperienceStep = ({
  cvData,
  setCvData,
  nextStep,
  setNextStep,
  setActiveStep,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (!cvData.experiences) {
      setCvData((state) => ({ ...state, experiences: [] }));
      return;
    }

    if (cvData.experiences) {
      const newErrors = cvData.experiences.flatMap((experience) => {
        const experienceErrors = {
          positionName: false,
          companyName: false,
          companyCity: false,
          companyCountry: false,
          startDate: false,
          projects: [],
        };

        if (experience.projects) {
          experienceErrors.projects = experience.projects.map(() => ({
            name: false,
            description: false,
          }));
        }

        return [experienceErrors];
      });

      setErrors(newErrors);
    }
  }, [cvData]);

  useEffect(() => {
    validate();
  }, [nextStep]);

  const handleAddFields = () => {
    setCvData((state) => ({
      ...state,
      experiences: [
        ...state.experiences,
        {
          positionName: "",
          companyName: "",
          companyCity: "",
          companyCountry: "",
          startDate: null,
          endDate: null,
          isFreelance: false,
          projects: [
            {
              name: "",
              description: "",
            },
          ],
        },
      ],
    }));

    setErrors((prev) => [
      ...prev,
      {
        positionName: false,
        companyName: false,
        companyCity: false,
        companyCountry: false,
        startDate: false,
        projects: [
          {
            name: false,
            description: false,
          },
        ],
      },
    ]);
  };

  const validate = () => {
    if (nextStep !== SKILLS_STEP) return;

    const newErrors = cvData.experiences.map((experience) => ({
      positionName: !experience.positionName.trim(),
      companyName: !experience.isFreelance && !experience.companyName.trim(),
      companyCity: !experience.isFreelance && !experience.companyCity.trim(),
      companyCountry:
        !experience.isFreelance && !experience.companyCountry.trim(),
      startDate: !experience.startDate,
      projects: experience.projects.map((project) => ({
        name: !project.name.trim(),
        description: !project.description.trim(),
      })),
    }));

    const hasErrors = newErrors.some((experience) => {
      return (
        Object.values(experience).some((value) => value === true) ||
        experience.projects.some((project) =>
          Object.values(project).some((value) => value === true)
        )
      );
    });

    if (hasErrors) {
      setErrors(newErrors);
      setNextStep(EXPERIENCE_STEP);
      return;
    }

    setActiveStep(SKILLS_STEP);
  };

  return (
    <>
      {cvData.experiences?.map((field, index) => (
        <div key={index}>
          <ExperienceForm
            expField={field}
            expIndex={index}
            cvData={cvData}
            setCvData={setCvData}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      ))}
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <IconButton
          className={"c-btn--primary-outlined c-btn"}
          onClick={handleAddFields}
          sx={{ mb: 10 }}
        >
          <img
            src={plusIcon}
            width={15}
            height={15}
            style={{ marginRight: "10px" }}
          />
          {t("cv.experienceSm")}
        </IconButton>
      </Box>
    </>
  );
};

CreateCvExperienceStep.propTypes = {
  cvData: PropTypes.object,
  setCvData: PropTypes.func,
  nextStep: PropTypes.number,
  setNextStep: PropTypes.func,
  setActiveStep: PropTypes.func,
};

export default CreateCvExperienceStep;
