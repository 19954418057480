import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus.png";
import {
  resetIsCheckedAddAdValue,
  setTechnologiesAddAdReq,
} from "../../store/actions/addAdTechnologies/addAdTechnologiesActions";
import { setCreateAdReq } from "../../store/actions/createAd/createAdActions";
import CreateAdFirstStep from "./CreateAdFirstStep";
import CreateAdSecondStep from "./CreateAdSecondStep";
import CreateAdThirdStep from "./CreateAdThirdStep";
import { useHistory } from "react-router-dom";
import { ADS_PAGE } from "../../constants/pages";
import { useTranslation } from "react-i18next";

const CreateAdPage = () => {
  const [stage, setStage] = useState(1);
  const [title, setTitle] = useState("");
  const [employmentType, setEmploymentType] = useState("Work");
  const [workHour, setWorkHour] = useState("FullTime");
  const [expiredAt, setExpiredAt] = useState("");
  const [experience, setExperience] = useState(0);
  const childRef = useRef();
  const history = useHistory();
  const { t } = useTranslation();

  const technologies = useSelector(
    (state) => state.addAdTechnologies.technologies
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTechnologiesAddAdReq());
  }, []);

  const createAd = (keyResponsibilities, requirements, offer) => {
    const onSuccessAddAd = () => {
      setStage(1);
      setTitle("");
      setEmploymentType("Work");
      setWorkHour("FullTime");
      setExpiredAt("");
      setExperience(0);
      dispatch(resetIsCheckedAddAdValue());
      history.push(ADS_PAGE);
    };

    const technologiesIds = technologies
      .filter((x) => x.isChecked === true)
      .map((x) => x.technologyId);

    dispatch(
      setCreateAdReq({
        title,
        minimumExperience: experience,
        createdAt: new Date(),
        expiredAt,
        keyResponsibilities,
        requirements,
        offer,
        workHour,
        employmentType,
        technologiesIds,
        onSuccessAddAd,
      })
    );
  };

  const backClickHandler = () => {
    if (stage === 1) {
      return;
    }

    setStage((oldState) => oldState - 1);
  };

  const forwardClickHandler = () => {
    if (stage === 1 && (title === "" || expiredAt === "")) {
      return;
    }

    if (stage === 2) {
      const checkedTechnologies = technologies.filter(
        (x) => x.isChecked === true
      );
      if (checkedTechnologies.length === 0) {
        return;
      }
    }

    if (stage === 3) {
      const {
        keyResponsibilities: k,
        requirements: r,
        offer: o,
      } = childRef.current();

      if (k === "" || r === "" || o === "") return;

      createAd(k, r, o);
    }

    if (stage < 3) {
      setStage((oldState) => oldState + 1);
      return;
    }
  };

  return (
    <div className="create-job-page" data-testid="create-job-page">
      <div className="create-job-page-content">
        <div className="create-job-page-content-header">
          <img
            src={plusIcon}
            alt="plus"
            style={{ width: "18px", height: "18px" }}
          />
          <h2>{t("ads.adding")}</h2>
          <h2>
            <sub> | {t("ads.ad")}</sub>
          </h2>
        </div>
        <div className="create-job-steps">
          {stage === 1 && (
            <CreateAdFirstStep
              title={title}
              setTitle={setTitle}
              employmentType={employmentType}
              setEmploymentType={setEmploymentType}
              workHour={workHour}
              setWorkHour={setWorkHour}
              expiredAt={expiredAt}
              setExpiredAt={setExpiredAt}
            />
          )}
          {stage === 2 && (
            <CreateAdSecondStep
              technologies={technologies}
              experience={experience}
              setExperience={setExperience}
            />
          )}
          {stage === 3 && <CreateAdThirdStep childRef={childRef} />}
        </div>
        <div className="create-job-buttons" style={{ marginBottom: "2rem" }}>
          <button
            className="create-job-buttons-back"
            disabled={stage === 1}
            onClick={backClickHandler}
          >
            {t("common.back")}
          </button>
          <button
            className="create-job-buttons-forward"
            onClick={forwardClickHandler}
          >
            {t("common.continue")}
          </button>
        </div>
      </div>
      <div className="create-ad-go-back">
        <button onClick={() => history.push(ADS_PAGE)}>
          {t("ads.backToAds")}
        </button>
      </div>
    </div>
  );
};

export default CreateAdPage;
