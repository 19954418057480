import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SkillsForm from "./Forms/SkillsForm";
import { useTranslation } from "react-i18next";
import { Box, IconButton } from "@mui/material";
import plusIcon from "../../assets/images/plus.png";
import { EDUCATION_STEP, SKILLS_STEP } from "../../constants/cv";

const CreateCvSkillsStep = ({
  cvData,
  setCvData,
  nextStep,
  setNextStep,
  setActiveStep,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (cvData.skills.length > 0) {
      const newErrors = cvData.skills.flatMap((skill) => {
        const skillErrors = {
          techStack: false,
          techs: [],
        };

        if (skill.techs) {
          skillErrors.techs = skill.techs.map(() => ({
            techOrTool: false,
          }));
        }

        return [skillErrors];
      });

      setErrors(newErrors);
    } else {
      handleAddFields();
    }
  }, [cvData]);

  useEffect(() => {
    validate();
  }, [nextStep]);

  const handleAddFields = () => {
    setCvData((state) => ({
      ...state,
      skills: [
        ...state.skills,
        {
          techStack: "",
          techs: [
            {
              techOrTool: "",
              yearsExperience: 0,
            },
          ],
        },
      ],
    }));

    setErrors((prevErrors) => [
      ...prevErrors,
      {
        techStack: false,
        techs: [{ techOrTool: false }],
      },
    ]);
  };

  const validate = () => {
    if (nextStep !== EDUCATION_STEP) return;

    const newErrors = cvData.skills.map((field) => {
      const skillErrors = {
        techStack: !field.techStack.trim(),
        techs: field.techs.map((tech) => ({
          techOrTool: !tech.techOrTool.trim(),
        })),
      };
      return skillErrors;
    });

    const hasErrors = newErrors.some((skill) => {
      return (
        Object.values(skill).some((value) => value === true) ||
        skill.techs.some((tech) =>
          Object.values(tech).some((value) => value === true)
        )
      );
    });

    if (hasErrors) {
      setErrors(newErrors);
      setNextStep(SKILLS_STEP);
      return;
    }

    setActiveStep(EDUCATION_STEP);
  };

  return (
    <>
      {cvData.skills.map((field, index) => (
        <div key={index}>
          <SkillsForm
            skillField={field}
            skillIndex={index}
            cvData={cvData}
            setCvData={setCvData}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      ))}
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <IconButton
          sx={{ mb: 10 }}
          className={"c-btn--primary-outlined c-btn"}
          onClick={handleAddFields}
        >
          <img
            src={plusIcon}
            width={12}
            height={12}
            style={{ marginRight: "10px" }}
          />
          {t("cv.skill")}
        </IconButton>
      </Box>
    </>
  );
};

CreateCvSkillsStep.propTypes = {
  cvData: PropTypes.object,
  setCvData: PropTypes.func,
  nextStep: PropTypes.number,
  setNextStep: PropTypes.func,
  setActiveStep: PropTypes.func,
};

export default CreateCvSkillsStep;
