import {
  FETCH_AD_REQ,
  FETCH_AD_ERR,
  FETCH_AD_SUCCESS,
} from "./adActionConstants";

export const setAdReq = (payload) => ({
  type: FETCH_AD_REQ,
  payload,
});

export const setAdError = (payload) => ({
  type: FETCH_AD_ERR,
  payload,
});

export const setAd = (payload) => ({
  type: FETCH_AD_SUCCESS,
  payload,
});
