import { createSelector } from "@reduxjs/toolkit";

export const technologiesSelector = (state) => state.technologies;

export const selectTechnologies = createSelector(
  technologiesSelector,
  (state) => state.technologies
);

export const selectTechnologiesError = createSelector(
  technologiesSelector,
  (state) => state.errorMessage
);
