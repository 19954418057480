import React, { useState } from "react";
import IconButton from "../../components/IconButton/IconButton";
import planeVector from "../../assets/images/planeVector.png";
import lock from "../../assets/images/lock.png";
import PropTypes from "prop-types";
import forbiden from "../../assets/images/forbiden.png";
import searchImage from "../../assets/images/search.png";
import x from "../../assets/images/x.png";
import edit from "../../assets/images/edit.png";
import { useEffect } from "react";
import addUser from "../../assets/images/addUser.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setEnableUsersReq,
  setUsersReq,
} from "../../store/actions/users/usersActions";
import { useTheme } from "@mui/system";
import { Fade, useMediaQuery } from "@mui/material";
import InviteDialog from "../../components/MUI/InviteDialog";
import { forgetPassword } from "../../store/actions/login/loginActions";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../components/MUI/ConfirmDialog";
import EditButton from "../../components/Button/EditButton";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { FETCH_USERS_LOADING } from "../../store/actions/users/usersActionConstants";

const UsersPage = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { users } = useSelector((s) => s.users);

  const [showInvite, setShowInvite] = useState(false);
  const [editEnable, setEdit] = useState(false);
  const [search, setSearch] = useState("");
  const [chosen, setChosen] = useState(null);
  const [showConfirm, setConfirm] = useState(false);
  const [showReset, setReset] = useState(false);
  const [isSearchFieldVisible, setIsSearchFieldVisible] = useState(false);

  const { t } = useTranslation();

  const handleChangeVisibility = () => {
    setIsSearchFieldVisible((s) => !s);
  };

  useEffect(() => {
    dispatch(setUsersReq());
  }, [dispatch]);

  const disableHandler = (id) => {
    dispatch(
      setEnableUsersReq({
        id,
        handleApiResponseSuccess: handleApiResponseSuccessEnable,
      })
    );
  };

  const handleReset = (email) => {
    dispatch(
      forgetPassword({
        email,
        handleApiResponseSuccess: handleApiResponseSuccessReset,
      })
    );
  };

  const handleApiResponseSuccessReset = () => {
    setReset(false);
  };

  const handleApiResponseSuccessEnable = () => {
    setConfirm(false);
  };

  const formatLabel = (string, value) => {
    if (!value) {
      return string;
    }
    return (
      <span>
        {string.split(value).reduce((prev, current, i) => {
          if (!i) {
            return [current];
          }
          return prev.concat(
            <b className="highlighted" key={value + current}>
              {value}
            </b>,
            current
          );
        }, [])}
      </span>
    );
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const inputNormal = (
    <div>
      <input
        placeholder={`${t("filters.search")}`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="ads-search-field"
        onClick={stopPropagation}
        style={{ zIndex: 1000 }}
      />
    </div>
  );

  const inputResponsive = (
    <div>
      <input
        placeholder="Pretrazi..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="ads-search-field-responsive smaller"
        onClick={stopPropagation}
        style={{ zIndex: 1000, width: "300px" }}
      />
    </div>
  );

  const isLoading = useSelector(
    selectIsLoadingByActionType(FETCH_USERS_LOADING)
  );

  return isLoading ? (
    <div>
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <div className="loader-container h-withHeader">
        <span>Loading</span>
      </div>
    </div>
  ) : (
    <div data-testid="users">
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <div style={{ postion: "absolute" }}>
        {!matches && (
          <>
            <Fade
              in={isSearchFieldVisible}
              timeout={500}
              className="jobs-page-search-by-title"
            >
              {inputNormal}
            </Fade>
            <Fade in={isSearchFieldVisible} timeout={500}>
              <div
                style={{
                  position: "absolute",
                  zIndex: 10000,
                  marginTop: 52.5,
                  right: "87.5px",
                }}
                className="search-input-users"
              >
                <img src={searchImage} />
              </div>
            </Fade>
          </>
        )}
        {matches && (
          <>
            <Fade in={isSearchFieldVisible} timeout={500}>
              {inputResponsive}
            </Fade>
            <Fade in={isSearchFieldVisible} timeout={500}>
              <div
                style={{
                  position: "absolute",
                  zIndex: 10000,
                  marginTop: 47.5,
                  right: "26px",
                }}
              >
                <img src={searchImage} />
              </div>
            </Fade>
          </>
        )}
      </div>
      <ConfirmDialog
        open={showConfirm}
        title={t("users.disableUser")}
        subtitle={chosen?.firstName + " " + chosen?.lastName}
        imgSrc={forbiden}
        content={t("users.questionDisableUser")}
        onClose={() => {
          setConfirm(false);
        }}
        onConfirm={() => {
          disableHandler(chosen.id); // setConfirm(false)
        }}
      />
      <ConfirmDialog
        open={showReset}
        title={t("users.resetPassword")}
        subtitle={chosen?.firstName + " " + chosen?.lastName}
        imgSrc={lock}
        content={t("users.resetLink")}
        onClose={() => {
          setReset(false);
        }}
        onConfirm={() => {
          handleReset(chosen.email); // setConfirm(false)
        }}
      />
      <InviteDialog
        open={showInvite}
        onClose={() => {
          setShowInvite(false);
        }}
        title={
          <div
            className="flex-center"
            data-testid="invite-dialog"
            style={{ justifyContent: "space-between" }}
          >
            <div className="flex-center" style={{ justifyContent: "start" }}>
              <img
                style={{
                  position: "relative",
                  top: -0.25,
                  paddingRight: "10px",
                }}
                src={addUser}
              />
              <h5>{t("users.inviteUser")}</h5>
              {!matches && <div className="vr"></div>}
              {!matches && (
                <p className="dialog-subtitle">{t("users.regLink")}</p>
              )}
            </div>
            <IconButton onClick={() => setShowInvite(false)}>
              <img
                style={{
                  position: "relative",
                  top: -0.25,
                }}
                src={x}
              />
            </IconButton>
          </div>
        }
      />
      <div>
        <div
          onClick={() => setIsSearchFieldVisible(false)}
          className="pl-144 flex-center"
          style={{
            paddingTop: "36px",
            justifyContent: "space-between",
            postion: "relative",
          }}
        >
          <h1 className="page-heading">{t("users.management")}</h1>
          <div className="flex-center">
            {/* <button></button> */}
            <EditButton
              onEnableEdit={() => {
                setEdit((s) => !s);
              }}
            />
            <IconButton
              className={
                "c-btn--primary-outlined c-btn ads-page-btn userPageBtn ml-20px no-padding"
              }
              onClick={handleChangeVisibility}
            >
              {!matches && t("candidates.search")}

              <img
                style={{
                  position: "relative",
                  top: -0.25,
                  paddingLeft: matches ? "0px" : "10px",
                }}
                src={searchImage}
              />
            </IconButton>
            <IconButton
              className={"c-btn--primary c-btn inviteBtn"}
              onClick={() => {
                setShowInvite(true);
              }}
            >
              {t("users.invite")}
              <img
                style={{
                  position: "relative",
                  top: 1.25,
                  paddingLeft: "15px",
                }}
                src={planeVector}
              />{" "}
            </IconButton>
          </div>
        </div>
        <div
          className="pl-144"
          onClick={() => setIsSearchFieldVisible(false)}
          style={{
            display: "flex",
            marginTop: "39px",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "500px",
          }}
        >
          <div className="table-cont">
            <table
              className={
                editEnable ? "usersTable-users normal" : "usersTable-users mini"
              } // style={{ width: "893.56px" }}
            >
              <thead>
                <tr className="headingRow">
                  <th>{t("users.fullName")}</th>
                  <th>E-mail</th>
                  <th>{t("users.position")}</th>
                  {editEnable && <th></th>}
                </tr>
              </thead>

              <tbody>
                {users
                  .filter((n) =>
                    (n.firstName + " " + n.lastName)
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((n) => (
                    <tr key={n.id} className="secondaryRow">
                      <td>
                        {(n.firstName + " " + n.lastName).includes(search) ? (
                          formatLabel(n.firstName + " " + n.lastName, search)
                        ) : (
                          <span>{n.firstName + " " + n.lastName}</span>
                        )}
                      </td>
                      <td>{n.email}</td>
                      <td className="profession">{n.position}</td>
                      {editEnable && (
                        <td data-testid="edit-row" className="edit-row">
                          <>
                            <IconButton
                              className={`c-btn--primary-outlined c-btn td-btn`}
                              onClick={() => {
                                setChosen(n);
                                setReset(true);
                              }}
                            >
                              <img
                                style={{
                                  position: "relative",
                                }}
                                src={lock}
                              />
                            </IconButton>
                            <IconButton
                              className={`c-btn--primary-outlined c-btn td-btn ${
                                n.isEnabled ? "active" : "inactive"
                              }`}
                              onClick={() => {
                                setChosen(n);
                                setConfirm(true);
                              }}
                            >
                              <img
                                style={{
                                  position: "relative",
                                }}
                                src={forbiden}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                props.history.push(`/users/${n.id}`);
                              }}
                              className={"c-btn--primary-outlined c-btn td-btn"}
                            >
                              <img
                                style={{
                                  position: "relative",
                                }}
                                src={edit}
                              />
                            </IconButton>
                          </>
                        </td>
                      )}{" "}
                    </tr>
                  ))}{" "}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "35px",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
UsersPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
export default UsersPage;
