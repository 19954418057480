import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAllAds,
  getAdDetailsById,
  getAllArchiveAds,
  getAllFilteredAds,
  createNewAd,
  archiveActiveAdRequest,
} from "../../request/adsRequest";
import {
  setAds,
  setAdsError,
  setFilteredAds,
  setFilteredAdsError,
} from "../actions/ads/adsAction";
import { setAd, setAdError } from "../actions/ad/adActions";
import {
  setArchiveAds,
  setArchiveAdsError,
} from "../actions/archiveAds/archiveAdsActions";
import { FETCH_ADS_REQ } from "../actions/ads/adsActionConstants";
import { FETCH_FILTERED_ADS_REQ } from "../actions/ads/adsActionConstants";
import { FETCH_AD_REQ } from "../actions/ad/adActionConstants";
import { FETCH_ARCHIVE_ADS_REQ } from "../actions/archiveAds/archiveAdsActionConstants";
import { CREATE_AD_REQ } from "../actions/createAd/createAdActionConstants";
import { ARCHIVE_ACTIVE_AD_REQ } from "../actions/archiveActiveAd/archiveActiveAdActionConstants";
import {
  setCreateAd,
  setCreateAdError,
} from "../actions/createAd/createAdActions";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import {
  archiveActiveAd,
  archiveActiveAdError,
} from "../actions/archiveActiveAd/archiveActiveAdActions";

export function* getAds() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAllAds);
    yield put(setAds(result.data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setAdsError(errorMessage));
  }
}

export function* getFilteredAds({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAllFilteredAds, payload);
    yield put(setFilteredAds(result.data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setFilteredAdsError(errorMessage));
  }
}

export function* getAd({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAdDetailsById, payload.id);
    yield put(setAd(result.data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setAdError(errorMessage));
  }
}

export function* getArchiveAds() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAllArchiveAds);
    yield put(setArchiveAds(result.data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setArchiveAdsError(errorMessage));
  }
}

export function* createAd({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(createNewAd, payload);
    const ad = result.data;
    yield put(setCreateAd(ad));
    const resultAds = yield call(getAllAds);
    yield put(setAds(resultAds.data));
    const resultArchiveAds = yield call(getAllArchiveAds);
    yield put(setArchiveAds(resultArchiveAds.data));
    if (payload.onSuccessAddAd) {
      yield call(payload.onSuccessAddAd);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setCreateAdError(errorMessage));
  }
}

export function* archiveActiveAdSaga({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const { data } = yield call(archiveActiveAdRequest, payload);
    yield put(archiveActiveAd(data));
    const resultAds = yield call(getAllAds);
    yield put(setAds(resultAds.data));
    const resultArchiveAds = yield call(getAllArchiveAds);
    yield put(setArchiveAds(resultArchiveAds.data));
    if (payload.navigateToAds) {
      yield call(payload.navigateToAds);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(archiveActiveAdError(errorMessage));
  }
}

export default function* adsSaga() {
  yield all([
    takeLatest(FETCH_ADS_REQ, getAds),
    takeLatest(FETCH_FILTERED_ADS_REQ, getFilteredAds),
    takeLatest(FETCH_AD_REQ, getAd),
    takeLatest(FETCH_ARCHIVE_ADS_REQ, getArchiveAds),
    takeLatest(CREATE_AD_REQ, createAd),
    takeLatest(ARCHIVE_ACTIVE_AD_REQ, archiveActiveAdSaga),
  ]);
}
