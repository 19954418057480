import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formatDateSrb, formatTimeSrb } from "../../util/helpers/dateHelpers";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { SelectionContext } from "../../context/SelectionContext";
import { useDispatch, useSelector } from "react-redux";
import {
  setDoneProcessReq,
  // setUpdateStatusReq,
} from "../../store/actions/processes/processAction";
import { useTranslation } from "react-i18next";
// import Button from "../Button/Button";

// const options = ["Zakazan", "Odrađen", "Čeka na zakazivanje", "Neuspešno"];

const SelectionCard = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [selected, setSelected] = useState(props.item.status);
  const { t } = useTranslation();

  const options = [
    t("processStatus.scheduled"), // Zakazan
    t("processStatus.finished"), // Odradjen
    t("processStatus.waitingAppointment"), // Ceka na zakazivanje
    t("processStatus.unsuccesfully"), // Neuspesno
  ];

  const optionsFinalDecision = [
    t("processStatus.finished"), // Odradjen
    t("processStatus.unsuccesfully"), // Neuspesno
  ];

  const { success } = useSelector((s) => s.statusUpdate);
  const {
    setActiveProcess,
    setActiveInterview,
    setActiveProcessUnsuccess,
    // activeProcessUnsuccess,
  } = useContext(SelectionContext);
  const dispatch = useDispatch();

  const statusChange = (e) => {
    if (props.item.status !== "Odrađen") {
      e.stopPropagation();
      setShowForm(true);
    }
  };

  useEffect(() => {
    setShowForm(false);
  }, [success]);

  const select = (e) => {
    e.stopPropagation();
    if (e.target.value === t("processStatus.scheduled")) {
      // Izmena - "Zakazan"
      // setovanje context state-a
      setActiveProcess({ process: props.item, status: "Zakazan" });
    }
    // poseban blok u slucaju da treba prikazati odredjeni modal kada je izabrano 'NEUSPESNO'
    else if (e.target.value === t("processStatus.unsuccesfully")) {
      // Izmena - "Neuspešno"
      setActiveProcessUnsuccess(props.item);
    } else if (e.target.value === t("processStatus.finished")) {
      // Izmena - "Odrađen"
      // ukoliko nije zadnji nivo selekcije kreirati proces za sledeci nivo
      // u suprotnom samo promeniti status u odradjeno
      if (props.item.selectionLevelId !== 4)
        dispatch(
          setDoneProcessReq({
            id: props.item.id,
            name: "Some random name",
            applicantId: props.item.applicant.applicantId,
          })
        );
      else {
        // Prikazati dijalog gde se moze dodati komentar
        setActiveProcess({ process: props.item, status: "Odrađen" });

        // // pozvati nasu custom metodu za promenu statusa bez prebacivanja u veci nivo
        // // promeni status u odradjeno
        // dispatch(
        //   setUpdateStatusReq({
        //     data: {
        //       newStatus: "Odrađen",
        //       processId: props.item.id,
        //     },
        //   })
        // );
      }
    }
    setSelected(e.target.value);
  };

  const clickHandler = () => {
    if (showForm) {
      setSelected(props.item.status);
      setShowForm(false);
    } else props.click();
  };

  const changeInterviewerHandler = (e) => {
    e.stopPropagation();
    setActiveInterview(props.item);
  };

  return (
    <div
      draggable
      className="sel-item"
      onDragStart={props.dragStart}
      onClick={clickHandler}
      data-testid="sel-card"
    >
      {" "}
      <div
        className={`sel-item-inner ${props.item.scheduler && "withScheduler"}`}
      >
        {showForm ? ( // Prikaz select menija i odabir zeljenog statusa
          <form data-testid="status-select">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                data-testid="status-select-drop"
                label="Status"
                onChange={(e) => {
                  select(e);
                }}
                onClick={(e) => e.stopPropagation()}
                value={selected}
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  paddingRight: "5px",
                }}
              >
                {props.item.selectionLevelId === 4 // Ispis statusnih opcija!
                  ? optionsFinalDecision.map((n, index) => (
                      <MenuItem
                        key={index}
                        sx={{ textAlign: "left" }}
                        value={n}
                      >
                        {n}
                      </MenuItem>
                    ))
                  : options.map((n, index) => (
                      <MenuItem
                        key={index}
                        sx={{ textAlign: "left" }}
                        value={n}
                      >
                        {n}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </form>
        ) : (
          // Pocetni prikaz SelectionCard-a, Status + Ime i prezime
          <div
            className="status"
            onClick={(e) => {
              if (props.item.status !== "Neuspešno") statusChange(e);
            }}
          >
            <button
              data-testid="status-btn"
              className={props.item.status === "Neuspešno" ? "unsucc" : ""}
            >
              {props.item.status === "Zakazan" && t("processStatus.scheduled")}
              {props.item.status === "Odrađen" && t("processStatus.finished")}
              {props.item.status === "Čeka na zakazivanje" &&
                t("processStatus.waitingAppointment")}
              {props.item.status === "Neuspešno" &&
                t("processStatus.unsuccesfully")}
            </button>
          </div>
        )}
        <div className="date-name">
          <div className="date">
            {props.item.date !== null && props.item.date !== "" && (
              <p data-testid="process-date">
                {formatDateSrb(props.item.date)} <span className="grey">|</span>{" "}
                {formatTimeSrb(props.item.date)}
              </p>
            )}
          </div>
          <div className="full-name">
            <p>
              {props.item.applicant.firstName +
                " " +
                props.item.applicant.lastName}
            </p>
          </div>
        </div>
      </div>
      {props.item.scheduler &&
      props.item.status !== "Neuspešno" &&
      props.item.status !== "Odrađen" ? (
        <div className="sel-item-scheduler">
          <div className="change-interbtn">
            <Button
              className="interbtn"
              onClick={(e) => changeInterviewerHandler(e)}
            >
              {t("common.change")}
            </Button>
          </div>
          <p>
            {t("selection.interviewer")}: {props.item.scheduler.firstName}{" "}
            {props.item.scheduler.lastName}
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

SelectionCard.propTypes = {
  item: PropTypes.any,
  click: PropTypes.func,
  dragStart: PropTypes.func,
};
export default SelectionCard;
