import React from "react";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import plusIcon from "../../../assets/images/plus.png";
import trashIcon from "../../../assets/images/delete.png";

const ExperienceProjectsFrom = ({
  expIndex,
  index,
  field,
  cvData,
  setCvData,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const projErrors = errors[expIndex]?.projects[index];

  const handleChangeInput = (index, event) => {
    const experiences = [...cvData.experiences];
    experiences[expIndex].projects[index][event.target.name] =
      event.target.value;

    setCvData((state) => ({ ...state, experiences }));
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[expIndex].projects[index] = {
        ...newErrors[expIndex].projects[index],
        [event.target.name]: false,
      };
      return newErrors;
    });
  };

  const handleAddFields = () => {
    const experiences = [...cvData.experiences];
    const projects = [
      ...experiences[expIndex].projects,
      {
        name: "",
        description: "",
      },
    ];

    experiences[expIndex].projects = projects;

    setCvData((state) => ({ ...state, experiences }));

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[expIndex].projects.push({
        name: false,
        description: false,
      });

      return newErrors;
    });
  };

  const handleRemoveFields = (index) => {
    const experiences = [...cvData.experiences];
    const projects = [...experiences[expIndex].projects];

    if (projects.length === 1) return;

    projects.splice(index, 1);
    experiences[expIndex].projects = projects;

    setCvData((state) => ({ ...state, experiences }));
  };

  const getHelperText = (fieldName) => {
    return `${t(`cv.${fieldName}`)} ${t("cv.helperText")}`;
  };

  return (
    <Box className="cv-exp-proj-form-main">
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <Box className="cv-form-control">
            <label>{t("cv.projectName")}</label>
            <TextField
              name="name"
              size="small"
              placeholder="ex. Todo App"
              value={field.name}
              helperText={projErrors?.name && getHelperText("projectName")}
              error={projErrors?.name}
              onChange={(event) => handleChangeInput(index, event)}
            />
          </Box>
        </Grid>
        <Grid item lg={8}>
          <Box className="cv-form-control">
            <label>{t("cv.projectDesc")}</label>
            <TextField
              multiline
              rows={2}
              name="description"
              size="small"
              placeholder="ex. Web application for..."
              helperText={
                projErrors?.description && getHelperText("projectDesc")
              }
              error={projErrors?.description}
              value={field.description}
              onChange={(event) => handleChangeInput(index, event)}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ m: 2 }}>
        <IconButton
          className={"c-btn--primary-outlined c-btn td-btn"}
          onClick={() => handleRemoveFields(index)}
        >
          <img src={trashIcon} width={15} height={15} />
        </IconButton>
      </Box>
      <Box>
        <IconButton
          className={"c-btn--primary-outlined c-btn td-btn"}
          onClick={handleAddFields}
        >
          <img src={plusIcon} width={15} height={15} />
        </IconButton>
      </Box>
    </Box>
  );
};

ExperienceProjectsFrom.propTypes = {
  expIndex: PropTypes.number,
  index: PropTypes.number,
  field: PropTypes.object,
  cvData: PropTypes.object,
  setCvData: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};

export default ExperienceProjectsFrom;
