import React, { useReducer, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import arrowRight from "../../../assets/images/arrow_right.png";
import arrowLeft from "../../../assets/images/arrow_left2.png";
import diligLogo from "../../../assets/images/dilig-logo.png";

const PDFViewer = ({ pdf }) => {
  const [pageNum, setPageNum] = useState(1);
  const [, forceRerender] = useReducer((x) => x + 1, 0);

  const handlePageChange = (index) => {
    setPageNum((prev) => {
      if (prev === 1 && index === -1) return prev;
      return prev + index;
    });
  };

  const handlePageError = () => {
    setPageNum((prev) => {
      if (prev <= 1) return prev;

      return prev - 1;
    });
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((_, i) => binaryString.charCodeAt(i));
  };

  const DiligLogo = () => {
    return (
      <Box
        sx={{
          wdith: { md: 50, lg: 80, xl: 100 },
          height: { md: 50, lg: 80, xl: 100 },
          mb: { xl: 3 },
        }}
      >
        <img src={diligLogo} width="100%" height="100%" />
      </Box>
    );
  };

  const LoadingSpinner = () => {
    return (
      <Box className="pdf-loading-spinner-box">
        <DiligLogo />
        <CircularProgress sx={{ color: "black" }} />
      </Box>
    );
  };

  const ErrorPlaceholder = () => {
    return (
      <Box className="pdf-loading-spinner-box">
        <DiligLogo />
        <Typography fontSize={18} fontWeight={600}>
          Error loading PDF
        </Typography>
        <Button
          variant="outlined"
          sx={{ color: "black", mt: { xl: 1 }, textTransform: "none" }}
          onClick={forceRerender}
        >
          <Typography fontSize={12}>Retry</Typography>
        </Button>
      </Box>
    );
  };

  return (
    <Box className="pdf-viewer-main">
      <Box className="pdf-viewer-controls-cont">
        <IconButton onClick={() => handlePageChange(-1)}>
          <img src={arrowLeft} />
        </IconButton>
        <Typography sx={{ marginX: 1, color: "#696969" }} fontSize={13}>
          Page {pageNum}
        </Typography>
        <IconButton onClick={() => handlePageChange(1)}>
          <img src={arrowRight} />
        </IconButton>
      </Box>
      <Document
        file={{ data: base64ToArrayBuffer(pdf) }}
        loading={<LoadingSpinner />}
        error={<ErrorPlaceholder />}
        noData={<ErrorPlaceholder />}
      >
        <Page
          pageNumber={pageNum}
          width={600}
          className="pdf-viewer-page"
          onLoadError={handlePageError}
        />
      </Document>
    </Box>
  );
};

PDFViewer.propTypes = {
  pdf: PropTypes.any,
};

export default React.memo(PDFViewer);
