import React from "react";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import deleteImage from "../../../src/assets/images/delete.png";
import planeImage from "../../../src/assets/images/planeVectorBlue.png";
import IconButton from "../../components/IconButton/IconButton";
import { formatDateTime } from "../../util/helpers/dateHelpers";
import { setUsersReq } from "../../store/actions/users/usersActions";
import { createCandidateComment } from "../../store/actions/candidate/candidateActions";
import { useDispatch, useSelector } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import { fetchCandidate } from "../../store/actions/candidate/candidateActions";
import {
  Snackbar,
  useMediaQuery,
  Button,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@mui/material";
import { useTheme } from "@mui/system";
import CandidateAd from "../../components/Candidates/CandidateAd";
import Slider from "react-slick";
import arrow_left from "../../assets/images/arrow_left.png";
import arrow_right from "../../assets/images/arrow_right.png";
import planeVector from "../../assets/images/planeVectorBlue.png";
import {
  ADS_PAGE,
  CANDIDATES_PAGE,
  CREATE_CV_PAGE,
} from "../../constants/pages";
import PropTypes from "prop-types";
import { deleteCandidate } from "../../store/actions/candidate/candidateActions";
import ConfirmDialog from "../../components/MUI/ConfirmDialog";
import deleteIcon from "../../assets/images/delete.png";
import { selectCandidate } from "../../store/selectors/candidateSelectors";
import { selectAuthUser } from "../../store/selectors/userSelectors";
import { selectUsers } from "../../store/selectors/usersSelector";
import { useTranslation } from "react-i18next";
import { setSendCvInviteReq } from "../../store/actions/cv/cvActions";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const CandidateDetailsPage = ({ history }) => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const user = useSelector(selectAuthUser);
  const candidate = useSelector(selectCandidate);
  const [inputValue, setInputValue] = useState("");
  let { id } = useParams();
  const messageContainer = useRef();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("361"));
  const adsSliderRef = useRef();
  const [showDelete, setDelete] = useState(false);
  const [showInvConfDialog, setShowInvConfDialog] = useState(false);
  const [usersToNotify, setUsersToNotify] = useState([]); //emails of users which are taged in comment
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [openBtnOptions, setOpenBtnOptions] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchCandidate({ id }));
    dispatch(setUsersReq());
  }, [dispatch]);

  useEffect(() => {
    messageContainer.current !== undefined
      ? (messageContainer.current.scrollTop =
          messageContainer.current.scrollHeight)
      : "";
  }, [messageContainer.current, candidate.comments]);

  const handleChangeValueToRepresent = (event) => {
    setInputValue(event.target.value);
  };

  const getArray = () => {
    let excludeCurrentlyLogedUser = users.filter((u) => u.id !== user.id);
    let newArray = excludeCurrentlyLogedUser.map(function (value) {
      return { id: value.id, display: value.firstName + " " + value.lastName };
    });

    return newArray;
  };

  const isThereUserAlready = (email) => {
    let indicator = usersToNotify.filter((k) => k === email);
    return indicator.length > 0;
  };

  const tranformDisplay = (id, display) => {
    let user = users.filter((u) => u.id === id);
    if (user[0] !== undefined) {
      if (!isThereUserAlready(user[0].email)) {
        setUsersToNotify((current) => [...current, user[0].email]);
      }
    }
    return display + " ";
  };

  const createComment = () => {
    // can't send message if input is empty
    if (inputValue.trim().length === 0) {
      return;
    }
    dispatch(
      createCandidateComment({
        content: inputValue,
        userId: user.id,
        applicantId: parseInt(id),
        usersToNotify: usersToNotify,
        user: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        },
      })
    );

    setUsersToNotify([]);
    setInputValue("");
  };

  const sendComment = () => {
    createComment();
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getDummyAds = (len) => {
    const ads = [];

    for (let i = 0; i < 5 - len + 1; i++) {
      ads.push(<CandidateAd key={i} className="hiddenAd" />);
    }

    return ads;
  };

  const activeAdsArrowLeftHandler = () => {
    adsSliderRef.current.slickPrev();
  };

  const activeAdsArrowRightHandler = () => {
    adsSliderRef.current.slickNext();
  };

  const navigateToDetailsPage = (id) => {
    history.push({
      pathname: ADS_PAGE + "/" + id,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const handleApiResponseSuccessDelete = () => {
    history.push({
      pathname: CANDIDATES_PAGE,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const deleteCandidateHandler = () => {
    dispatch(
      deleteCandidate({
        id,
        handleApiResponseSuccess: handleApiResponseSuccessDelete,
      })
    );
  };

  const makeCommentForSending = (comment) => {
    let k = comment.replaceAll("[", "<span style='color:#226CB0'>");
    let t = k.replaceAll("]", "</span> ");
    let n = "<p>" + t + "</p>";
    return n;
  };

  const handleOnKeyUp = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      createComment();
    }
  };

  const goToPageWithAllCandidates = () => {
    history.push({
      pathname: "/candidates",
    });
  };

  const handleSendInvite = () => {
    dispatch(
      setSendCvInviteReq({
        id,
        handleApiResponseSuccess: () => setOpenSnackBar(true),
      })
    );
  };

  const handleDiligCvSelectBtnClick = () => {
    if (selectedIndex === 0) {
      setShowInvConfDialog(true);
      return;
    }

    history.push({
      pathname: CREATE_CV_PAGE.replace(":token", candidate.applicantId),
      state: {
        candidate,
      },
    });
  };

  const handleToggleDiligCvSelectBtn = () => {
    setOpenBtnOptions((prevOpen) => !prevOpen);
  };

  const handleDiligCvSelectBtnClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenBtnOptions(false);
  };

  return (candidate && Object.keys(candidate).length === 0) ||
    user === undefined ? (
    <p>Loading...</p>
  ) : (
    <div className="main-candidate-container">
      <ConfirmDialog
        open={showDelete}
        title={t("candidates.deleteCandidate")}
        subtitle={candidate.firstName + " " + candidate.lastName}
        imgSrc={deleteIcon}
        content={t("candidates.deleteCandidateQuestion")}
        onClose={() => {
          setDelete(false);
        }}
        onConfirm={deleteCandidateHandler}
      />
      <ConfirmDialog
        open={showInvConfDialog}
        title={t("candidates.sendCVInviteTitle")}
        subtitle={`${candidate.firstName} ${candidate.lastName}`}
        imgSrc={planeVector}
        content={t("candidates.sendCvInviteQuestion")}
        onClose={() => {
          setShowInvConfDialog(false);
        }}
        onConfirm={() => {
          handleSendInvite();
          setShowInvConfDialog(false);
        }}
      />
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <div className="top-candidate-container">
        <div>
          <p className="candidate-header">{t("candidates.candidate")}</p>
          <span className="separation-line">|</span>
          <p className="candidate-lower-header">
            {candidate.firstName} {candidate.lastName}
          </p>
        </div>
        <div className="candidate-option-container">
          <IconButton
            className="c-btn c-btn--primary-outlined candidate-btn"
            onClick={() => setDelete(true)}
          >
            {t("common.delete")}
            <img src={deleteImage} alt="delete" className="candidates-image" />
          </IconButton>
        </div>
      </div>
      <div className="content-candidate-container">
        <div className="details-candidate-container">
          <p style={{ margin: 0 }} data-testid="candidate-experience">
            {candidate.experience === 0
              ? t("common.noExperience")
              : t("candidates.experience") + ":" + candidate.experience}
          </p>
          <div className="technologies-candidate-container">
            {candidate.technologyApplicants.map((obj, index) => (
              <div className="technology-candidate-card" key={index}>
                {obj.technology.name}
              </div>
            ))}
          </div>
          <div className="candidate-informations-container">
            <div className="candidate-informations-sub-container">
              <div className="candidate-property-container">
                <p className="informations-candidate-header">
                  {t("candidates.informations")}
                </p>
                <p className="candidate-property">{t("common.gender")}:</p>
                <p className="candidate-property">
                  {t("ads.professionalQualification")}:
                </p>
              </div>
              <div
                style={{ alignSelf: "flex-end", marginLeft: 42 }}
                className="candidate-property-container"
              >
                <p
                  className="candidate-property-value"
                  data-testId="candidate-gender"
                >
                  {candidate.gender === "M"
                    ? t("common.male")
                    : t("common.female")}
                </p>
                <p className="candidate-property-value">
                  {candidate.professionalQualification === ""
                    ? "/"
                    : candidate.professionalQualification}
                </p>
              </div>
            </div>
            <div className="candidate-informations-sub-container">
              <div className="candidate-property-container">
                <p className="informations-candidate-header">
                  {t("candidates.contact")}
                </p>
                <p className="candidate-property">Email:</p>
                <p className="candidate-property">{t("users.phone")}:</p>
              </div>
              <div
                style={{ alignSelf: "flex-end", marginLeft: 42 }}
                className="candidate-property-container"
              >
                <p className="candidate-property-value">{candidate.email}</p>
                <p className="candidate-property-value">
                  {candidate.phoneNumber === "" ? "/" : candidate.phoneNumber}
                </p>
              </div>
            </div>
            <div className="candidate-informations-sub-container">
              <div className="candidate-property-container">
                <p className="informations-candidate-header">
                  {t("users.socials")}
                </p>
                <p className="candidate-property">Linkedln</p>
                <p className="candidate-property">GitHub</p>
                <p className="candidate-property">BitBucket</p>
              </div>
              <div
                style={{ alignSelf: "flex-end", marginLeft: 42 }}
                className="candidate-property-container"
              >
                <p className="candidate-property-value">
                  {candidate.linkedlnLink === "" ? "/" : candidate.linkedlnLink}
                </p>
                <p className="candidate-property-value">
                  {candidate.githubLink === "" ? "/" : candidate.githubLink}
                </p>
                <p className="candidate-property-value">
                  {candidate.bitBucketLink === ""
                    ? "/"
                    : candidate.bitBucketLink}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="comment-container">
          <div
            style={{
              minHeight: "219px",
              overflowX: "auto",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
            ref={messageContainer}
          >
            {candidate.comments.map((comment, index) => (
              <div className="comment-sub-container" key={index}>
                <div className="comment-sender">
                  <p>
                    {comment.user.firstName.charAt(0)}
                    {comment.user.lastName.charAt(0)}
                  </p>
                </div>
                <div className="comment-message">
                  <div
                    className="comment-message-con"
                    dangerouslySetInnerHTML={{
                      __html: makeCommentForSending(comment.content),
                    }}
                  />
                  <p className="comment-message-date">
                    {formatDateTime(comment.dateOfSending)}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="comment-separation-line"></div>
          <div className="send-comment-container">
            <p>{t("candidates.comment")}</p>
            <div className="send-comment-sub-container">
              <MentionsInput
                value={inputValue}
                onChange={handleChangeValueToRepresent}
                onKeyUp={handleOnKeyUp}
                className="comment-input"
                placeholder={"ex. Odlican kandidat"}
                customSuggestionsContainer={(children) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                      marginLeft: 3,
                      maxHeight: 95,
                      overflowY: "auto",
                    }}
                  >
                    {children}
                  </div>
                )}
                style={{
                  input: {
                    overflow: "auto",
                    height: "100%",
                    padding: 17,
                  },
                  highlighter: {
                    boxSizing: "border-box",
                    overflow: "hidden",
                    padding: 17,
                    height: 56,
                  },
                }}
              >
                <Mention
                  trigger="@"
                  className="comment-input-list"
                  data={getArray}
                  displayTransform={(id, display) =>
                    tranformDisplay(id, display)
                  }
                  markup="[__display__]"
                  style={{ color: "#226CB0", overflow: "hidden" }}
                />
              </MentionsInput>
              {!matches ? (
                <div className="comment-send-btn" onClick={sendComment}>
                  <img
                    src={planeImage}
                    alt="plane"
                    className="candidates-image"
                  />
                  <p>{t("candidates.comment")}</p>
                </div>
              ) : (
                <div
                  className="comment-send-btn-responsive"
                  onClick={sendComment}
                >
                  <img
                    src={planeImage}
                    alt="plane"
                    className="candidates-image"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="applicant-jobs-container">
        <p style={{ marginLeft: matches ? 36 : 144 }}>
          {t("candidates.allApplications")}
        </p>
        <div className="applicant-jobs-container-2">
          <div
            style={{
              marginLeft: matches ? 36 : candidate.ads.length < 5 ? 108 : 72,
            }}
          >
            {(matches
              ? candidate.ads.length > 1
              : candidate.ads.length > 5) && (
              <div
                className="active-jobs-jobs-arrows"
                data-testid="candidate-ad-responsive-arrows"
              >
                <button onClick={activeAdsArrowLeftHandler}>
                  <img src={arrow_left} alt="arrow-left" />
                </button>
                <button onClick={activeAdsArrowRightHandler}>
                  <img src={arrow_right} alt="arrow-right" />
                </button>
              </div>
            )}
          </div>
          <Slider {...settings} style={{ width: "100%" }} ref={adsSliderRef}>
            {candidate.ads.map((add, index) => (
              <CandidateAd
                add={add}
                key={index}
                onclick={() => navigateToDetailsPage(add.id)}
              />
            ))}
            {candidate.ads.length <= 5 && getDummyAds(candidate.ads.length)}
          </Slider>
        </div>
      </div>
      <div className="applicant-jobs-buttons-container">
        <p
          to="/candidates"
          className="applicant-jobs-back-button"
          onClick={goToPageWithAllCandidates}
        >
          {t("candidates.backToCandidates")}
        </p>
        {candidate.diligCV ? (
          <a
            className="applicant-dilig-cv-button"
            download={`Diligent ${candidate.firstName} ${candidate.lastName}.pdf`}
            href={`data:application/pdf;base64,${candidate.diligCV}`}
            title="Download pdf document"
          >
            {t("cv.downloadDiligCv")}
          </a>
        ) : (
          <React.Fragment>
            <ButtonGroup
              ref={anchorRef}
              className="applicant-dilig-cv-btn-group"
            >
              <Button
                fullWidth
                disableRipple
                className="applicant-dilig-cv-btn"
                onClick={handleDiligCvSelectBtnClick}
              >
                {selectedIndex === 0 ? t("cv.sendCvInvite") : "Create CV"}
              </Button>
              <Button
                size="small"
                onClick={handleToggleDiligCvSelectBtn}
                className="applicant-dilig-cv-btn-select"
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={openBtnOptions}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper sx={{ mb: 2 }}>
                    <ClickAwayListener
                      onClickAway={handleDiligCvSelectBtnClose}
                    >
                      <MenuList autoFocusItem>
                        <MenuItem
                          selected={selectedIndex === 0}
                          onClick={() => {
                            setSelectedIndex(0);
                            setOpenBtnOptions(false);
                          }}
                        >
                          {t("cv.sendCvInvite")}
                        </MenuItem>
                        <MenuItem
                          selected={selectedIndex === 1}
                          onClick={() => {
                            setSelectedIndex(1);
                            setOpenBtnOptions(false);
                          }}
                        >
                          Create CV
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        )}
        <a
          className="applicant-cv-button"
          download={candidate.firstName + candidate.lastName + ".pdf"}
          href={`data:application/pdf;base64,${candidate.cv}`}
          title="Download pdf document"
        >
          {t("common.download")} cv
        </a>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
        message={t("cv.inviteSentMessage")}
      />
    </div>
  );
};

CandidateDetailsPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default CandidateDetailsPage;
