import { createSelector } from "@reduxjs/toolkit";

export const candidatesSelector = (state) => state.candidates;

export const selectCandidates = createSelector(
  candidatesSelector,
  (state) => state.candidates
);

export const selectPagination = createSelector(
  candidatesSelector,
  (state) => state.pagination
);

export const selectAdsCandidates = createSelector(
  candidatesSelector,
  (state) => state.adsCandidates
);

export const selectCandidatesError = createSelector(
  candidatesSelector,
  (state) => state.errorMessage
);
