import React from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TINYMCE_API_KEY } from "../../secrets";

const CreateAdThirdStep = ({ childRef }) => {
  const { t } = useTranslation();
  const editorKeyResponsibilitiesRef = useRef();
  const editorRequirementsRef = useRef();
  const editorOfferRef = useRef();

  useEffect(() => {
    childRef.current = alertUser;
  }, []);

  function alertUser() {
    return {
      keyResponsibilities: editorKeyResponsibilitiesRef.current.getContent(),
      requirements: editorRequirementsRef.current.getContent(),
      offer: editorOfferRef.current.getContent(),
    };
  }

  return (
    <div data-testid="create-job-third-step-form">
      <div className="create-job-form-control">
        <label>{t("ads.duties")}</label>
        <Editor
          apiKey={TINYMCE_API_KEY}
          onInit={(evt, editor) =>
            (editorKeyResponsibilitiesRef.current = editor)
          }
          style={{ height: "1rem !important" }}
        />
      </div>
      <div className="create-job-form-control">
        <label>{t("ads.conditions")}</label>
        <Editor
          apiKey={TINYMCE_API_KEY}
          onInit={(evt, editor) => (editorRequirementsRef.current = editor)}
          style={{ height: "1rem !important" }}
        />
      </div>
      <div className="create-job-form-control">
        <label>{t("ads.offer")}</label>
        <Editor
          apiKey={TINYMCE_API_KEY}
          onInit={(evt, editor) => (editorOfferRef.current = editor)}
          style={{ height: "1rem !important" }}
        />
      </div>
    </div>
  );
};

CreateAdThirdStep.propTypes = {
  childRef: PropTypes.any,
};

export default CreateAdThirdStep;
