import {
  FETCH_PROCESSES_REQ,
  FETCH_PROCESSES_ERR,
  FETCH_PROCESSES_SUCCESS,
  FETCH_FILTERED_PROCESSES_REQ
} from "./processesActionConstants";

export const setProcessesReq = () => ({
  type: FETCH_PROCESSES_REQ,
});

export const setFilteredProcessesReq = (payload) => ({
  type: FETCH_FILTERED_PROCESSES_REQ,
  payload,
});

export const setProcessesError = (payload) => ({
  type: FETCH_PROCESSES_ERR,
  payload,
});

export const setProcesses = (payload) => ({
  type: FETCH_PROCESSES_SUCCESS,
  payload,
});