import { format } from "date-fns";
import { enUS } from "date-fns/locale";

export function formatDate(date, fmt = "dd.MM.y", locale = enUS) {
  const dt = new Date(date);
  return format(dt, fmt, { locale });
}

export function formatDateInput(date, fmt = "y-MM-dd", locale = enUS) {
  const dt = new Date(date);
  return format(dt, fmt, { locale });
}

export function formatDateTime(date) {
  const dt = new Date(date);
  return format(dt, "hh:mm dd.MM.y");
}

export function formatDateSrb(date) {
  const dt = new Date(date);
  return format(dt, "dd.MM.");
}

export function formatTimeSrb(date) {
  const dt = new Date(date);
  return format(dt, "HH:mm");
}

export function getMonthString(numberOfMonth) {
  let month = "";
  switch (numberOfMonth) {
    case 0:
      month = "Januar";
      break;
    case 1:
      month = "Februar";
      break;
    case 2:
      month = "Mart";
      break;
    case 3:
      month = "April";
      break;
    case 4:
      month = "Maj";
      break;
    case 5:
      month = "Jun";
      break;
    case 6:
      month = "Jul";
      break;
    case 7:
      month = "Avgust";
      break;
    case 8:
      month = "Septembar";
      break;
    case 9:
      month = "Oktobar";
      break;
    case 10:
      month = "Novembar";
      break;
    case 11:
      month = "Decembar";
      break;
    default:
      month = "Neispravan mesec";
      break;
  }

  return month;
}

export function getDayString(dayOfWeek) {
  let day = "";
  switch (dayOfWeek) {
    case 0:
      day = "Ponedeljak";
      break;
    case 1:
      day = "Utorak";
      break;
    case 2:
      day = "Sreda";
      break;
    case 3:
      day = "Cetvrtak";
      break;
    case 4:
      day = "Petak";
      break;
    case 5:
      day = "Subota";
      break;
    case 6:
      day = "Nedelja";
      break;
  }

  return day;
}

export function getFormatedDayOrMonth(dayNumber) {
  return dayNumber <= 9 ? "0" + dayNumber : dayNumber;
}
