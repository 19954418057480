import {
    CREATE_AD_ERR,
    CREATE_AD_REQ,
    CREATE_AD_SUCCESS,
  } from "./createAdActionConstants";
  
  export const setCreateAdReq = (payload) => ({
    type: CREATE_AD_REQ,
    payload,
  });
  
  export const setCreateAdError = (payload) => ({
    type: CREATE_AD_ERR,
    payload,
  });
  
  export const setCreateAd = (payload) => ({
    type: CREATE_AD_SUCCESS,
    payload,
  });
  