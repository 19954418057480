import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../util/helpers/dateHelpers";
import { CANDIDATES_PAGE } from "../../constants/pages";
import { useTranslation } from "react-i18next";

const CandidateCard = ({ candidate, className, history }) => {
  const { t } = useTranslation();
  const navigateToDetailsPage = () => {
    history.push({
      pathname: CANDIDATES_PAGE + "/" + candidate.applicantId,
    });
  };

  return candidate == null ? (
    <p></p>
  ) : (
    <div
      onClick={navigateToDetailsPage}
      className={`candidate-card-container ${
        className !== undefined ? className : ""
      }`}
    >
      <p className="candidate-card-date">
        {formatDate(candidate.dateOfApplication)}
      </p>
      <p className="candidate-card-applicant-name">
        {candidate.firstName} {candidate.lastName}
      </p>
      <p className="candidate-card-years">
        {candidate.experience === 0
          ? t("common.noExperience")
          : candidate.experience + " " + t("common.experience")}
      </p>
      <div className="candidate-card-tecnologies-container">
        {candidate.technologyApplicants.map((technology, index) => (
          <p key={index}>{technology.name}</p>
        ))}
      </div>
      <div className="candidate-card-techologies">
        {candidate.technologyApplicants.map((technology, index) => (
          <div key={index} className="applicant-add-site">
            {technology.technology.name}
          </div>
        ))}
      </div>
      <a className="cvLink">
        CV {candidate.firstName} {candidate.lastName}.pdf
      </a>
    </div>
  );
};

CandidateCard.propTypes = {
  candidate: PropTypes.object,
  className: PropTypes.any,
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default CandidateCard;
