import { all, call, put, takeLatest } from "redux-saga/effects";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import {
  CREATE_CV_REQ,
  FETCH_CV_PREVIEW_REQ,
  RESPOND_TO_CV_INVITE_REQ,
  SEND_CV_INVITE_REQ,
} from "../actions/cv/cvActionsConstants";
import {
  getCvPreview,
  sendCvInviteReq,
  repondToCvInvite,
  createCvReq,
} from "../../request/cvRequests";
import {
  setCreateCvError,
  setCvPreview,
  setCvPreviewError,
  setRespondToCvInviteError,
  setSendCvInviteError,
} from "../actions/cv/cvActions";

export function* getCvPreviewSaga({ payload }) {
  try {
    const result = yield call(getCvPreview, payload);
    yield put(setCvPreview(result.data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setCvPreviewError(errorMessage));
  }
}

export function* sendCVInvite({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);

    yield call(sendCvInviteReq, payload.id);
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setSendCvInviteError(errorMessage));
  }
}

export function* respondCvInvite({ payload }) {
  try {
    yield call(repondToCvInvite, payload);
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setRespondToCvInviteError(errorMessage));
  }
}

export function* createCv({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);

    yield call(createCvReq, payload);

    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setCreateCvError(errorMessage));
  }
}

export default function* cvPreviewSaga() {
  yield all([takeLatest(FETCH_CV_PREVIEW_REQ, getCvPreviewSaga)]);
  yield all([takeLatest(SEND_CV_INVITE_REQ, sendCVInvite)]);
  yield all([takeLatest(RESPOND_TO_CV_INVITE_REQ, respondCvInvite)]);
  yield all([takeLatest(CREATE_CV_REQ, createCv)]);
}
