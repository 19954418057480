import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ApplyForAdThirdStage = ({
  onIncreaseStage,
  onDecreaseStage,
  linkedinLink,
  setLinkedinLink,
  githubLink,
  setGithubLink,
  bitBucketLink,
  setBitBucketLink,
  email,
  setEmail,
}) => {
  const disabled =
    linkedinLink.length === 0 ||
    githubLink.length === 0 ||
    bitBucketLink.length === 0;

  const {t} = useTranslation()

  return (
    <div>
      <div className="apply-for-job-header-title">
        <p>{t("common.socialNetwork")}</p>
      </div>
      <div className="apply-for-job-modal-form-control">
        <label>LinkedIn</label>
        <input
          type="text"
          value={linkedinLink}
          data-testid="apply-for-ad-modal-third-stage-linkedin-input"
          onChange={(e) => setLinkedinLink(e.target.value)}
          placeholder="ex. https://www.linkedin.com/in/petar-petrovic"
        />
      </div>
      <div className="apply-for-job-modal-form-control">
        <label>GitHub</label>
        <input
          type="text"
          value={githubLink}
          data-testid="apply-for-ad-modal-third-stage-github-input"
          onChange={(e) => setGithubLink(e.target.value)}
          placeholder="ex. https://www.github.com/petarpetrovic"
        />
      </div>
      <div className="apply-for-job-modal-form-control">
        <label>BitBucket</label>
        <input
          type="text"
          value={bitBucketLink}
          data-testid="apply-for-ad-modal-third-stage-bitbucket-input"
          onChange={(e) => setBitBucketLink(e.target.value)}
          placeholder="ex. https://developer.atlassian.com/user/petarapetrovic"
        />
      </div>
      <div className="apply-for-job-modal-form-control">
        <label>Email</label>
        <input
          type="email"
          value={email}
          data-testid="apply-for-ad-modal-third-stage-email-input"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="ex. petar.petrovic@dilig.net"
        />
      </div>
      <div className="apply-for-job-buttons">
        <button
          onClick={onDecreaseStage}
          data-testid="apply-for-ad-modal-third-stage-go-back-button"
        >
          {t("common.back")}
        </button>
        <button
          onClick={onIncreaseStage}
          disabled={disabled}
          data-testid="apply-for-ad-modal-third-stage-go-forward-button"
        >
          {t("common.continue")}
        </button>
      </div>
    </div>
  );
};

ApplyForAdThirdStage.propTypes = {
  linkedinLink: PropTypes.string,
  setLinkedinLink: PropTypes.any,
  githubLink: PropTypes.string,
  setGithubLink: PropTypes.any,
  bitBucketLink: PropTypes.string,
  setBitBucketLink: PropTypes.any,
  email: PropTypes.string,
  setEmail: PropTypes.any,
  onIncreaseStage: PropTypes.any,
  onDecreaseStage: PropTypes.any,
};

export default ApplyForAdThirdStage;
