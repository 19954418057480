import {
  CREATE_PATTERN_REQ,
  CREATE_PATTERN_ERR,
  CREATE_PATTERN_SUCCESS,
} from "./createPatternActionConstants";

export const createPatternReq = (payload) => ({
  type: CREATE_PATTERN_REQ,
  payload,
});

export const createPatternError = (payload) => ({
  type: CREATE_PATTERN_ERR,
  payload,
});

export const createPattern = (payload) => ({
  type: CREATE_PATTERN_SUCCESS,
  payload,
});
