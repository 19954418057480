import { all, call, put, takeLatest } from "redux-saga/effects";
import { getAllTechnologies } from "../../request/technologiesRequest";
import { FETCH_ADD_AD_TECHNOLOGIES_REQ } from "../actions/addAdTechnologies/addAdTechnologiesActionConstants";
import { setTechnologiesAddAd } from "../actions/addAdTechnologies/addAdTechnologiesActions";
import { FETCH_TECHNOLOGIES_REQ } from "../actions/technologies/technologiesActionConstants";
import {
  setTechnologies,
  setTechnologiesError,
} from "../actions/technologies/technologiesActions";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";

export function* getTechnologies() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAllTechnologies);
    const resultData = result.data.map((res) => ({ ...res, isChecked: false }));
    yield put(setTechnologies(resultData));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(setTechnologiesError(errorMessage));
    }
  }
}

export function* getTechnologiesAddAd() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAllTechnologies);
    const resultData = result.data.map((res) => ({ ...res, isChecked: false }));
    yield put(setTechnologiesAddAd(resultData));
  } catch (error) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(setTechnologiesError(errorMessage));
  }
}

export default function* technologiesSaga() {
  yield all([
    takeLatest(FETCH_TECHNOLOGIES_REQ, getTechnologies),
    takeLatest(FETCH_ADD_AD_TECHNOLOGIES_REQ, getTechnologiesAddAd),
  ]);
}
