import React, { useState } from "react";
import ApplyForAdFirstStage from "./ApplyForAdFirstStage";
import PropTypes from "prop-types";
import briefcaseIcon from "../../assets/images/briefcase1.png";
import xIcon from "../../assets/images/x.png";
import CustomModal from "../UI/CustomModal";
import ApplyForAdSecondStage from "./ApplyForAdSecondStage";
import { setTechnologiesReq } from "../../store/actions/technologies/technologiesActions";
import { selectTechnologies } from "../../store/selectors/technologiesSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ApplyForAdThirdStage from "./ApplyForAdThirdStage";
import ApplyForAdFourthStage from "./ApplyForAdFourthStage";
import { applyForAdReq } from "../../store/actions/applyForAd/applyForAdActions";
import { useHistory } from "react-router-dom";
import { ADS_PAGE } from "../../constants/pages";
import { useTranslation } from "react-i18next";

const ApplyForAd = ({ open, title, adId, onCloseModal }) => {
  const [stage, setStage] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mappedTechnologies, setMappedTechnologies] = useState([]);
  const [experience, setExperience] = useState(0);
  const [professionalQualification, setProfessionalQualification] =
    useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [githubLink, setGithubLink] = useState("");
  const [email, setEmail] = useState("");
  const [bitBucketLink, setBitBucketLink] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [pdfFile, setPdfFile] = useState(null);

  const technologies = useSelector(selectTechnologies);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setTechnologiesReq());
  }, []);

  useEffect(() => {
    if (technologies && technologies.length > 0) {
      const tech = technologies.map((t) => ({ ...t, isChecked: false }));
      setMappedTechnologies(tech);
    }
  }, [technologies]);

  const increaseStageHandler = () => {
    setStage((oldValue) => oldValue + 1);
  };

  const decreaseStageHandler = () => {
    setStage((oldValue) => oldValue - 1);
  };

  const handleApiResponseSuccess = () => {
    onCloseModal();
    history.push(ADS_PAGE);
  };

  const finishedFourStagesHandler = () => {
    const technologiesIds = mappedTechnologies
      .filter((tech) => tech.isChecked === true)
      .map((x) => x.technologyId);

    dispatch(
      applyForAdReq({
        adId,
        firstName,
        lastName,
        gender,
        dateOfBirth,
        phoneNumber,
        technologiesIds,
        experience,
        professionalQualification,
        linkedinLink,
        githubLink,
        bitBucketLink,
        email,
        coverLetter,
        pdfFile,
        handleApiResponseSuccess,
      })
    );
  };

  return (
    <CustomModal open={open} onCloseModal={onCloseModal} classes="apply-for-job">
      <div className="apply-for-job-header">
        <div className="apply-for-job-header-left">
          <div className="apply-for-job-header-left-image">
            <img src={briefcaseIcon} alt="plus" />
          </div>
          <div className="apply-for-job-header-left-image-title">
            <p>{t("ads.signUp")}</p>
          </div>
          <div className="apply-for-job-header-left-image-title-sub">
            <sub> | {title}</sub>
          </div>
        </div>
        <div className="apply-for-job-header-right">
          <button onClick={onCloseModal}>
            <img src={xIcon} alt="x" />
          </button>
        </div>
      </div>
      {stage === 1 && (
        <ApplyForAdFirstStage
          onCloseModal={onCloseModal}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          gender={gender}
          setGender={setGender}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          onIncreaseStage={increaseStageHandler}
        />
      )}
      {stage === 2 && (
        <ApplyForAdSecondStage
          technologies={mappedTechnologies}
          setTechnologies={setMappedTechnologies}
          experience={experience}
          professionalQualification={professionalQualification}
          setProfessionalQualification={setProfessionalQualification}
          setExperience={setExperience}
          onIncreaseStage={increaseStageHandler}
          onDecreaseStage={decreaseStageHandler}
        />
      )}
      {stage === 3 && (
        <ApplyForAdThirdStage
          linkedinLink={linkedinLink}
          setLinkedinLink={setLinkedinLink}
          githubLink={githubLink}
          setGithubLink={setGithubLink}
          bitBucketLink={bitBucketLink}
          setBitBucketLink={setBitBucketLink}
          email={email}
          setEmail={setEmail}
          onIncreaseStage={increaseStageHandler}
          onDecreaseStage={decreaseStageHandler}
        />
      )}
      {stage === 4 && (
        <ApplyForAdFourthStage
          pdfFile={pdfFile}
          coverLetter={coverLetter}
          setCoverLetter={setCoverLetter}
          setPdfFile={setPdfFile}
          onDecreaseStage={decreaseStageHandler}
          onFinishedFourStages={finishedFourStagesHandler}
        />
      )}
    </CustomModal>
  );
};

ApplyForAd.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  adId: PropTypes.string,
  onCloseModal: PropTypes.func,
};

export default ApplyForAd;
