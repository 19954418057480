import { all, call, put, takeEvery } from "redux-saga/effects";
import { getSpecificSchedule } from "../../request/scheduleRequest";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import { SCHEDULE_FETCH } from "../actions/schedule/scheduleActionConstants";import { addHeaderToken } from "../../request";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { JWT_TOKEN } from "../../constants/localStorage";
import {
  fetchScheduleSuccess,
  fetchScheduleError,
} from "../actions/schedule/scheduleActions";

export function* getSchedule({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getSpecificSchedule, {month:payload.month, year:payload.year});
    yield put(fetchScheduleSuccess(result.data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(fetchScheduleError(errorMessage));
    }
  }
}
export default function* scheduleSaga() {
  yield all([takeEvery(SCHEDULE_FETCH, getSchedule)]);
}
