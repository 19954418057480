import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { changeIsCheckedAddAdValue } from "../../store/actions/addAdTechnologies/addAdTechnologiesActions";
import { useTranslation } from "react-i18next";

const CreateAdSecondStep = ({ technologies, experience, setExperience }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCheckboxes = (technologyId) => {
    dispatch(changeIsCheckedAddAdValue(technologyId));
  };

  return (
    <div data-testid="create-job-second-step-form">
      <div className="create-job-form-control">
        <label>{t("filters.advancedTechnologies")}</label>
      </div>
      <div className="add-job-modal-stage-sub-card">
        <div className="add-job-modal-stage-sub-card-checkboxes-group">
          <label>Front-End</label>
          <div className="add-job-modal-stage-sub-card-checkboxes">
            {technologies
              .filter((x) => x.technologyType === "Frontend")
              .map((x) => (
                <FormControlLabel
                  key={x.technologyId}
                  control={
                    <Checkbox
                      value={x.name}
                      checked={x.isChecked}
                      onChange={handleCheckboxes.bind(this, x.technologyId)}
                      className="create-job-second-step-checkbox"
                    />
                  }
                  label={x.name}
                />
              ))}
          </div>
        </div>

        <div className="add-job-modal-stage-sub-card-checkboxes-group">
          <label>Back-End</label>
          <div className="add-job-modal-stage-sub-card-checkboxes">
            {technologies
              .filter((x) => x.technologyType === "Backend")
              .map((x) => (
                <FormControlLabel
                  key={x.technologyId}
                  control={
                    <Checkbox
                      value={x.name}
                      checked={x.isChecked}
                      onChange={handleCheckboxes.bind(this, x.technologyId)}
                      className="create-job-second-step-checkbox"
                    />
                  }
                  label={x.name}
                />
              ))}
          </div>
        </div>

        <div className="add-job-modal-stage-sub-card-checkboxes-group">
          <label>{t("ads.others")}</label>
          <div className="add-job-modal-stage-sub-card-checkboxes">
            {technologies
              .filter((x) => x.technologyType === "Other")
              .map((x) => (
                <FormControlLabel
                  key={x.technologyId}
                  control={
                    <Checkbox
                      value={x.name}
                      checked={x.isChecked}
                      onChange={handleCheckboxes.bind(this, x.technologyId)}
                      className="create-job-second-step-checkbox"
                    />
                  }
                  label={x.name}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="create-job-form-control">
        <label>{t("filters.experience")}</label>
        <input
          type="number"
          min={0}
          onChange={(e) => setExperience(e.target.value)}
          value={experience}
        />
      </div>
    </div>
  );
};

CreateAdSecondStep.propTypes = {
  technologies: PropTypes.any,
  experience: PropTypes.number,
  setExperience: PropTypes.any,
};

export default CreateAdSecondStep;
