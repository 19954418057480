const base =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5116/v1"
    : process.env.REACT_APP_BASE_API_URL;

export default {
  authentications: {
    login: base + "/authentications/authenticate",
    googleLogin: base + "/authentications/authenticateGoogle",
    refreshToken: base + "/authentications/refresh",
    logout: base + "/authentications/logout?userId={userId}",
    forgetPassword: base + "/authentications/ForgotPassword",
    resetPassword: base + "/authentications/RessetPassword",
    register: base + "/authentications/register",
  },
  users: {
    allUsers: base + "/users",
    user: base + "/users/{id}",
    toggleEnabled: base + "/users/toggleEnable/{id}",
    invite: base + "/users/invite",
  },
  candidates: {
    filteredCandidates: base + "/applicants",
    allFilteredAdsCandidates: base + "/applicants/adsApplicants",
    options: base + "/applicants/options",
    initProcess: base + "/applicants/selection-init",
  },
  ads: {
    allAds: base + "/jobs",
    createAd: base + "/jobs",
    allFilteredAds: base + "/jobs/filtered",
    allArchiveAds: base + "/jobs/archive",
    adDetails: base + "/jobs/details",
    archiveActiveAd: base + "/jobs/archive-active-job/:id",
  },
  technologies: {
    allTechnologies: base + "/technologies",
  },
  comments: {
    addComment: base + "/comments",
  },
  processes: {
    allLevels: base + "/selectionlevels",
    filteredLevels: base + "/selectionlevels/filtered",
    doneProcess: base + "/selectionprocesses",
    getApplicantProcesses: base + "/applicants/processes",
    changeStatus: base + "/selectionprocesses/status-update",
    changeInterviewer: base + "/selectionprocesses/interviewer-update",
    // allProcesses: base + "/selectionprocesses",
  },
  patterns: {
    allPatterns: base + "/patterns",
    patternById: base + "/patterns/:id",
    patternApplicantsById:
      base + "/patterns/corresponding-pattern-applicants/:id",
    filteredPatterns: base + "/patterns/filter",
    createPattern: base + "/patterns",
    updatePattern: base + "/patterns/:id",
    scheduleAppointment: base + "/patterns/schedule-interview",
  },
  stats: {
    stats: base + "/stats",
  },
  schedule: {
    specificSchedule: base + "/schedule",
  },
  applicant: {
    applyForAd: base + "/applicants/apply-for-ad",
    getCV: base + "/applicants/get-CV",
  },
  screeningTest: {
    allTests: base + "/screeningtest",
    createTest: base + "/screeningtest",
  },
  cv: {
    getPreview: `${base}/applicants/get-cv-preview`,
    sendCvInviteReq: `${base}/applicants/create-cv-invite/{id}`,
    validateCvInvite: `${base}/applicants/validate-cv-invite/{token}`,
    repondToCvInvite: `${base}/applicants/respond-cv-invite/{token}`,
    createCv: `${base}/applicants/create-cv/{token}`,
  },
};
