import React from "react";
import PropTypes from "prop-types";
import { CANDIDATES_DETAILS_PAGE } from "../../constants/pages";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AdDetailsCandidateCard = ({
  className,
  id,
  firstName,
  lastName,
  experience,
  cv,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div
      data-testid="job-details-candidate"
      className={`job-details-candidate ${className}`}
    >
      <div className="job-details-candidate-date">
        <p>{new Date().toLocaleDateString()}</p>
      </div>
      <div className="job-details-candidate-title">
        <h3
          data-testid="job-details-candidate-title-link"
          onClick={() =>
            history.push(CANDIDATES_DETAILS_PAGE.replace(":id", id))
          }
        >
          {firstName} {lastName}
        </h3>
      </div>
      <div className="job-details-candidate-experience">
        {experience > 0 ? (
          <p>
            {experience}+ {t("common.experience")}
          </p>
        ) : (
          <p>{t("common.noExperience")}</p>
        )}
      </div>
      <div className="job-details-candidate-buttons">
        <button>React</button>
        <button>.NET</button>
        <button>Angular</button>
      </div>
      <div className="job-details-candidate-cv">
        <a href="#">{cv}</a>
      </div>
    </div>
  );
};

AdDetailsCandidateCard.propTypes = {
  className: PropTypes.any,
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  experience: PropTypes.number,
  cv: PropTypes.string,
};

export default AdDetailsCandidateCard;
