import {
  CREATE_CV_ERR,
  CREATE_CV_SUCCESS,
} from "../../actions/cv/cvActionsConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  isSuccess: false,
  errorMessage: "",
};

export default createReducer(
  {
    [CREATE_CV_SUCCESS]: creationSucceded,
    [CREATE_CV_ERR]: creationFailed,
  },
  initialState
);

function creationSucceded(state) {
  return {
    ...state,
    isSuccess: !state.isSuccess,
  };
}

function creationFailed(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
