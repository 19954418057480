import { getRequest, postRequest, replaceInUrl } from ".";
import apiEndpoints from "./apiEndpoints";

export const getCvPreview = (cvData) =>
  postRequest(apiEndpoints.cv.getPreview, cvData);

export const sendCvInviteReq = (id) =>
  postRequest(replaceInUrl(apiEndpoints.cv.sendCvInviteReq, { id }));

export const validateCvInviteReq = (token) =>
  getRequest(apiEndpoints.cv.validateCvInvite.replace("{token}", token));

export const repondToCvInvite = (payload) =>
  postRequest(
    apiEndpoints.cv.repondToCvInvite.replace("{token}", payload.token),
    payload.cvData
  );

export const createCvReq = (payload) =>
  postRequest(
    apiEndpoints.cv.createCv.replace("{token}", payload.token),
    payload.cvData
  );
