import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import plusIcon from "../../assets/images/plus.png";
import sendMessage from "../../assets/images/send_message.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPatternReq } from "../../store/actions/pattern/patternActions";
import { selectPattern } from "../../store/selectors/patternSelectors";
import {
  clearNotSentEmailsArray,
  scheduleAppointmentReq,
} from "../../store/actions/scheduleAppointment/scheduleAppointmentActions";
import { selectNotSentEmails } from "../../store/selectors/scheduleAppointmentSelectors";
import { setPatternApplicantsReq } from "../../store/actions/patternApplicants/patternApplicantsActions";
import { selectPatternApplicants } from "../../store/selectors/patternApplicants";
import { PATTERNS_PAGE } from "../../constants/pages";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const PatternDetailsPage = () => {
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState("");
  const pattern = useSelector(selectPattern);
  const patternApplicants = useSelector(selectPatternApplicants);
  const notSentEmails = useSelector(selectNotSentEmails);
  const [selectedPatternApplicants, setSelectedPatternApplicants] = useState(
    []
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const navigateToPatternsPage = () => {
    history.push(PATTERNS_PAGE);
  };

  useEffect(() => {
    setSelectedPatternApplicants(patternApplicants);
  }, [patternApplicants]);

  useEffect(() => {
    dispatch(setPatternReq({ id }));
  }, []);

  useEffect(() => {
    dispatch(setPatternApplicantsReq({ id }));
  }, []);

  useEffect(() => {
    if (patternApplicants && patternApplicants.length > 0) {
      setEmail(patternApplicants[0].email);
    }
  }, [patternApplicants]);

  useEffect(() => {
    const arrOfApplicants = [];
    if (!patternApplicants || !emails) return;
    for (let i = 0; i < patternApplicants.length; i++) {
      let s = 0;
      for (let j = 0; j < emails.length; j++) {
        if (patternApplicants[i].email === emails[j]) s = 1;
      }

      if (s === 0) arrOfApplicants.push(patternApplicants[i]);
    }

    setSelectedPatternApplicants(arrOfApplicants);
    if (arrOfApplicants.length > 0) {
      setEmail(arrOfApplicants[0].email);
    }
  }, [emails]);

  useEffect(() => {
    dispatch(clearNotSentEmailsArray());
  }, []);

  const addNewEmailHandler = () => {
    if (email.length === 0) return;
    if (selectedPatternApplicants.length === 0) return;
    setEmails((oldState) => [...oldState, email]);
    setEmail("");
  };

  const scheduleAppointmentHandler = () => {
    dispatch(
      scheduleAppointmentReq({
        emails,
        patternId: id,
        handleApiResponseSuccess: navigateToPatternsPage,
      })
    );
  };

  return (
    <>
      {!pattern && (
        <div>
          <p>Loading...</p>
        </div>
      )}
      {pattern && (
        <div className="pattern-details" data-testid="pattern-details">
          <div className="pattern-details-header">
            <p>
              <span>{t("patterns.made")}:</span>{" "}
              {new Date(pattern.createdAt).toLocaleDateString()}
            </p>
          </div>
          <div className="pattern-details-card">
            <div className="pattern-details-card-title">
              <div className="pattern-details-card-title-title">
                <h1>{t("pattern.pattern")}</h1>
              </div>
              <div className="pattern-details-card-title-sub">
                <sub> | {t("patterns.scheduling")}</sub>
              </div>
            </div>
            <div className="pattern-details-card-sub-card">
              <div className="pattern-details-card-sub-card-title">
                <p>{pattern.selectionLevel.name}</p>
              </div>
              <div className="pattern-details-card-sub-card-emails">
                {emails &&
                  emails.map((email, index) => (
                    <div
                      key={index}
                      className="pattern-details-card-sub-card-emails-email"
                    >
                      {email}
                    </div>
                  ))}
              </div>
            </div>

            <div className="pattern-details-card-sub-card">
              <div className="pattern-details-card-sub-card-title">
                <p>{pattern.selectionLevel.name}</p>
              </div>
              <div className="pattern-details-card-sub-card-add-email">
                <select
                  data-testid="pattern-details-select"
                  onChange={(e) => setEmail(e.target.value)}
                >
                  {selectedPatternApplicants &&
                    selectedPatternApplicants.length > 0 &&
                    selectedPatternApplicants.map((applicant) => (
                      <option
                        key={applicant.applicantId}
                        value={applicant.email}
                      >
                        {applicant.firstName + " " + applicant.lastName}
                      </option>
                    ))}
                </select>
                <button
                  onClick={addNewEmailHandler}
                  data-testid="pattern-details-plus"
                >
                  <img src={plusIcon} alt="plus" />
                </button>
              </div>
            </div>

            {notSentEmails && notSentEmails.map((x) => <p key={x}>{x}</p>)}

            <div className="pattern-details-card-sub-card">
              <div className="pattern-details-card-sub-card-title">
                <p>{t("patterns.messageText")}</p>
              </div>
              <div className="pattern-details-card-sub-card-message-pattern">
                {/* <textarea disabled value={pattern.message}></textarea> */}
                {parse(pattern.message)}
              </div>
            </div>

            <div className="pattern-details-card-buttons">
              <button
                className="job-details-buttons-link"
                data-testid="job-details-buttons-link"
                onClick={() => history.push(PATTERNS_PAGE)}
              >
                {t("ads.backToAds")}
              </button>
              <IconButton
                disabled={emails && emails.length === 0}
                className="c-btn c-btn--primary add-job-btn"
                data-testid="pattern-details-send-email"
                onClick={scheduleAppointmentHandler}
              >
                <img
                  style={{
                    marginRight: "5px",
                    width: "12px",
                    height: "12px",
                  }}
                  src={sendMessage}
                />
                {t("patterns.message")}
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PatternDetailsPage;
