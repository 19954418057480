import {
  FETCH_PATTERNS_REQ,
  FETCH_PATTERNS_ERR,
  FETCH_PATTERNS_SUCCESS,
  FETCH_FILTERED_PATTERNS_REQ,
  FETCH_FILTERED_PATTERNS_SUCCESS,
  FETCH_FILTERED_PATTERNS_ERR,
} from "./patternsActionConstants";

export const setPatternsReq = () => ({
  type: FETCH_PATTERNS_REQ,
});

export const setPatternsError = (payload) => ({
  type: FETCH_PATTERNS_ERR,
  payload,
});

export const setPatterns = (payload) => ({
  type: FETCH_PATTERNS_SUCCESS,
  payload,
});

export const setFilteredPatternsReq = (payload) => ({
  type: FETCH_FILTERED_PATTERNS_REQ,
  payload,
});

export const setFilteredPatternsError = (payload) => ({
  type: FETCH_FILTERED_PATTERNS_ERR,
  payload,
});

export const setFilteredPatterns = (payload) => ({
  type: FETCH_FILTERED_PATTERNS_SUCCESS,
  payload,
});
