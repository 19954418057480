import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Selection from "../../components/Selection/Selection";
import FilterButton from "../../components/Button/FilterButton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDoneProcess } from "../../store/actions/processes/processAction";
import { setProcessesReq } from "../../store/actions/processes/processesAction";
import { setStatuses } from "../../store/actions/processes/statusAction";
import { selectDoneProcess } from "../../store/selectors/processSelectors";
import { selectProcesses } from "../../store/selectors/processesSelectors";
import { selecStatuses } from "../../store/selectors/statusSelectors";
import PropTypes from "prop-types";
import SelectionFilter from "../../components/Selection/SelectionFilter";
import InterviewDialog from "../../components/MUI/InterviewDialog";
import plus from "../../assets/images/plus.png";
import forbiden from "../../assets/images/forbiden.png";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { fetchCandidateOptions } from "../../store/actions/candidates/candidatesActions";
import { SelectionContext } from "../../context/SelectionContext";
import { setUsersReq } from "../../store/actions/users/usersActions";
import StatusDialog from "../../components/MUI/StatusDialog";
import InterviewerDialog from "../../components/MUI/InterviewerDialog";
import CommentProcessDialog from "../../components/MUI/CommentProcessDialog";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import {
  FETCH_FILTERED_PROCESSES_LOADING,
  FETCH_PROCESSES_LOADING,
} from "../../store/actions/processes/processesActionConstants";

const SelectionProcessPage = ({ history }) => {
  const [toggleFiltersDrawer, setToggleFiltersDrawer] = useState(false);
  const [toggleInitModal, setToggleInitModal] = useState(false);
  // const errorMessage = useSelector(selectProcessesError);
  const process = useSelector(selectDoneProcess);
  const processes = useSelector(selectProcesses); 
  // const doneErrorMessage = useSelector(selectDoneProcessError);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const statuses = useSelector(selecStatuses);
  const { isSuccess } = useSelector((s) => s.initProcess);
  const { success } = useSelector((s) => s.statusUpdate);
  const { updateSuccess } = useSelector((s) => s.interviewerUpdate);

  const {
    activeProcess,
    setActiveProcess,
    activeInterview,
    setActiveInterview,
    setActiveProcessUnsuccess,
    activeProcessUnsuccess,
  } = useContext(SelectionContext);

  useEffect(() => {
    dispatch(fetchCandidateOptions());
    dispatch(setProcessesReq());
    dispatch(setUsersReq());
    const s = [
      { isChecked: false, name: "Zakazan" },
      { isChecked: false, name: "Odrađen" },
      { isChecked: false, name: "Neuspešno" },
      { isChecked: false, name: "Čeka na zakazivanje" },
      { isChecked: false, name: "Čeka se odgovor" },
    ];
    dispatch(setStatuses(s));
  }, [isSuccess, success, updateSuccess]);

  useEffect(() => {
    dispatch(setUsersReq());
    dispatch(setProcessesReq());
    dispatch(setDoneProcess(false));
  }, [process.process.doneProcess, success, updateSuccess]);

  const handleToggleFiltersDrawer = () => {
    setToggleFiltersDrawer((oldState) => !oldState);
  };

  const renderList = processes?.map((item, index) => {
    return (
      <Selection
        modalEvent={() => {
          setToggleInitModal(true);
        }}
        selection={item}
        order={index}
        key={index}
        history={history}
      />
    );
  });

  const isLoading = useSelector(
    selectIsLoadingByActionType(FETCH_PROCESSES_LOADING)
  );

  const isLoadingFiltered = useSelector(
    selectIsLoadingByActionType(FETCH_FILTERED_PROCESSES_LOADING)
  );

  return isLoading || isLoadingFiltered ? (
    <div>
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <div className="loader-container h-withHeader">
        <span>Loading</span>
      </div>
    </div>
  ) : (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div data-testid="selections-page">
        <div className="l-t-rectangle"></div>
        <div className="r-b-rectangle"></div>
        <SelectionFilter />
        <SelectionFilter
          open={toggleFiltersDrawer}
          handleClose={handleToggleFiltersDrawer}
          statuses={statuses}
        />
        <StatusDialog // Adding an interviewer dialog
          open={activeProcess !== null}
          title={t("selection.addInterviewer")}
          subtitle={t("selection.selection")}
          imgSrc={plus}
          onClose={() => {
            setActiveProcess(null);
          }}
        />
        <CommentProcessDialog
          open={activeProcessUnsuccess !== null}
          title={t("candidates.comment")}
          subtitle={t("selection.selection")}
          imgSrc={forbiden}
          onClose={() => {
            setActiveProcessUnsuccess(null);
          }}
        />
        <InterviewerDialog
          open={activeInterview !== null}
          title={t("selection.changeInterviewer")}
          subtitle={t("selection.selection")}
          imgSrc={plus}
          onClose={() => {
            setActiveInterview(null);
          }}
        />
        <InterviewDialog  // Adding Candidate dialog
          open={toggleInitModal}
          title={t("selection.addCandidate")}
          subtitle={t("selection.interview")}
          imgSrc={plus}
          onClose={() => {
            setToggleInitModal(false);
          }}
        />
        <div className="selections">
          <div className="level-header">
            <h1>
              {t("selection.title")}
              <span className="level-header-spliter">|</span>
              <span className="level-header-subheader">
                {t("selection.subtitle")}
              </span>
            </h1>
            <FilterButton onShowFilters={handleToggleFiltersDrawer} />
          </div>
          <div className="selection-levels">
            <div>
              {/* <IconButton
              sx={{ marginLeft: "15px" }}
              className="c-btn c-btn--primary-outlined fixed-right"
              onClick={handleToggleFiltersDrawer}
            >
              Filteri{" "}
              <img src={filterVector} alt="filter" className="filter-vector" />
            </IconButton> */}
              <div className="selection-levels-processes">
                <div className="selection-levels-processes-process">
                  {renderList}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

SelectionProcessPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default SelectionProcessPage;
