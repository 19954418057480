import { deleteRequest, getRequest, postRequest, downloadPdf } from ".";
import apiEndpoints from "./apiEndpoints";

export const getFilteredCandidates = (payload) => {
  let technologiesQuery = "";
  for (let i = 0; i < payload.technologies.length; i++) {
    technologiesQuery += `technologies=${payload.technologies[i]}&`;
  }
  return getRequest(
    apiEndpoints.candidates.filteredCandidates +
      "?currentPage=" +
      payload.currentPage +
      "&pageSize=" +
      payload.pageSize +
      "&minExperience=" +
      payload.minExperience +
      "&maxExperience=" +
      payload.maxExperience +
      "&employmentType=" +
      payload.employmentType +
      "&minDateOfApplication=" +
      payload.minDateOfApplication +
      "&maxDateOfApplication=" +
      payload.maxDateOfApplication +
      "&" +
      technologiesQuery
  );
};
export const getCandidate = (id) =>
  getRequest(apiEndpoints.candidates.filteredCandidates + "/" + id);
export const createComment = (data) =>
  postRequest(apiEndpoints.comments.addComment, data);
export const getFilteredAdsCandidates = (payload) => {
  let technologiesQuery = "";
  for (let i = 0; i < payload.technologies.length; i++) {
    technologiesQuery += `technologies=${payload.technologies[i]}&`;
  }
  return getRequest(
    apiEndpoints.candidates.allFilteredAdsCandidates +
      "?currentPage=" +
      payload.currentPage +
      "&pageSize=" +
      payload.pageSize +
      "&minExperience=" +
      payload.minExperience +
      "&maxExperience=" +
      payload.maxExperience +
      "&employmentType=" +
      payload.employmentType +
      "&minDateOfApplication=" +
      payload.minDateOfApplication +
      "&maxDateOfApplication=" +
      payload.maxDateOfApplication +
      "&" +
      technologiesQuery
  );
};
export const deleteCandidate = (id) =>
  deleteRequest(apiEndpoints.candidates.filteredCandidates + "?id=" + id);

export const getCandidateOptions = () =>
  getRequest(apiEndpoints.candidates.options);

export const initializeProcessRequest = (payload) =>
  postRequest(apiEndpoints.candidates.initProcess, payload);

export const getCV = (fileName) =>
  downloadPdf(apiEndpoints.applicant.getCV + "?fileName=" + fileName);
