import createReducer from "../../utils/createReducer";
import {
    SCHEDULE_ERROR,
    SCHEDULE_SUCCESS
} from "../../actions/schedule/scheduleActionConstants";

const initialState = {
  schedule: [],
  errorMessage: "",
};

export default createReducer(
  {
    [SCHEDULE_SUCCESS]: getSchedule,
    [SCHEDULE_ERROR]: setError,
  },
  initialState
);

function getSchedule(state, action) {
  return {
    ...state,
    schedule: action.payload,
  };
}

function setError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
