import createReducer from "../../utils/createReducer";
import {
  SCREENING_TESTS_ERROR,
  SCREENING_TESTS_SUCCESS,
} from "../../actions/screeningTests/screeningTestsActionConstants";

const initialState = {
  screeningTests: [],
  errorMessage: "",
};

export default createReducer(
  {
    [SCREENING_TESTS_SUCCESS]: setScreeningTests,
    [SCREENING_TESTS_ERROR]: setScreeningTestsError,
  },
  initialState
);

function setScreeningTests(state, action) {
  return {
    ...state,
    screeningTests: action.payload,
  };
}

function setScreeningTestsError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
