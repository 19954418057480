import {
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
} from "../actionHelpers";

const FETCH_STATS_SCOPE = "FETCH_STATS";
export const FETCH_STATS_REQ = createFetchType(FETCH_STATS_SCOPE);
export const FETCH_STATS_ERR = createErrorType(FETCH_STATS_SCOPE);
export const FETCH_STATS_SUCCESS = createSuccessType(FETCH_STATS_SCOPE);
export const FETCH_STATS_LOADING = createLoadingType(FETCH_STATS_SCOPE);
