import {
  createFetchType,
  createSuccessType,
  createErrorType,
} from "../actionHelpers";

const FETCH_PATTERN_APPLICANTS_SCOPE = "FETCH_PATTERN_APPLICANTS";

export const FETCH_PATTERN_APPLICANTS_REQ = createFetchType(
  FETCH_PATTERN_APPLICANTS_SCOPE
);
export const FETCH_PATTERN_APPLICANTS_ERR = createErrorType(
  FETCH_PATTERN_APPLICANTS_SCOPE
);
export const FETCH_PATTERN_APPLICANTS_SUCCESS = createSuccessType(
  FETCH_PATTERN_APPLICANTS_SCOPE
);
